/*
Version: 1.0
Author: Symlink Technology
Author URI: https://www.symlinktech.com/.
*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
/*
font-family: 'Roboto', sans-serif;
*/
:root{
--color01:#1da253;
--color02:#455a64;
--color03:#5b5756;
--shades01:#ffffff;
--shades02:#263238;
--shades03:#f8f8fb;
--shades04:#eaeaea;
--shades05:#dadada;
--shades06:#aeafc5;
--statusOpen:#e5164c;
--statusClosed:#1da253;
--statusWaiting:#ff9800;
--statusAssigned:#2196f3;
--statusInprogress:#ff7043;
--statusReduced:#ab47bc;
--statusDismiss:#00bcd4;
--statusGuilty:#8d6e63;
}

/* ********|| INITIALIZATION STARTS ||******** */
body, html{ width: 100%; line-height:18px; margin:0 auto !important;padding:0 !important;font-family: 'Roboto', sans-serif; font-weight: 300; -webkit-font-smoothing: subpixel-antialiased;text-shadow: 1px 1px 1px rgba(0,0,0,0.004);font-size: 14px;  color: var(--shades02); background:var(--shades01);position: relative; z-index: 0;}
*:focus{outline: none !important;outline-offset: none !important;outline-offset: 0 !important;}
a {text-decoration: none ;}
a:hover{text-decoration: none;-webkit-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -o-transition: all 0.3s ease; -ms-transition: all 0.3s ease;transition: all 0.3s ease;}
a:focus{ outline: none;text-decoration: none;}
input:focus, label:focus{outline: none !important;outline-offset: none !important;outline-offset: 0 !important;}
/* ********|| INITIALIZATION ENDS ||******** */

/* ********|| PSEUDO ELEMENTS STARTS ||******** */
::selection{ background-color:var(--shades01); color:var(--shades01)}
::-moz-selection{ background-color:var(--shades01); color:var(--shades01)}
::-webkit-selection{ background-color:var(--shades01); color:var(--shades01)}

:-webkit-focus { outline: none !important; }
:-moz-focus { outline: none !important; }
:focus { outline: none !important; }

select:-webkit-focusring { color: var(--shades01) !important;text-shadow: 0 0 0 var(--shades02);}
select:-moz-focusring {color: var(--shades01) !important;text-shadow: 0 0 0 var(--shades02);}
select:focusring {color: var(--shades01) !important; text-shadow: 0 0 0 var(--shades02);}

::input-placeholder{ color:var(--shades02) !important;}
::-moz-input-placeholder{ color:var(--shades02) !important;}
::-webkit-input-placeholder{ color:var(--shades02) !important;}


input::-webkit-outer-spin-button, input::-webkit-inner-spin-button{-webkit-appearance: none; margin: 0;}
input[type=number]{-moz-appearance: textfield;}

/*::-webkit-scrollbar-track{	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);box-shadow: inset 0 0 6px rgba(0,0,0,0.1);background-color: var(--textMedColor);}
::-webkit-scrollbar{width: 5px;height: 5px;background-color: var(--textMedColor);}
::-webkit-scrollbar-thumb{background-color: var(--secondaryColorAltLight);}*/
/* ********|| PSEUDO ELEMENTS ENDS ||******** */

/* ********|| ADJUSTMENT CLASSES STARTS ||******** */
*,::after,::before{box-sizing: border-box;}
a, button{color:var(--shades02); border:0; transition: all 0.3s ease;}
a:hover, a:focus{color:var(--shades02);}
p { margin: 0 0 10px; }
hr{margin-top: 20px; margin-bottom: 20px; border: 0; border-top: 1px solid var(--color04);}
strong{font-weight:600;}

.required:after{content:"*";color:#f44336; font-size:16px; font-weight:600; position: absolute;margin: 0 0 0 3px;}
.separator_dashed{ border-bottom:var(--color04) 1px dashed;}
.separator_dotted{ border-bottom:var(--color04) 1px dotted;}
.separator_solid{ border-bottom:var(--color04) 1px solid;}

/* ********|| ADJUSTMENT CLASSES ENDS ||******** */

.form-control{ border-radius: 4px;box-shadow: none;border: 1px solid #ddd;color: var(--textColor);background-color: var(--shades01);min-width: 50px;min-height: 38px;width: 100%;padding: 6px 12px;font-family: inherit;font-size: 14px;}
.form-control:focus{ border-color:var(--shades01);box-shadow: none;}
.form-control::-webkit-input-placeholder{ color: var(--shades02) !important;}
.form-control:-moz-placeholder{color:var(--shades02) !important;opacity:  1 !important;}
.form-control::-moz-placeholder{color:var(--shades02) !important; opacity:1 !important;}
.form-control:-ms-input-placeholder{color:var(--shades02) !important;}
.form-control::-ms-input-placeholder{color:var(--shades02) !important;}
textarea.form-control{ resize:none; min-height: 100px;}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{ background-color: var(--shades01); opacity: 0.7; cursor: not-allowed;}

.custom-checkbox{position: relative;padding:8px 0 8px 30px;margin:0;display: flex; align-items:center; line-height:1;}
.custom-checkbox .custom-checkbox-input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0; cursor: pointer;}
.custom-checkbox .custom-checkbox-label{position: relative;margin: 0;font-size: 14px;font-weight: 500;color: var(--color02);line-height: 1;}
.custom-checkbox .custom-checkbox-label:before{content:'';position:absolute;width: 18px;height: 18px;background: var(--shades01);border: 1px solid var(--shades04);top: 50%;left: -30px; transform: translateY(-50%);border-radius: 2px;}
.custom-checkbox .custom-checkbox-label:after{content: "\f00c";position: absolute;font-family: "Font Awesome 5 Pro";width: 18px;height: 18px;top: 50%;left: -30px;transform: translateY(-50%); font-weight: 500;font-size: 14px;display: none;align-items:center; justify-content:center; color: var(--shades01);text-align: center;}
.custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:after{display:flex;}
.custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:before{background: var(--color01);border: 1px solid var(--color01);}

.custom-checkbox.alt .custom-checkbox-input{width: 18px;height: 18px;top: 8px;left: 0;transform: none;}
.custom-checkbox.alt .custom-checkbox-label:before{top: 0;left: -30px; transform:none;}
.custom-checkbox.alt .custom-checkbox-label:after{top: 0;left: -30px; transform:none;}

.custom-radio{position: relative;padding:8px 0 8px 30px;margin:0;display: flex; align-items:center; line-height:1;}
.custom-radio .custom-radio-input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0; cursor: pointer;}
.custom-radio .custom-radio-label{position: relative;margin: 0;font-size: 14px;font-weight: 500;color: var(--color02);line-height: 1;}
.custom-radio .custom-radio-label:before{content:'';position:absolute;width: 18px;height: 18px;background: var(--shades01);border: 1px solid var(--shades04);top: 50%;left: -30px; transform: translateY(-50%);border-radius: 50%;}
.custom-radio .custom-radio-label:after{content: "\f111";position: absolute;font-family: "Font Awesome 5 Pro";width: 18px;height: 18px;top: 50%;left: -30px;transform: translateY(-50%); font-weight: 600;font-size: 8px;display: none;align-items:center; justify-content:center; color: var(--shades01);text-align: center;}
.custom-radio .custom-radio-input:checked ~ .custom-radio-label:after{display:flex;}
.custom-radio .custom-radio-input:checked ~ .custom-radio-label:before{background: var(--color01);border: 1px solid var(--color01);}

.custom-tab{}
.custom-tab .custom-tab-navigation{padding-left: 0;list-style: none;display: flex;flex-wrap: wrap;justify-content: center;margin: 0 auto 10px;}
.custom-tab .custom-tab-navigation > li{width: auto;margin:5px;position: relative;}
.custom-tab .custom-tab-navigation > li > a{background-color: rgba(255,255,255,0.1);color: var(--shades01);padding: 10px 20px;display: block;font-size: 16px;font-weight: 400;text-transform: capitalize;position: relative;border-radius: 4px;max-width: 300px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
.custom-tab .custom-tab-navigation > li.active > a,.custom-tab .custom-tab-navigation > li > a:hover{background-color: var(--shades01);}
.custom-tab .custom-tab-content{}
.custom-tab .custom-tab-content .custom-tab-wrap{display: none;background: transparent;padding: 20px 0;}
.custom-tab .custom-tab-content .custom-tab-wrap.active{display: block;}
.custom-tab .custom-tab-content .custom-tab-navigation > li > a{padding: 5px 10px;font-size: 14px;font-weight: 600;}
.custom-tab .custom-tab{margin-top: -20px;}
.custom-tab .custom-tab .custom-tab-navigation{margin-bottom: 0;}
@media (min-width: 576px){.custom-tab .custom-tab-navigation{max-width:620px}}
@media (min-width: 768px){.custom-tab .custom-tab-navigation{max-width:740px}}
@media (min-width: 992px){.custom-tab .custom-tab-navigation{max-width:1140px}}
@media (min-width: 1200px){.custom-tab .custom-tab-navigation{max-width:1200px}}

/* /////////////////////////////////////////////////////////////// */
/*             			   	 	BOOTSTRAP FIX            			   				 */
/* /////////////////////////////////////////////////////////////// */
@media (min-width: 576px){
.container {max-width: none;}
.modal-dialog {max-width: 700px;}
.modal-sm {max-width: 400px;}
}
@media (min-width: 768px){
.container {max-width: 740px;}
}
@media (min-width: 992px){
.container {max-width: 960px;}
}
@media (min-width: 1200px){
.container {max-width: 1170px;}
}
@media (min-width: 1440px){
.container {max-width: 1280px;}
}
@media (min-width: 1600px){
.container {max-width: 1400px;}
}
@media (min-width: 1900px){
.container {max-width: 1540px;}
}

.modal-open {overflow: auto;}
.modal{ overflow-x: hidden; overflow-y: auto;}
.overflow-hidden{overflow:hidden !important;}
.dropdown .dropdown-toggle{padding-right: 16px !important;position: relative;}
.dropdown .dropdown-toggle::after{content: "\f078";font-family: "Font Awesome 5 Pro";font-size: 14px;font-weight: 500;display: block;position: absolute;margin: 0;vertical-align: unset;top: 50%;right: 0;transform: translateY(-50%);border: 0; color: var(--shades01); opacity: 1; margin-top: 2px; transition: all 0.3s ease;}
.dropdown.show .dropdown-toggle::after{transform: translateY(-50%) rotate(-180deg);}
.dropdown .dropdown-menu{border-radius: 0;border-color: var(--color04);z-index: 8;}
.dropdown-menu .dropdown-item{display: flex;padding: 5px 10px;color: var(--shades02);}
.dropdown-item.active, .dropdown-item:active{background: var(--shades02);color: var(--shades01) !important;}
header .authentication.mobile{display: none;}
header .authentication .dropdown-item.active .profile-menu .icon{color: var(--shades01) !important;}
header .authentication .dropdown-item.active .profile-menu .text{color: var(--shades01) !important;}
.dropdown-menu .dropdown-item:focus,.dropdown-menu .dropdown-item:hover,.dropdown-menu .dropdown-item:active{background: var(--shades02);color: var(--shades01);}
.mega-dropdown{position:static !important;}
.mega-dropdown .mega-dropdown-menu{display:block;width: 100%;top: auto !important;left: 50% !important;transform: translate(-50%,50px) !important;padding: 20px 40px;visibility: hidden;opacity:0;transition:.5s ease all;}
.mega-dropdown .mega-dropdown-menu.show{display:block;transform: translate(-50%,0) !important;visibility: visible;opacity:1;transition:.5s ease all;}

/*////////////////////////////////////////////////////////////////*/
/*             		          	 HOME PAGE      	      				  	*/
/*////////////////////////////////////////////////////////////////*/
/*
font-family: 'Roboto', sans-serif;
*/
/* *******|| HEADER STARTS ||******* */
header{position: fixed; top: 0; left: 0; width: 100%; height: 96px; z-index: 110; background-color: var(--shades01); transition: all 0.3s ease;}
header .header-strip{display: flex; align-items: center; justify-content: flex-end; height: 36px;}
header .contact{display: flex; align-items: center;}
header .contact .contact-item{font-size: 13px;font-weight: 400;color: var(--color02);display: flex;align-items: center;}
header .contact .contact-item i{font-size: 18px;}
header .contact .contact-item+.contact-item{margin-left: 20px;}
header .contact .contact-item:hover{color: var(--color01);}
header .social{display: flex;justify-content: flex-end;align-items: center; margin: 0 0 0 20px; padding: 0 0 0 20px; border-left: 1px solid var(--shades05);}
header .social .social-item{display: flex;justify-content: center;align-items: center;padding: 0;color: var(--color02);font-size: 18px;}
header .social .social-item+.social-item{margin-left: 8px;}
header .social .social-item:hover{color: var(--color01);}
header .header-content{display: flex;align-items: center;}
header .brand{height:60px; overflow: hidden; margin-right: auto; margin-top: -36px;}
header .brand .logo{width: 100%; height: 100%; display: block;}
header .brand .logo img{width: 100%; height: 100%; object-fit: contain; object-position: center left;}
header .navigation{display: flex;align-items: center;justify-content: flex-end; margin-left: auto;height: 60px;}
header .navigation-wrap{display: flex;align-items: center;}
header .navigation-menu{display: flex;align-items: center;}
header .navigation-menu .menu-item{}
header .navigation-menu .menu-link{font-size: 15px;text-transform: uppercase;font-weight: 600;letter-spacing: 0.3px;padding: 0 20px;color: var(--color02);}
header .navigation-menu .menu-link:hover, header .navigation-menu .menu-item.active .menu-link{color: var(--color01);}
header .authentication{display: flex;justify-content: flex-end;align-items: center;padding: 0 0 0 10px;margin: 0;border-left: 1px solid var(--shades05);}
header .authentication .btn-login{ position: relative; overflow: hidden; display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 40px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: 0.3px;border-radius: 4px;}
header .authentication .btn-login:before{content:''; position: absolute; width: 200px; height: 200px; background-color: var(--shades01);top: 50%; left: 50%; transform: translate(-50%,-50%) scale(0); opacity: 0; border-radius: 50%; transition: all 0.3s ease;}
header .authentication .btn-login:hover:before{transform: translate(-50%,-50%) scale(1);opacity: 0.3;}
header .authentication .user-profile{display: flex;align-items: center;}
header .authentication .user-profile .profile-img{width: 35px;height: 35px;border-radius: 50%;overflow: hidden;margin-right: 8px;}
header .authentication .user-profile .profile-img img{width: 100%;height: 100%;object-fit: cover;}
header .authentication .user-profile .profile-text{font-size:14px;font-weight:500;letter-spacing:.3px;color:var(--color02)}
header .cart-inner{display: flex;align-items: center;justify-content: space-between;padding-left: 15px;}
header .cart-inner .notifications-btn{font-size:18px;color:var(--color02);display:flex;align-items:center;background-color:transparent;padding-right: 2px !important;position: relative;}
header .cart-inner .notifications-btn .badge{position:absolute;top:-11px;right:-11px;color:var(--shades01);font-size:10px;border-radius:50%;display:flex;align-items:center;justify-content:center;background-color:var(--color01);height:20px;width:20px;font-weight:400}
header .cart-inner .dropdown .dropdown-toggle::after{display:none}
header .cart-action .cart-btn{font-size:18px;color:var(--color02);display:flex;align-items:center;position: relative;}
header .cart-action .cart-btn .badge-icon{position: absolute;top: -11px;right: -11px;color: var(--shades01);font-size: 10px;border-radius: 50%;display: flex;align-items: center;justify-content: center;background-color: var(--color01);height: 20px;width: 20px;font-weight: 400;}
header .cart-inner .dropdown-menu .dropdown-title{font-size:14px;font-weight:400;color:var(--color02);padding: 10px;border-bottom: 1px solid var(--shades04);}
header .cart-inner .dropdown-menu .notification-item {display: flex;padding: 10px;}
header .cart-inner .dropdown-menu .notification-item.unread {background-color: var(--shades03);}
header .cart-inner .dropdown-menu .notification-item .notification-image{width: 40px;height: 40px;border-radius: 50%;overflow: hidden;}
header .cart-inner .dropdown-menu .notification-item .notification-image img{width: 100%;height: 100%;object-fit: cover;}
header .cart-inner .dropdown-menu .notification-item .cart-content{width: 80%;max-width: calc(80% - 10px);margin-left: 10px;}
header .cart-inner .dropdown-menu .notification-item .cart-content .text{font-size:13px;font-weight:500;color:var(--color02);line-height: 1.2;}
header .cart-inner .dropdown-menu .notification-item .cart-content .sub-text{font-size:13px;font-weight:400;color:var(--shades06);}
header .cart-inner .dropdown-menu .notification-list{overflow: auto;}
header .cart-inner .dropdown-menu .notification-list .notification-list-item{max-height: 300px;}

header .cart-inner .dropdown-menu .notification-list::-webkit-scrollbar-track{	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);box-shadow: inset 0 0 6px rgba(0,0,0,0.1);background-color: var(--textMedColor);}
header .cart-inner .dropdown-menu .notification-list::-webkit-scrollbar{width: 5px;height: 5px;background-color: #fff;}
header .cart-inner .dropdown-menu .notification-list::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 10px;}

.notification-page .notification-list .notification-list-item{padding: 0;}

.notification-page .dropdown-title{font-size: 25px;margin-bottom: 15px;font-weight: 500;color: var(--color02);line-height: 1.2;}
.notification-page .notification-list::-webkit-scrollbar-track{	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);box-shadow: inset 0 0 6px rgba(0,0,0,0.1);background-color: var(--textMedColor);}
.notification-page .notification-list::-webkit-scrollbar{width: 5px;height: 5px;background-color: #fff;}
.notification-page .notification-list::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 10px;}
.notification-page .notification-item {display: flex;padding: 10px;background: var(--shades01);}
.notification-page .notification-item+.notification-item{border-top: 1px solid var(--shades04);}
.notification-page .notification-item.unread {background-color: var(--shades03);}
.notification-page .notification-item .notification-image{width: 40px;height: 40px;border-radius: 50%;overflow: hidden;}
.notification-page .notification-item .notification-image img{width: 100%;height: 100%;object-fit: cover;}
.notification-page .notification-item .cart-content{width: 80%;max-width: calc(80% - 10px);margin-left: 10px;}
.notification-page .notification-item .cart-content .text{font-size:14px;font-weight:500;color:var(--color02)}
.notification-page .notification-item .cart-content .sub-text{font-size:13px;font-weight:400;color:var(--shades06);}
.notification-page .notification-list{background: var(--shades01);border: 1px solid var(--shades04);box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);border-radius: 6px;overflow: auto;}
.notification-page .notification-list .notification-list-item{background: var(--shades01);border: 1px solid var(--shades04);box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);border-radius: 6px;overflow: auto;}


::-webkit-scrollbar-track{	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);box-shadow: inset 0 0 6px rgba(0,0,0,0.1);background-color: var(--textMedColor);}
::-webkit-scrollbar{width: 5px;height: 5px;background-color: #fff;}
::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 10px;}

.notification-list::-webkit-scrollbar-track{	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);box-shadow: inset 0 0 6px rgba(0,0,0,0.1);background-color: var(--textMedColor);}
.notification-list::-webkit-scrollbar{width: 5px;height: 5px;background-color: #fff;}
.notification-list::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 10px;}

header .cart-action{padding-right: 10px;}
header .cart-inner .dropdown-menu .see-btn{display: flex;align-items: center;justify-content: center;padding: 10px;border-top: 1px solid var(--shades04);font-size:14px;font-weight:500;color:var(--color03);background-color: transparent;width: 100%;}
header .cart-inner .dropdown .dropdown-menu{width: 100%;transform: translate(0)!important;top: 38px !important;min-width:350px;border:none;padding:0;border-radius: 5px;right: 0 !important;left: auto !important;box-shadow: rgb(54 54 54 / 11%) 1px 1px 29px 0px;}

.modal-search{display: flex;justify-content: space-between;flex-wrap: wrap;align-items: center;margin-bottom: 15px;}
.modal-search .total-pay-action .action-btn{margin-right: 15px;}
.modal-search .total-pay-action{align-items: center;}
.search.management{align-items: center;border: 1px solid #eaeaea;border: 1px solid var(--shades04);border-radius: 5px;display: flex;margin-right: 10px;overflow: hidden;width: 300px;}
.modal-search .total-pay-action{margin-bottom: 0;}
.search.management .search-btn{background-color: #fff;border-right: 1px solid #eaeaea;border-right: 1px solid var(--shades04);color: #1da253;color: var(--color01);height: 35px;width: 35px;}
.search.management .search-input{border: none;height: 35px;max-width: calc(100% - 35px);padding: 0 10px;width: 100%;}

.forgot-content.plate-info-modal .details{}
.forgot-content.plate-info-modal .details .details-card{margin: 15px 0;}
.forgot-content.plate-info-modal .details .details-card .vehicle-name{    font-size: 16px;
font-weight: 700;}
.forgot-content.plate-info-modal .details .details-card .vehicle-info{    font-size: 14px;
font-weight: 700;}
.forgot-content.plate-info-modal .details .details-card .vehicle-metalist{margin-top: 15px;}
.forgot-content.plate-info-modal .details .details-card .vehicle-metalist .meta-item{display: flex;}
.forgot-content.plate-info-modal .details .details-card .vehicle-metalist .meta-item+ .meta-item{margin-top: 8px;}
.forgot-content.plate-info-modal .details .details-card .vehicle-metalist .meta-item .meta-label{width: 100px;font-weight: 400;font-size: 14px;color: var(--color02);}
.forgot-content.plate-info-modal .details .details-card .vehicle-metalist .meta-item .meta-data{font-weight: 600;font-size: 14px;}
.forgot-content.plate-info-modal .submit-action{}
.forgot-content.plate-info-modal .submit-action .btn-submit-action{background: var(--color01);color: var(--shades01);padding: 10px;border-radius: 5px;}
.modal-body.payment-modal-part{height: 100%;max-height: calc(100vh - 200px);overflow: auto;}

header .authentication .dropdown .dropdown-toggle{text-align: left;background-color: transparent;}
header .authentication .dropdown .dropdown-toggle::after{color: var(--color02);margin: 0;font-size: 12px;}
header .authentication .dropdown .dropdown-menu{width: 97%;border: 1px solid var(--shades04);}
/* header .authentication .dropdown-search{padding:0 10px;margin:0 0 5px}
header .authentication .dropdown .dropdown-search .form-control{background-color:transparent;border:0;border-radius:0;border-bottom:1px solid var(--color02);padding:0;width:100%;height:40px;color:var(--color02);font-size:13px;font-weight:500;transition:all .3s ease} */
/* header .authentication .dropdown .dropdown-scroll{max-height:100px;overflow:auto} */

header .authentication .dropdown-menu .dropdown-item:hover .profile-menu .icon{color: var(--shades01);}
header .authentication .dropdown-menu .dropdown-item:hover .profile-menu .text{color: var(--shades01);}
.dropdown-menu .dropdown-item:focus .profile-menu .icon{color: var(--shades01);}
.dropdown-menu .dropdown-item:focus .profile-menu .text{color: var(--shades01);}
.dropdown-menu .dropdown-item:active .profile-menu .icon{color: var(--shades01);}
.dropdown-menu .dropdown-item:active .profile-menu .text{color: var(--shades01);}

header .authentication .profile-menu{display: flex;align-items: center;width: 100%;}
header .authentication .profile-menu .icon{font-size:14px;font-weight:500;letter-spacing:.3px;color:var(--color02);margin-right: 10px;width: 22%;max-width: calc(22% - 10px);}
header .authentication .profile-menu .text{font-size:14px;font-weight:500;letter-spacing:.3px;color:var(--color02)}

.scroll-down header{transform: translate3d(0, -100%, 0);backdrop-filter: blur(12px);}
.scroll-down header:before{opacity:0.8;}
.scroll-up header{transform: none;backdrop-filter: blur(12px);}
.scroll-up header:before{opacity:0.8;}
.header-placeholder{height:96px;position:relative;width:100%}
/* *******|| HEADER ENDS ||******* */

/* ********|| BANNER SLIDER START ||******** */
.hero{padding:40px 0; display: flex; align-items: center; background-color: var(--shades01);z-index:0;}
.hero .banner-image{display: flex;justify-content: flex-end;width: 100%;height: 100%;}
.hero .banner-image .img-one-inner{width: 100%;height: 100%;}
.hero .banner-image .img-two-inner{}
.hero .banner-image img{width:100%; height: 100%; object-fit: contain;}
.hero .banner-content-list{margin-top: 25px;}
.hero .banner-content-list .list{padding: 0;}
.hero .banner-content-list .list .item{list-style: none;padding-left: 34px;font-size: 18px;font-weight: 400;position: relative;}
.hero .banner-content-list .list .item::before{content: '\f058';position: absolute;font-family: "Font Awesome 6 Pro";left: 0;color: var(--color01);font-size: 25px;}
.hero .banner-content-list .list .item+ .item{padding-top: 18px;}

.hero .banner-content-list{margin-top: 25px;}
.hero .banner-content-list ul{padding: 0;}
.hero .banner-content-list ul li{list-style: none;padding-left: 34px;font-size: 18px;font-weight: 400;position: relative;}
.hero .banner-content-list ul li::before{content: '\f058';position: absolute;font-family: "Font Awesome 6 Pro";    left: 0;color: var(--color01);font-size: 25px;}
.hero .banner-content-list ul li+ li{padding-top: 18px;}


/* .hero .container{display: flex;} */
.hero .hero-content{position: relative;width: 100%;max-width: 480px;z-index: 1;}
.hero .hero-title{font-size: 48px;font-weight: 700;margin: 0;line-height: 1.2;color: var(--shades02);}
.hero .hero-text{font-size: 20px;font-weight: 400;letter-spacing: 0.3px;color: var(--color02);line-height: 1.4;margin: 20px 0 0;}
.hero .hero-action{display: flex;align-items: center; margin: 60px 0 0;}
.hero .hero-action .btn-action{display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 50px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: 0.3px;border-radius: 4px; position: relative; overflow: hidden;}
.hero .hero-action .btn-action:before{content:''; position: absolute; width: 200px; height: 200px; background-color: var(--shades01);top: 50%; left: 50%; transform: translate(-50%,-50%) scale(0); opacity: 0; border-radius: 50%; transition: all 0.3s ease; }
.hero .hero-action .btn-action:hover:before{transform: translate(-50%,-50%) scale(1);opacity: 0.3; }
.hero .hero-action .btn-action+.btn-action{ margin-left: 20px;}
.hero .hero-action .btn-action.alt{background-color: var(--color02);}
.banner-download{display: flex;margin-top: 25px;}
.banner-download .download-item{display: flex;align-items: center;justify-content: center;line-height: 1;height: 40px;background-color: var(--color01);border-radius: 5px;width: 100%;max-width: 160px;color: var(--shades01);}
.banner-download .download-item+ .download-item{margin-left: 10px;}
.banner-download .download-item .icon{width: 20px;height: 20px;margin-right: 10px;filter: brightness(5);}
.banner-download .download-item .icon img{width: 100%;height: 100%;object-fit: contain;transition: all 0.3s ease;}
.banner-download .download-item .title{font-size: 14px;color: var(--shades01); font-weight: 600;transition: all 0.3s ease;}
.banner-download .download-item:hover{background-color:var(--shades02);}
.banner-download .download-item:hover .icon img{filter:brightness(5);}
.banner-download .download-item:hover .title{color: var(--shades01);}
/* ********|| BANNER SLIDER ENDS ||******** */

/* ********|| BANNER HIGHLIGHTS START ||******** */
.section-steps{position: relative; background-color: var(--color02);}
.steps{}
.steps .steps-item{display: flex; width: 100%; margin: 0;position: relative; transform-origin: bottom; transition: all 0.3s ease;align-items: center;}
.steps .steps-item+ .steps-item{margin-top: 35px;}
.steps .steps-item .step-icon{width: 100px;height: 100px;display: flex;align-items: center;justify-content: center;font-size: 60px; color: var(--shades01)}
.steps .steps-item .step-content{width: 100%;max-width: calc(100% - 100px);}
.steps .steps-item .step-number{font-size: 13px;font-weight: 600;text-transform: uppercase;color: var(--shades01);letter-spacing: 0.3px;line-height: 1;margin: 0 0 10px;}
.steps .steps-item .step-title{font-size: 24px;margin: 0;color: var(--shades01);font-weight: 300;line-height: 1.2;}
.steps .steps-item .step-text{font-size: 14px;color: var(--shades01);line-height: 1.2;margin: 16px 0 0;opacity: 0.5; letter-spacing: 0.3px; font-weight: 300;}
.step-video{position: relative;}
.step-video video{border-radius: 15px;}
/* .steps .steps-item:hover{transform:scale(1.2); background-color: var(--color01);}
.steps .steps-item:hover .step-text{opacity: 1;} */
.step-section{background-color: var(--color02);padding: 60px 0;}
.step-section .step-part{display: flex;align-items: center;flex-wrap: wrap;}
/* .step-section .step-part .step-slider{width: 300px;display: flex;align-items: center;margin: 0 auto;justify-content: center;}
.step-section .step-part .steps{width: 100%;max-width: calc(100% - 550px);margin-left: 250px;} */
.step-section .step-slider-pegination{margin-top: 10px;}
.swiper-pagination .swiper-pagination-bullet{border: 1px solid var(--shades04);width: 10px;height: 10px;}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{background: var(--color01);}

.video-action{display: flex;align-items: center;justify-content: center;position: absolute;z-index: 8;top: 50%;transform: translateY(-50%);left: 0;right: 0;}
.video-action .btn-action{font-size: 16px;height: 56px;width: 56px;padding: 0 20px;border-radius: 50%;color: var(--shades01);background-color: rgb(91 87 86 / 65%);}

/* ********|| BANNER HIGHLIGHTS ENDS ||******** */

/* ********|| CONTENT START ||******** */
.section-calculation{position: relative; padding: 100px 0; display: flex; align-items: center; height: 600px; background-color: var(--shades01);z-index: 0;}
.section-calculation .calculation-background{position: absolute;width: 100%;height: 100%;top: 0;left: 0;z-index: -2;}
.section-calculation .calculation-background:before{content:'';position: absolute;width: 100%;height: 100%;top: 0;left: 0;z-index:1; background: linear-gradient(to right, var(--color01), transparent); display: none;}
.section-calculation .calculation-background img{width: 100%; height: 100%; object-fit: cover;}
.section-calculation .calculation-wave{position: absolute;width: 100%;height: 100%;bottom: 0;left: 0;z-index: -1;display: flex;align-items: flex-end;}
.section-calculation .calculation-wave svg{width: 100%;}
.section-calculation .container{display: flex;}
.calculation-content{position: relative;display: flex;align-items: flex-start;justify-content: center;flex-direction: column;background-color: var(--shades01);padding: 60px;margin:0;min-height: 320px;border-radius: 10px;box-shadow: rgb(54 50 81 / 15%) 0 0 60px;width: 1000%;max-width: 600px; overflow: hidden;z-index: 1;}
.calculation-content .calculation-icon{position: absolute;right: -15%;top: 50%;transform: translateY(-50%);font-size: 250px;opacity: 0.1;color: var(--color01);z-index: -1;}
.calculation-content .calculation-title{font-size: 16px;text-transform: uppercase;font-weight: 400;color: var(--color02);line-height: 1.2;margin: 0;}
.calculation-content .calculation-subtitle{font-size: 24px;line-height: 1;margin: 30px 0 0;font-weight: 500;color: var(--color02);}
.calculation-content .calculation-highlight{font-size: 30px;line-height: 1;margin: 6px 0 0;font-weight: 800;color: var(--color01);}
.calculation-content .calculation-action{display: flex;align-items: center; margin: 40px 0 0;}
.calculation-content .calculation-action .btn-action{display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 50px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: 0.3px;border-radius: 4px; position: relative; overflow: hidden;}
.calculation-content .calculation-action .btn-action:before{content:''; position: absolute; width: 200px; height: 200px; background-color: var(--shades01);top: 50%; left: 50%; transform: translate(-50%,-50%) scale(0); opacity: 0; border-radius: 50%; transition: all 0.3s ease;}
.calculation-content .calculation-action .btn-action:hover:before{transform: translate(-50%,-50%) scale(1);opacity: 0.3; }

.section-search{position: relative; padding: 100px 0; display: flex; align-items: center;background-color: var(--color01);z-index: 1;position: relative;}
.section-search:before{}
.section-search .overlay-section{ position: absolute;top: 0;left: 0;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;background-color: rgb(36 173 79 / 80%);z-index: 3;font-size: 100px;font-weight: 600;color: var(--shades01);}
.section-search .overlay-section .text{    display: flex;align-items: center;justify-content: center;top: 50%;transform: translateY(-50%);position: relative;font-size: 40px;color: var(--shades01);text-transform: uppercase;}
.section-search .search .search-title{font-size: 48px;font-weight: 700;margin: 0;line-height: 1.2;color: var(--shades01);}
.section-search .search .search-text{font-size: 16px;font-weight: 300;letter-spacing: 0.3px;color: var(--shades01);line-height: 1.4;margin: 20px 0 0;}
.section-search .search .search-image{width: 100%;}
.section-search .search .search-image img{width: 100%; height: 100%;object-fit: cover;}
.section-search .nav-tabs{border-bottom: 2px solid rgba(255,255,255,0.2); margin: 30px 0 0;}
.section-search .nav-tabs .nav-item{}
.section-search .nav-tabs .nav-item+.nav-item{ margin-left: 30px;}
.section-search .nav-tabs .nav-link{border: 0;background-color: transparent;color: var(--shades01);border-radius: 0;padding: 0;line-height: 1;height: 50px;display: flex;justify-content: center;align-items: center;font-size: 16px;font-weight: 500;text-transform: uppercase;opacity: 0.5;position: relative;z-index: 1;}
.section-search .nav-tabs .nav-link:after{content: ''; position: absolute;z-index: 1; bottom: 0; left: 0; width: 100%; border-bottom: 6px solid var(--shades01);opacity: 0; transition: alll 0.3s ease;}
.section-search .nav-tabs .nav-link.active{opacity: 1;}
.section-search .nav-tabs .nav-link.active:after{opacity: 1;}
.section-search .tab-content{}
.section-search .tab-content .tab-pane{}
.section-search .search-plate-content{position: relative;padding: 40px 0 0;}
.section-search .summon-content{position: relative;padding: 40px 0 0;}
.section-search .form-content{}
.section-search .form-group{}
.section-search .control-label{font-size: 11px;text-transform: uppercase;color: var(--shades01);letter-spacing: 0.5px;font-weight: 400;line-height: 1;}
.section-search .form-control{background-color: transparent;border: 0;border-radius: 0;border-bottom: 2px solid rgba(255,255,255,0.2);padding: 0;width: 100%;height: 40px;color: var(--shades01);font-size: 16px;font-weight: 500;transition: all 0.3s ease;}
.section-search .form-control::-webkit-input-placeholder{ color:rgba(255,255,255,0.5) !important;}
.section-search .form-control:focus{ border-color:var(--shades01);}
.section-search .dropdown{}
.section-search .dropdown .dropdown-toggle{padding: 0;height: 40px;width: 100%;border: 0;display: flex;justify-content: flex-start;align-items: center;background-color: transparent;border-bottom: 2px solid rgba(255,255,255,0.2);color: var(--shades01);font-size: 16px;font-weight: 500;}
.section-search .dropdown .dropdown-menu{border: 0;width: 100%;padding:5px 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 20px;}
.section-search .dropdown .dropdown-search{padding: 0 10px; margin: 0 0 5px;}
.section-search .dropdown .dropdown-search .form-control{background-color: transparent;border: 0;border-radius: 0;border-bottom: 1px solid var(--color02);padding: 0;width: 100%;height: 40px;color: var(--color02);font-size: 13px;font-weight: 500;transition: all 0.3s ease;}
.section-search .dropdown .dropdown-search .form-control::-webkit-input-placeholder{ color:var(--color02) !important;}
.section-search .dropdown .dropdown-search .form-control:focus{ border-color:var(--color02);}
.section-search .dropdown .dropdown-scroll{max-height: 200px;overflow: auto;}
.section-search .dropdown .dropdown-scroll::-webkit-scrollbar{width: 6px;height: 6px;background-color: transparent; border-radius: 10px}
.section-search .dropdown .dropdown-scroll::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 8px}
.section-search .btn-action{border:1px solid var(--shades01);display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 50px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: 0.3px;border-radius: 4px;position: relative;overflow: hidden;}
.section-search .btn-action:before{content:''; position: absolute; width: 200px; height: 200px; background-color: var(--shades01);top: 50%; left: 50%; transform: translate(-50%,-50%) scale(0); opacity: 0; border-radius: 50%; transition: all 0.3s ease; }
.section-search .btn-action:hover:before{transform: translate(-50%,-50%) scale(1);opacity: 0.3; }


.section-services{position: relative; padding: 100px 0;background-color: var(--shades01); z-index: 0;}
.section-services .services-background{position: absolute;width: 50%;bottom: 0;left: -10%;z-index: -1;}
.section-services .services-background img{width: 100%; height: 100%; object-fit: contain;}
.section-services .container{display: flex; flex-wrap: wrap;}
.service-content{width: 100%; max-width: calc(100% - 800px);}
.service-content .service-title{font-size: 48px;font-weight: 700;margin: 0;line-height: 1.2;color: var(--shades02);}
.service-content .service-text{font-size: 16px;font-weight: 300;letter-spacing: 0.3px;color: var(--color02);line-height: 1.4;margin: 20px 0 0;}
.service-infographics{width: 800px;}
.service-infographics .service-circle{width: 600px;height: 600px;display: flex; align-items: center; justify-content: center; position: relative;margin: 0 auto;}
.service-infographics .service-circle:before{content:''; position: absolute;width: calc(100% - 80px);height: calc(100% - 80px);border-radius: 50%;border: 2px dashed var(--shades02);opacity: 0.1;}
.service-infographics .service-item{position: absolute;transform: translateY(-50%) rotate(calc(360deg / 5 * var(--i)));transform-origin: 300px;left: 0;top: 50%;font-size: 60px; cursor: default;}
.service-infographics .service-item .service-inner{transform: rotate(calc(360deg / -5 * var(--i)));}
.service-infographics .service-item .service-icon{position: relative;width: 80px;height: 80px;border-radius: 50%;display: flex;align-items: center;justify-content: center;background-color: var(--shades01);font-size: 40px;color: var(--color02);box-shadow: rgb(0 0 0 / 10%) 0 0 20px;transition: all 0.3s ease;z-index: 1;overflow: hidden;}
.service-infographics .service-item .service-icon:before{content:''; position: absolute; width: 200px; height: 200px; background-color: var(--color01);top: 50%; left: 50%; transform: translate(-50%,-50%) scale(0); opacity: 0; border-radius: 50%;z-index: -1; transition: all 0.3s ease; }
.service-infographics .service-item .service-title{position: absolute;display: block;top: 100%;left: 50%;transform: translateX(-50%);text-align: center;font-size: 13px;font-weight: 600;text-transform: uppercase;line-height: 1;letter-spacing: 0.3px;margin: 10px 0 0;padding: 4px 0;background-color: var(--shades01);transition: all 0.3s ease;width: 140px;}
.service-infographics .service-item:hover .service-icon{color: var(--shades01);}
.service-infographics .service-item:hover .service-icon:before{transform: translate(-50%,-50%) scale(1);opacity:1;}
.service-infographics .service-item:hover .service-title{color: var(--color01);}
.service-infographics .service-center{width: 240px;height: 240px;border-radius: 50%;padding: 20px;border: 2px dashed var(--shades04);}
.service-infographics .service-center .logo{display: block; width: 100%; height: 100%;}
.service-infographics .service-center .logo img{width:100%; height: 100%; object-fit: contain;}

.section-tickets{position: relative; padding: 100px 0;background-color: var(--color02); z-index: 0;}
.section-tickets .tickets-image{width: 100%; margin: 20px 0 0;}
.section-tickets .tickets-image img{width: 100%; height: 100%; object-fit: contain;}
.section-tickets .container{display: flex; flex-wrap: wrap; align-items: center;}
.ticket-content{width: 100%; max-width: calc(40% - 60px); margin-left: 60px;}
.ticket-content .ticket-title{font-size: 48px;font-weight: 700;margin: 0;line-height: 1.2;color: var(--shades01);}
.ticket-content .ticket-text{font-size: 16px;font-weight: 300;letter-spacing: 0.3px;color: var(--shades01);line-height: 1.4;margin: 20px 0 0;}
.tickets{width: 100%; max-width: 60%;}
.tickets .ticket-inner{position: relative;}
.tickets .ticket-item{position: relative;display: flex;background-color: var(--shades01);border-radius: 6px;box-shadow: rgb(0 0 0 / 20%) 0 2px 5px;padding: 10px 20px;}
.tickets .ticket-item+.ticket-item{ margin: 16px 0 0;}
.tickets .ticket-item .ticket-start{width: 100%; max-width: calc(100% - 180px); margin-right: 20px;}
.tickets .ticket-item .ticket-id{position: relative;}
.tickets .ticket-item .ticket-label{font-size: 11px;text-transform: uppercase;font-weight: 400;color: var(--color02);line-height: 1;margin: 0 0 2px;}
.tickets .ticket-item .ticket-data{font-size: 16px;line-height: 1;font-weight: 600;color: var(--shades02);}
.tickets .ticket-item .ticket-date{font-size: 12px;font-weight: 600;color: var(--shades02);margin: 0 0 8px;}
.tickets .ticket-item .ticket-meta{display:flex; flex-wrap: wrap; margin: 6px -10px 0;}
.tickets .ticket-item .ticket-meta .meta-item{ margin:0 10px;}
.tickets .ticket-item .ticket-meta .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 400;color: var(--color02);line-height: 1;margin: 0 0 4px;}
.tickets .ticket-item .ticket-meta .meta-data{font-size: 14px;line-height: 1;font-weight: 600;color: var(--shades02);}
.tickets .ticket-item .ticket-meta .meta-item-full{}
.tickets .ticket-item .ticket-end{width: 160px; margin-left: auto; display: flex; flex-direction: column; justify-content: space-between;}
.tickets .ticket-item .ticket-status{display: flex;justify-content: flex-end;}
.tickets .ticket-item .ticket-status .status{font-size: 13px;font-weight: 500;background-color: var(--color02);line-height: 1;padding: 4px 10px;border-radius: 2px;color: var(--shades01);}
.tickets .ticket-item .ticket-status .status.pending{background-color: #ffc107;}
.tickets .ticket-item .ticket-status .status.paid{background-color: var(--color01);}
.tickets .ticket-item .ticket-status .status.resolved{background-color: #f10750;}
.tickets .ticket-item .ticket-plate{display: flex; flex-direction:column; align-items: flex-end;}
.tickets .ticket-item .ticket-plate .plate-label{font-size: 11px;text-transform: uppercase;font-weight: 400;color: var(--color02);line-height: 1;margin: 0 0 4px;}
.tickets .ticket-item .ticket-plate .plate-data{display: flex;align-items: center;justify-content: center;width: 100px;height: 40px;border: 2px solid var(--color02);border-radius: 6px;background-color: var(--shades03);font-weight: 800;color: var(--color01);font-size: 14px;letter-spacing: 0.3px;}


.section-plans{position: relative; padding: 100px 0;background-color: var(--shades01);}
.plans-content{position: relative;display: flex;flex-direction: column;}
.plans-content .plans-title{font-size: 48px;font-weight: 700;margin: 0;line-height: 1.2;color: var(--shades02);}
.plans-content .plans-text{font-size: 16px;font-weight: 300;letter-spacing: 0.3px;color: var(--color02);line-height: 1.4;margin: 20px 0 0;}
.plans-content .plans-image{margin: 40px 0 0; width: 100%;}
.plans-content .plans-image img{width: 100%; height: 100%; object-fit: contain;}

.pricing-plans{position: relative; display: flex; flex-wrap: wrap; margin: 0 -5px;}
.pricing-plans .plan{width: 100%; max-width: calc(33.33% - 10px); margin: 5px; padding:60px 40px; border-radius: 20px;background-color: var(--shades03);  display: flex; flex-direction: column; transition: all 0.3s ease;}
.pricing-plans .plan .plan-name{font-size: 20px;line-height: 1.4;font-weight: 400;color: var(--color02);transition: all 0.3s ease;}
.pricing-plans .plan .plan-price{display: flex;align-items: center;margin: 20px 0;font-size: 16px;font-weight: 400;color: var(--shades06); line-height: 1;transition: all 0.3s ease;}
.pricing-plans .plan .plan-price span{display: block;font-size: 40px;font-weight: 700;letter-spacing: -0.5px;margin-right: 10px;color: var(--color01);}
.pricing-plans .plan .plan-text{font-size: 14px;font-weight: 400;color: var(--shades06);line-height: 1.2;letter-spacing: 0.3px;transition: all 0.3s ease;}
.pricing-plans .plan .plan-options{position: relative;margin: 20px 0;}
.pricing-plans .plan .option{position: relative;padding: 10px 0;line-height: 1;color: var(--color02);font-weight: 500;letter-spacing: 0.3px;font-size: 14px;padding-left: 24px;transition: all 0.3s ease;}
.pricing-plans .plan .option:before{content:'\f00c'; font-family: 'Font Awesome 6 Pro';position: absolute;top: 9px;left: 0;width: 16px;height: 16px;background-color: var(--shades06);display: flex;align-items: center;justify-content: center;border-radius: 50%;line-height: 1;color: var(--shades01);font-size: 10px;font-weight: 500;}
.pricing-plans .plan .plan-action{display: flex; align-items: center; justify-content: center; margin: auto 0 0;}
.pricing-plans .plan .btn-action{border:0; width: 100%; display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 44px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: 0.3px;border-radius: 4px; position: relative; overflow: hidden;}
.pricing-plans .plan .btn-action:before{content:''; position: absolute; width: 280px; height: 280px; background-color: var(--shades01);top: 50%; left: 50%; transform: translate(-50%,-50%) scale(0); opacity: 0; border-radius: 50%; transition: all 0.3s ease;}
.pricing-plans .plan .btn-action:hover:before{transform: translate(-50%,-50%) scale(1);opacity: 0.3;}
.pricing-plans .plan:hover{background-color: var(--color02);}
.pricing-plans .plan:hover .plan-name{color: var(--shades01);}
.pricing-plans .plan:hover .option{color: var(--shades01);}
.pricing-plans .plan.active{background-color: var(--color01);}
.pricing-plans .plan.active .plan-name{color: var(--shades01);}
.pricing-plans .plan.active .plan-price{color: var(--shades03);}
.pricing-plans .plan.active .plan-price span{ color: var(--shades03);}
.pricing-plans .plan.active .plan-text{color: var(--shades03);font-weight: 300;}
.pricing-plans .plan.active .option{color: var(--shades03); font-weight: 400;}
.pricing-plans .plan.active .option:before{background-color: var(--shades03); color: var(--color01); font-weight: 600;}
.pricing-plans .plan.active .btn-action{background-color: var(--color02);}


.section-testimonial{position: relative; padding: 180px 0 100px; display: flex; align-items: center; background-color: var(--shades01);z-index:0;}
.section-testimonial .testimonial-background{position: absolute;width: 100%;height: 100%;top: 0;left: 0;z-index: -1;}
.section-testimonial .testimonial-background:before{content:'';position: absolute;width: 100%;height: 100%;top: 0;left: 0; background-color: var(--color01); opacity: 0.8;}
.section-testimonial .testimonial-background img{width: 100%; height: 100%; object-fit: cover;}
.testimonial{position: relative; display:flex; align-items: center; flex-wrap: wrap; margin: 0 -15px;padding: 60px 30px;z-index: 0;}
.testimonial:before{content:'';position: absolute;width: calc(100% - 200px);height: 100%;top: 0;left: 0; background-color: var(--color01); z-index: -1; border-radius: 30px;}
.testimonial .testimonial-icon{position: absolute;width: 100px;height: 100px;background-color: var(--color02);color: var(--shades01);display: flex;align-items: center;justify-content: center;line-height: 1;border-radius: 50%;font-size: 60px;top: -60px;left: 40px;}
.testimonial .testimonial-content{width: 400px; margin: 0 15px;}
.testimonial .testimonial-content .testimonial-title{font-size: 48px;font-weight: 700;margin: 0;line-height: 1;color: var(--shades01);}
.testimonial .testimonial-content .testimonial-text{font-size: 16px;font-weight: 300;letter-spacing: 0.3px;color: var(--shades01);line-height: 1.4;margin: 20px 0 0;}
.testimonial .testimonial-slider{width: 100%; max-width: calc(100% - 460px); margin: 0 15px;position: relative;padding-bottom: 40px;}
.testimonial .swiper-slide{padding: 40px 5px 10px;height: auto;display: flex;}
.testimonial .testimonial-item{position: relative;padding: 20px;background-color: var(--shades01);border-radius: 10px;box-shadow: rgb(0 0 0 / 15%) 0 2px 10px;}
.testimonial .testimonial-item .item-header{position: relative;margin: -50px 0 0;}
.testimonial .testimonial-item .item-image{width: 60px; height: 60px; overflow: hidden; border-radius: 50%;}
.testimonial .testimonial-item .item-image img{width: 100%;height: 100%; object-fit: cover;}
.testimonial .testimonial-item .item-info{margin: 10px 0 0;}
.testimonial .testimonial-item .item-title{font-size: 15px;font-weight: 600;color: var(--color02);}
.testimonial .testimonial-item .item-subtitle{font-size: 13px;font-weight: 400;color: var(--shades06);}
.testimonial .testimonial-item .item-icon{position: absolute;top: 10px;right: 10px;font-size: 30px;color: var(--color01);}
.testimonial .testimonial-item .item-body{padding: 20px 0 0;font-size: 13px;font-weight: 400;color: var(--shades02);line-height: 1.4;}
.testimonial .testimonial-item .wave{position: absolute;bottom: 0;left: 0;opacity: 0.3; width: 100%;overflow: hidden; border-radius:0 0 10px 10px;}
.testimonial .testimonial-item .wave img{width: 100%;}
.testimonial .swiper-button-prev{position: absolute;top: auto;bottom: 0px;left: 5px;height: 30px;width: 30px;color: var(--color01);background-color: var(--shades01);display: flex;align-items: center;justify-content: center;margin: 0;border-radius: 4px;font-size: 20px;}
.testimonial .swiper-button-prev:before{content: '\f104';font-family: "Font Awesome 6 Pro";position: absolute;font-size: 22px;font-weight: 500;}
.testimonial .swiper-button-next{position: absolute;top: auto;bottom: 0px;left: 40px;height: 30px;width: 30px;color: var(--color01);background-color: var(--shades01);display: flex;align-items: center;justify-content: center;margin: 0;border-radius: 4px;font-size: 20px;}
.testimonial .swiper-button-next:before{content: '\f105';font-family: "Font Awesome 6 Pro";position: absolute;font-size: 22px;font-weight: 500;}
.testimonial .swiper-button-prev:after,.testimonial .swiper-button-next:after{display:none;}
.testimonial .swiper-pagination{width:auto;left: auto;right: 5px;}
.testimonial .swiper-pagination .swiper-pagination-bullet{margin: 0 2px;width: 12px;height: 12px;background-color: var(--shades01);opacity: 0.4;}
.testimonial .swiper-pagination .swiper-pagination-bullet-active{opacity: 1;}
.testimonial .testimonial-slider .swiper{padding-bottom: 40px;}

.section-clients{position: relative; padding: 60px 0; background-color: var(--shades01);}
.client-content{position: relative;display: flex;flex-wrap: wrap;margin: 0 -15px;}
.client-content .client-info{width: 100%; max-width: calc(30% - 30px); margin: 0 15px;}
.client-content .client-title{font-size: 32px;font-weight: 700;margin: 0;line-height: 1.2;color: var(--shades02);}
.client-content .client-text{font-size: 16px;font-weight: 300;letter-spacing: 0.3px;color: var(--color02);line-height: 1.4;}
.clients{width: 100%; max-width: calc(70% - 30px); margin: 0 15px;}
.clients .client-list{display: flex;align-items: center;flex-wrap: wrap;}
.clients .client-list .client-item{width: 100%; max-width: 20%; padding: 0 10px; transition: all 0.3s ease; filter: grayscale(1);}
.clients .client-list .client-item img{width: 100%; height: 100%; object-fit: contain;}
.clients .client-list .client-item:hover{filter: grayscale(0);}

.section-whyus{position: relative; padding: 100px 0;background-color: var(--shades03);z-index: 0;}
.whyus-content{position: relative;display: flex;flex-wrap: wrap;margin: 0 -15px;}
.whyus-info{width: 100%; max-width: calc(30% - 30px); margin: 0 15px;}
.whyus-info .whyus-title{font-size: 48px;font-weight: 700;margin: 0;line-height: 1;color: var(--shades02);}
.whyus-info .whyus-text{font-size: 16px;font-weight: 300;letter-spacing: 0.3px;color: var(--shades02);line-height: 1.4;margin: 20px 0 0;}
.whyus-info .whyus-image{width: 100%; margin: 20px 0 0;}
.whyus-info .whyus-image img{width: 100%; height: 100%; object-fit: contain;}
.whyus{width: 100%; max-width: calc(70% - 30px); margin: 0 15px;}
.whyus .whyus-list{display: flex;flex-wrap: wrap;margin: 0 -10px;}
.whyus .whyus-item{width: 100%;max-width: calc(25% - 20px);margin: 10px;background-color: var(--shades01);padding: 20px;border-radius: 10px;box-shadow: rgb(0 0 0 / 5%) 0 2px 10px; transition: all 0.3s ease;}
.whyus .whyus-item:hover{box-shadow: rgb(0 0 0 / 15%) 0 4px 30px;}
.whyus .whyus-item .item-icon{font-size: 60px;color: var(--color01);margin: 0 auto 20px;text-align: center;}
.whyus .whyus-item .item-title{font-size: 16px;font-weight: 600;text-align: center;color: var(--color02);line-height: 1.2;}
.whyus .whyus-item .item-text{font-size: 13px;font-weight: 400;text-align: center;color: var(--shades06);line-height: 1.2;margin: 10px 0 0;}

/* ********|| CONTENT ENDS ||******** */

/* ********|| FOOTER STARTS ||******** */
.pre-footer{position: absolute;width: 100%;bottom: -1px;left: 0;z-index: -1;}
.pre-footer img{width: 100%; height: 100%; object-fit: cover;}
footer{position: relative; padding: 100px 0 50px; background-color: var(--color01);}
footer .footer-content{position: relative; margin: 0 0 20px;}
footer .brand{height:60px; overflow: hidden; margin-right: auto;}
footer .brand .logo{width: 100%; height: 100%; display: block;}
footer .brand .logo img{width: 100%; height: 100%; object-fit: contain; object-position: center left;filter: invert(1) brightness(5);}
footer .footer-title{font-size: 16px;text-transform: uppercase;font-weight: 600;color: var(--shades01);letter-spacing: 0.5px;margin: 0 0 20px;}
footer .footer-text{font-size: 14px;font-weight: 400;color: var(--shades01);line-height: 1.4;letter-spacing: 0.3px;margin: 20px 0 0;}
footer .footer-links{position: relative;display: flex;flex-wrap: wrap;}
footer .footer-links .link-item{display: flex;width: 100%;max-width: 50%;}
footer .footer-links .link{font-size: 14px;font-weight: 400;color: var(--shades01);line-height: 1.2;padding: 10px 0;display: flex;letter-spacing: 0.5px; }
footer .footer-links .link:hover{transform: translateX(5px);}
footer .footer-contact{position: relative;}
footer .footer-contact .contact-item{font-size: 14px;font-weight: 400;color: var(--shades01);display: flex;padding: 10px 0;}
footer .footer-contact .contact-item i{font-size: 18px;}
footer .footer-contact .contact-item:hover{transform: translateX(5px);}
footer .footer-download{position: relative;}
footer .footer-download .download-item{display: flex;align-items: center;justify-content: center;line-height: 1;height: 40px;background-color: var(--shades01);border-radius: 5px;width: 100%;max-width: 160px;color: var(--color01);}
footer .footer-download .download-item+.download-item{margin: 10px 0 0;}
footer .footer-download .download-item .icon{width: 20px;height: 20px;margin-right: 10px;}
footer .footer-download .download-item .icon img{width: 100%;height: 100%;object-fit: contain;transition: all 0.3s ease;}
footer .footer-download .download-item .title{font-size: 14px;color: var(--color02); font-weight: 600;transition: all 0.3s ease;}
footer .footer-download .download-item:hover{background-color:var(--shades02);}
footer .footer-download .download-item:hover .icon img{filter:brightness(5);}
footer .footer-download .download-item:hover .title{color: var(--shades01);}

.footer-bottom{position: relative;display: flex; align-items: center; padding: 30px 0 0;border-top: 2px solid rgba(255,255,255,0.15);}
.footer-bottom .copyright{margin-right: auto;font-size: 13px;font-weight: 400;color: var(--shades01);letter-spacing: 0.3px;}
.footer-bottom .other-links{margin-left: auto;}
.footer-bottom .other-links .links-item{font-size: 13px;font-weight: 400;color: var(--shades01);letter-spacing: 0.3px;opacity: 0.7;}
.footer-bottom .other-links .links-item:hover{opacity: 1;text-decoration: underline;}
.footer-bottom .other-links .links-item+.links-item{margin-left: 8px;}
.footer-bottom .social{display: flex;justify-content: flex-end;align-items: center; margin: 0 0 0 20px; padding: 0 0 0 20px; border-left: 1px solid rgba(255,255,255,0.2);}
.footer-bottom .social .social-item{display: flex;justify-content: center;align-items: center;padding: 0;color: var(--shades01);font-size: 18px; opacity: 0.7;}
.footer-bottom .social .social-item:hover{opacity: 1;}
.footer-bottom .social .social-item+.social-item{margin-left: 8px;}

/* ********|| FOOTER ENDS ||******** */

/* ********|| OTHER PAGES STARTS ||******** */

/* ********|| OTHER PAGES ENDS ||******** */

/* ********|| MODAL STARTS ||******** */
.modal{backdrop-filter: blur(10px); transition: all 0.3s ease;}
.modal-dialog{}
.modal-content{border: 0;border-radius: 10px;box-shadow: rgb(0 0 0 / 10%) 0 2px 30px;overflow: hidden;}
.modal-body{}
.authenticationModal .address-select{display:flex;align-items:center}
.authenticationModal .address-select .custom-radio+ .custom-radio{margin-left: 20px;}
.authenticationModal{border-radius: 10px; overflow: hidden;}
.authenticationModal .form-group.address-select-type{margin: 6px 0 9px;}

.authenticationModal .custom-radio .custom-radio-label:before{border: 1px solid var(--color01);}
.authenticationModal .commercial-inner.login-type{margin: 0 0 20px;}
.authenticationModal .commercial-inner.login-type .radio-group{display: flex;align-items: center;}
.authenticationModal .commercial-inner.login-type .radio-group .custom-radio+.custom-radio{margin-left: 20px;}

.authenticationModal .email-content.active, .authenticationModal .phone-content.active{display:block;}
.authenticationModal .email-content, .authenticationModal .phone-content{display: none;}

.authenticationModal .modal-content{overflow: unset; box-shadow: none;}
.authenticationModal .modal-body{padding: 0; background-color: transparent;}
.authenticationModal .modal-close{width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;position: absolute;top: -20px;right: -20px;z-index: 9;background-color: var(--shades01);border-radius: 50%;box-shadow: rgb(0 0 0 / 10%) 0 2px 10px;font-size: 24px;color: var(--color02);}
.authenticationModal .modal-close:hover{transform: rotate(90deg);background-color: var(--color01);color: var(--shades01);}
.authenticationModal .authentication{ display: flex;background-color: var(--shades03);align-items: center;min-height: 500px;border-radius: 10px;box-shadow: rgb(0 0 0 / 40%) 0 2px 30px;}
.authenticationModal .auth-image{width: 100%;max-width: 45%;padding: 35px;}
.authenticationModal .auth-image img{width: 100%;height: 100%; object-fit: contain;}
.authenticationModal .auth-content{width: 100%;max-width: 55%;padding: 30px;}
.authenticationModal .auth-title{font-size: 36px;font-weight: 700;margin: 0;line-height: 1.2;color: var(--color02);}
.authenticationModal .auth-text{font-size: 16px;font-weight: 300;letter-spacing: 0.3px;color: var(--color02);line-height: 1.4;margin: 20px 0 0;}
.authenticationModal .form-content{position: relative;margin: 30px 0 0;}
.authenticationModal .form-group{position: relative; margin: 0 0 16px;}
.authenticationModal .input-style{position: relative;overflow: hidden;display: flex;background-color: var(--shades01);border-radius: 6px;box-shadow: rgb(0 0 0 / 5%) 0 2px 10px;}
.authenticationModal .input-style .input-icon{width: 40px;min-width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;font-size: 18px;color: var(--color01);background-color: transparent;border-right: 1px solid var(--shades03);}
.authenticationModal .input-style .input{width: 100%;border: 0;border-radius: 0;padding: 0 10px;background-color: transparent;color: var(--color02);font-weight: 500;letter-spacing: 0.3px;}
.authenticationModal .input-style .btn-show-password{width: 40px;min-width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;font-size: 14px;color: var(--shades06);background-color: transparent;}
.authenticationModal .timer{padding: 10px 0 0;display: flex;justify-content: flex-end;font-weight: 500;font-size: 13px;color: var(--color01);}
.authenticationModal .form-option{display: flex;justify-content: space-between; align-items: center;}
.authenticationModal .custom-checkbox{margin-right: auto;}
.authenticationModal .custom-checkbox .custom-checkbox-label{font-size: 13px;font-weight: 400; line-height: 1.2;}
.authenticationModal .link{font-weight: 500; color: var(--color01);}
.authenticationModal .btn-forgot-pasword{margin-left: auto; height: auto; padding: 0; background-color: transparent; font-size: 13px;font-weight: 500;color: var(--color01);}
.authenticationModal .form-action{display: flex;justify-content: center;align-items: center;margin: 20px 0 0;width: 100%;}
.authenticationModal .form-action .btn-action{ position: relative; overflow: hidden; display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 40px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: 0.3px;border-radius: 4px;width: 100%;max-width: 200px;}
.authenticationModal .form-action .btn-action i{font-size: 16px;}
.authenticationModal .form-action .btn-action:before{content:''; position: absolute; width: 220px; height: 220px; background-color: var(--shades01);top: 50%; left: 50%; transform: translate(-50%,-50%) scale(0); opacity: 0; border-radius: 50%; transition: all 0.3s ease;}
.authenticationModal .form-action .btn-action:hover:before{transform: translate(-50%,-50%) scale(1);opacity: 0.3;}
.authenticationModal .form-action .btn-action:disabled{background-color: var(--shades06);}

.authenticationModal .auth-options{display: flex;align-items: center;justify-content: center;margin: 20px 0 0;font-size: 13px;font-weight: 400;color: var(--color02);}
.authenticationModal .btn-signup-trigger, .authenticationModal .btn-signin-trigger{margin-left: 10px;height: auto;padding: 0;background-color: transparent;font-size: 13px;font-weight: 500;color: var(--color01);}
.authenticationModal .signin-content,.authenticationModal .signup-content,.authenticationModal .forgot-content{display: none;}
.authenticationModal .signin-content.active,.authenticationModal .signup-content.active,.authenticationModal .forgot-content.active{display: block;}


.authenticationModal .form-group .timer{
font-weight: 600;
margin-top: 10px;
display: flex;
justify-content: flex-end;
color: var(--color01);
}
.authenticationModal .form-content.sign-up .form-action{
flex-wrap: wrap;
}
.authenticationModal .form-group{

}

.authenticationModal .dropdown .dropdown-toggle{padding: 0;height: 40px;width: 100%;border: 0;display: flex;justify-content: flex-start;align-items: center;    background-color:var(--shades01);
border-radius: 6px;
box-shadow: rgb(0 0 0 / 5%) 0 2px 10px;
color: var(--color02);font-size: 16px;font-weight: 500;
letter-spacing: 0.3px;padding: 0 10px;padding-right: 10px !important;}
.authenticationModal .dropdown .dropdown-toggle::after{color: var(--color02);right: 7px;}
.authenticationModal .dropdown .dropdown-menu{border: 0;width: 100%;padding:5px 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 20px;}
.authenticationModal .dropdown .dropdown-search{padding: 0 10px; margin: 0 0 5px;}
.authenticationModal .dropdown .dropdown-search .form-control{background-color: transparent;border: 0;border-radius: 0;border-bottom: 1px solid var(--color02);padding: 0;width: 100%;height: 40px;color: var(--color02);font-size: 13px;font-weight: 500;transition: all 0.3s ease;}
.authenticationModal .dropdown .dropdown-search .form-control::-webkit-input-placeholder{ color:var(--color02) !important;}
.authenticationModal .dropdown .dropdown-search .form-control:focus{ border-color:var(--color02);}
.authenticationModal .dropdown .dropdown-scroll{max-height: 200px;overflow: auto;}
.authenticationModal .dropdown .dropdown-scroll::-webkit-scrollbar{width: 6px;height: 6px;background-color: transparent; border-radius: 10px}
.authenticationModal .dropdown .dropdown-scroll::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 8px}
.authenticationModal .btn-action{border:1px solid var(--shades01);display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 50px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: 0.3px;border-radius: 4px;position: relative;overflow: hidden;}
.authenticationModal .btn-action:before{content:''; position: absolute; width: 200px; height: 200px; background-color: var(--shades01);top: 50%; left: 50%; transform: translate(-50%,-50%) scale(0); opacity: 0; border-radius: 50%; transition: all 0.3s ease; }
.authenticationModal .btn-action:hover:before{transform: translate(-50%,-50%) scale(1);opacity: 0.3; }

.searchTicketModal{display: block; opacity: 1 !important;}



.pages-banner{padding: 60px 0;}
.pages-banner .page-title{font-size: 48px;font-weight: 700;margin: 0;line-height: 1.2;color: var(--shades02);}
.pages-banner .page-banner-content{font-size: 20px;font-weight: 400;letter-spacing: 0.3px;color: var(--color02);line-height: 1.4;margin: 20px 0 0;}
.pages-banner .pages-banner-img{}
.pages-banner .pages-banner-img img{width: 100%;height: 100%;}

.breadcrumb-part {padding: 30px 0;}
.breadcrumb-part .breadcrumb-box {display: flex;align-items: center;}
.breadcrumb-part .breadcrumb-box .item-breadcrumb{position: relative;}
.breadcrumb-part .breadcrumb-box .item-breadcrumb+ .item-breadcrumb{padding-left: 20px;}
.breadcrumb-part .breadcrumb-box .item-breadcrumb:before{content: '\f105';position: absolute;font-family: "Font Awesome 5 Pro";right: -12px;font-size: 18px;}
.breadcrumb-part .breadcrumb-box .item-action{font-size: 14px;color: var(--color01);font-weight: 400;}
.breadcrumb-part  .item-breadcrumb.active:before{visibility: hidden;}
.breadcrumb-part .breadcrumb-box .item-breadcrumb.active .item-action {pointer-events: none;color: var(--secondaryColor);}

.what-we-do-section{position: relative;padding: 100px 0;background-color: var(--color02);z-index: 0;}
.what-we-do-section .image-inner{width: 100%;height: 100%;}
.what-we-do-section .image-inner img{width: 100%;height: 100%;object-fit: cover;}
.what-we-do-section .title{font-size: 48px;font-weight: 700;margin: 0;line-height: 1.2;color: var(--shades01);}
.what-we-do-section .sub-title{font-size: 16px;font-weight: 300;letter-spacing: 0.3px;color: var(--shades01);line-height: 1.4;margin: 20px 0 0;}
.what-we-do-section .content{font-size: 20px;font-weight: 300;text-align: left;color: var(--shades01);line-height: 1.6;margin: 10px 0 0;}

.feature-section{position: relative;padding: 100px 0;background-color: var(--shades03);z-index: 0;}
.feature-section .container {display: flex;flex-wrap: wrap;align-items: center;}
.feature-section .feature-part{width: 100%;max-width: calc(50% - 30px);margin: 0 15px;}
.feature-list{display: flex;flex-wrap: wrap;margin: 0 -10px;}
.feature-section .feature-box{width: 100%;max-width: calc(50% - 20px);margin: 10px;background-color: var(--shades01);padding: 20px;border-radius: 10px;box-shadow: rgb(0 0 0 / 5%) 0 2px 10px;transition: all 0.3s ease;}
.feature-box:hover{box-shadow: rgb(0 0 0 / 15%) 0 4px 30px;}
.feature-box .icon{font-size: 60px;color: var(--color01);margin: 0 auto 20px;text-align: center;}
.feature-box .feature-content{}
.feature-box .feature-content .title{font-size: 16px;font-weight: 600;text-align: center;color: var(--color02);line-height: 1.2;}
.feature-box .feature-content .description{font-size: 13px;font-weight: 400;text-align: center;color: var(--shades06);line-height: 1.2;margin:10px 0 0;}
.feature-section .feature-descriptions-img{width: 100%;max-width: calc(50% - 30px);margin: 0 15px;}
.feature-section .feature-descriptions-img img{}

.newsletter-form-section .form-content{display: flex;align-items: center;}
.newsletter-form-section .form-group{position: relative; margin: 0 20px 0 0;width: 100%;max-width: calc(100% - 250px);}
.newsletter-form-section .input-style{position: relative;overflow: hidden;display: flex;background-color: var(--shades01);border-radius: 6px;box-shadow: rgb(0 0 0 / 5%) 0 2px 10px;border: 1px solid var(--shades03);}
.newsletter-form-section .input-style .input-icon{width: 40px;min-width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;font-size: 18px;color: var(--color01);background-color: transparent;border-right: 1px solid var(--shades03);}
.newsletter-form-section .input-style .input{width: 100%;border: 0;border-radius: 0;padding: 0 10px;background-color: transparent;color: var(--color02);font-weight: 500;letter-spacing: 0.3px;}

.newsletter-form-section .form-action{display: flex;justify-content: flex-start;align-items: center;width: 250px;}
.newsletter-form-section .form-action .btn-action{ position: relative; overflow: hidden; display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 40px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: 0.3px;border-radius: 4px;width: 100%;max-width: 140px;}
.newsletter-form-section .form-action .btn-action i{font-size: 16px;}
.newsletter-form-section .form-action .btn-action:before{content:''; position: absolute; width: 220px; height: 220px; background-color: var(--shades01);top: 50%; left: 50%; transform: translate(-50%,-50%) scale(0); opacity: 0; border-radius: 50%; transition: all 0.3s ease;}
.newsletter-form-section .form-action .btn-action:hover:before{transform: translate(-50%,-50%) scale(1);opacity: 0.3;}


.section-newsletter{ position: relative;padding: 100px 0;z-index: 0;}
.section-newsletter .news-letter-content{position: relative;display: flex;flex-wrap: wrap;margin: 0 -15px;}
.section-newsletter .news-letter-content .newsletter-image{width: 100%;max-width: calc(50% - 30px);margin: 0 15px;}
.section-newsletter .news-letter-content .newsletter-image img{}
.section-newsletter .news-letter-content .newsletter-form-section{width: 100%;max-width: calc(50% - 30px);margin: 0 15px;}
.newsletter-form-section .news-letter-title{font-size: 48px;font-weight: 700;margin: 0;line-height: 1.2;color: var(--shades02);}
.newsletter-form-section .newsletter-des{font-size: 16px;font-weight: 400;letter-spacing: 0.3px;color: var(--color02);line-height: 1.4;margin: 20px 0 0;}


.form-action{display: flex;justify-content: flex-start;align-items: center;width: 250px;}
.form-action .btn-action{ position: relative; overflow: hidden; display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 40px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: 0.3px;border-radius: 4px;width: 100%;max-width: 180px;}
.form-action .btn-action i{font-size: 16px;}
.form-action .btn-action:before{content:''; position: absolute; width: 220px; height: 220px; background-color: var(--shades01);top: 50%; left: 50%; transform: translate(-50%,-50%) scale(0); opacity: 0; border-radius: 50%; transition: all 0.3s ease;}
.form-action .btn-action:hover:before{transform: translate(-50%,-50%) scale(1);opacity: 0.3;}

/* ********|| CONTACT US FORM STARTS ||******** */

.contact-section .form-inner .form-control{background:#f6f5f7;min-height:45px}
.contact-section .form-inner .form-control::placeholder{color:#959595!important}
.contact-section .contact-inner{padding:80px 40px}
.contact-section .contact-list {padding: 0;width: 100%;display: flex;flex-direction: column;justify-content: center;}
.contact-section .contact-inner .title{font-size:48px;font-weight:700;margin:0;line-height:1.2;color:var(--shades01)}
.contact-section .contact-inner .description{font-size:14px;font-weight:400;text-align:left;color:var(--shades06);line-height:1.2;margin:10px 0 20px}
.contact-section{background-color:var(--color02)}
.contact-section .contact-info{width:100%;background-color:var(--shades01);padding:20px;border-radius:10px;box-shadow:rgb(0 0 0 / 5%) 0 2px 10px;transition:all .3s ease}
.contact-section .contact-info+ .contact-info{margin-top:15px}
.contact-section .contact-info .contact-icon{font-size:30px;color:var(--color01);display:flex;justify-content:center}
.contact-section.contact-list{display:flex;flex-wrap:wrap;justify-content:center;align-items:center;padding:80px 20px;flex-direction:column}
.contact-section .contact-info .contact-icon i{border:1px dashed var(--lightColor);border-radius:50%;display:flex;align-items:center;justify-content:center}
.contact-section .contact-info .contact-content{display:flex;flex-direction:column;align-items:center;justify-content:center;padding:8px 0 0;font-size:18px;font-weight:600;letter-spacing:.3px;line-height:1.5;color:var(--color04);text-align:center}

.contact-section .contact-content .contact-item {font-size: 14px;font-weight: 500;line-height: 1;margin: 6px 0 0;}
.contact-section .contact-info .contact-content .sub-text{font-size:16px;color:var(--shades06);font-weight:500}

.career-section{position:relative;padding:100px 0;background-color:var(--shades03);z-index:0}
.career-section	.career-content{position:relative;display:flex;flex-wrap:wrap;margin:0 -15px;justify-content: space-between;}
.career-section	.career-content .career-info{width:100%;margin: 0 15px;max-width: calc(30% - 30px);position: sticky;top: 126px;margin-bottom: auto;}
.career-content .career-info .title{font-size:48px;font-weight:700;margin:0;line-height:1;color:var(--shades02)}
.career-content .career-info .career-text{font-size:16px;font-weight:300;letter-spacing:.3px;color:var(--shades02);line-height:1.4;margin:20px 0 0}
.career-content .career-info .career-image{width:100%;margin:20px 0 0}
.career-content .career-info .career-image img{width:100%;height:100%}
.career-section .career-list{width:100%;max-width:calc(60% - 30px);margin:0 15px}
.career-list .career-item{background-color:var(--shades01);cursor:pointer;padding:20px;border-radius:10px;box-shadow:rgb(0 0 0 / 5%) 0 2px 10px;transition:all .3s ease}

.career-list .career-item:hover{box-shadow:rgb(0 0 0 / 15%) 0 4px 30px}
.career-list .career-item+ .career-item{margin-top:15px}
.career-item .job-title{font-size:24px;font-weight:500;margin-bottom:10px;text-transform: capitalize;}
.career-item .job-description{font-size:16px;line-height:1.3;font-weight:400;color:var(--color02)}
.career-item .location{display:flex;align-items:baseline;margin-bottom:15px}
.career-item .job-detail{display: flex;justify-content: flex-end;align-items: flex-end;}
.career-item .location .icon{font-size:18px;color:var(--color02);margin-right:8px}
.career-item .location .text{font-size:14px;font-weight:400;text-transform:capitalize}
.career-item .job-detail-list{display:flex;align-items:center;flex-wrap: wrap;width: 100%;max-width: calc(100% - 120px);}
.career-item .job-detail-list .job-detail-item{display:flex;align-items:baseline;width: 33.33%; max-width: calc(33.33% - 10px);margin: 6px 0;}
.job-detail-list .job-detail-item .text{font-size:16px;font-weight:500;text-transform:capitalize}
.job-detail-list .job-detail-item .icon{font-size:18px;color:var(--color01);margin-right:8px}
.job-detail-list .job-detail-item:last-of-type{width: 66.66%;max-width: calc(66.66%);}
.view-more-action{display:flex;margin-top:25px}
.view-more-action .more-action{position:relative;overflow:hidden;display:flex;align-items:center;justify-content:center;padding:0 16px;height:40px;background-color:var(--color01);color:var(--shades01);font-size:14px;font-weight:600;text-transform:uppercase;letter-spacing:.3px;border-radius:4px}
.view-more-action .more-action:hover:before{transform:translate(-50%,-50%) scale(1);opacity:.3}
.view-more-action .more-action:before{content:'';position:absolute;width:200px;height:200px;background-color:var(--shades01);top:50%;left:50%;transform:translate(-50%,-50%) scale(0);opacity:0;border-radius:50%;transition:all .3s ease}

.apply-action{display: flex;justify-content: flex-end;margin-top: 10px;width: 120px;}
.apply-action .apply-btn{position: relative;overflow: hidden;display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 40px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: .3px;border-radius: 4px;}
.apply-action .apply-btn:hover:before{transform:translate(-50%,-50%) scale(1);opacity:.3}
.apply-action .apply-btn:before{content:'';position:absolute;width:200px;height:200px;background-color:var(--shades01);top:50%;left:50%;transform:translate(-50%,-50%) scale(0);opacity:0;border-radius:50%;transition:all .3s ease}

.pagination{display: flex;border-radius: 0;padding: 0;justify-content: center;align-items: center;margin: 20px 0 30px;}
.pagination .pagination-nav{width: 40px;height: 30px;border-radius: 6px;background-color: var(--shades06);font-size: 18px;padding: 0; color:var(--shades01);}
.pagination .pagination-nav:hover{background-color: var(--shades01);color:var(--shades06);}
.pagination .pagination-content{margin: 0 10px; display: flex; align-items: center;font-size: 14px;font-weight: 500; color: var(--color07);}
.pagination .pagination-input{width: 40px;height: 30px;border-radius: 6px;margin: 0 10px;border: 0;background-color: var(--color01);color: var(--shades01);text-align: center;padding: 0;font-size: 14px;font-weight: 600;}

.career-apply-modal .modal-header{padding:10px 22px}
.career-apply-modal .submit-action{margin: 20px 0 0;}
.career-apply-modal .btn-submit-action{position: relative;overflow: hidden;display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 40px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: .3px;border-radius: 4px;}
.career-apply-modal .form-content{background:transparent;padding:10px}
.career-apply-modal .btn-submit-action:before {content: '';position: absolute;width: 200px;height: 200px;background-color: var(--shades01);top: 50%;left: 50%;transform: translate(-50%,-50%) scale(0);opacity: 0;border-radius: 50%;transition: all .3s ease;}
.career-apply-modal .btn-submit-action:hover:before {transform: translate(-50%,-50%) scale(1);opacity: .3;}
.modal-close{width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;position: absolute;top: -20px;right: -20px;z-index: 9;background-color: var(--shades01);border-radius: 50%;box-shadow: rgb(0 0 0 / 10%) 0 2px 10px;font-size: 24px;color: var(--color02);}
.modal-close:hover{transform: rotate(90deg);background-color: var(--color01);color: var(--shades01);}
.career-apply-modal .modal-body{background-color: var(--shades03);padding: 10px;border-radius: 8px;}
.career-apply-modal{border-radius: 10px; overflow: hidden;}
.career-apply-modal .modal-content{overflow: unset; /*box-shadow: none;*/}
.career-apply-modal .form-content{position: relative;}
.career-apply-modal .form-group{position: relative; margin: 0 0 16px;}
.career-apply-modal .input-style{position: relative;display: flex;background-color: var(--shades01);border-radius: 6px;box-shadow: rgb(0 0 0 / 5%) 0 2px 10px;}
.career-apply-modal .input-style .input-icon{width: 40px;min-width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;font-size: 18px;color: var(--color01);background-color: transparent;border-right: 1px solid var(--shades03);}
.career-apply-modal .input-style .input{width: 100%;border: 0;border-radius: 0;padding: 0 10px;background-color: transparent;color: var(--color02);font-weight: 500;letter-spacing: 0.3px;}
.upload-file .control-label.required{font-size: 14px;font-weight: 500;}
.career-apply-modal .apply-action{margin-top: 15px;border-top: 1px solid var(--shades04);padding-top: 15px;width: unset !important;}
.career-apply-modal .dropdown .dropdown-toggle::after{color: var(--color02);right: 10px;}
.career-apply-modal .dropdown .dropdown-scroll {max-height:100px;overflow: auto;}

.career-apply-modal .dropdown .dropdown-toggle{padding: 0;height: 40px;width: 100%;border: 0;display: flex;justify-content: flex-start;align-items: center;background-color: transparent;color: var(--color01);font-size: 16px;font-weight: 500;}
.career-apply-modal .dropdown .dropdown-toggle.vehicle-input{    background-color: var(--shades01);
border-radius: 5px;
box-shadow: 0 2px 10px rgb(0 0 0 / 5%);
color: var(--color02);
min-height: 35px;
overflow: hidden;padding: 0 10px;font-size: 14px;}
.career-apply-modal .dropdown .dropdown-menu{border: 0;width: 100%;padding:5px 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 20px;border-radius: 5px;}
.career-apply-modal .dropdown .dropdown-search{padding: 0 10px; margin: 0 0 5px;}
.career-apply-modal .dropdown .dropdown-search .form-control{background-color: transparent;border: 0;border-radius: 0;border-bottom: 1px solid var(--color02);padding: 0;width: 100%;height: 40px;color: var(--color02);font-size: 13px;font-weight: 500;transition: all 0.3s ease;}
.career-apply-modal .dropdown .dropdown-search .form-control::-webkit-input-placeholder{ color:var(--color02) !important;}
.career-apply-modal .dropdown .dropdown-search .form-control:focus{ border-color:var(--color02);}
.career-apply-modal .dropdown .dropdown-scroll::-webkit-scrollbar{width: 6px;height: 6px;background-color: transparent; border-radius: 10px}
.career-apply-modal .dropdown .dropdown-scroll::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 8px}
.career-apply-modal .btn-action{border:1px solid var(--color01);display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 50px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: 0.3px;border-radius: 4px;position: relative;overflow: hidden;}
.career-apply-modal .btn-action:before{content:''; position: absolute; width: 200px; height: 200px; background-color: var(--color01);top: 50%; left: 50%; transform: translate(-50%,-50%) scale(0); opacity: 0; border-radius: 50%; transition: all 0.3s ease; }
.career-apply-modal .btn-action:hover:before{transform: translate(-50%,-50%) scale(1);opacity: 0.3; }
.career-apply-modal textarea{resize: none;padding: 10px !important;}
.career-apply-modal .experience-dropdown .text{font-size: 14px;text-transform: capitalize;padding-left: 10px;color: var(--shades02);font-weight: 500;}
.career-apply-modal .qualifications-area .input-style .input-icon{border-right: none !important;}
.career-apply-modal .qualifications-area .input{border-left: 1px solid var(--shades03);}
.career-apply-modal .custom-checkbox-label{text-transform: capitalize;}
.career-apply-modal .upload-file{margin-top: 5px;}
.career-apply-modal .header-text {}  
.career-apply-modal .header-text .job-detail{}  
.career-apply-modal .job-detail .job-detail-list{display: flex;justify-content: space-between;width: 100%;margin-top: 10px;flex-wrap: wrap;margin: 0 -5px;}  
.career-apply-modal .job-detail-list .job-detail-item{width: 25%;max-width: calc(25% - 10px);margin: 2px 5px;display: flex;}  
.career-apply-modal .job-detail-list .job-detail-item .icon{font-size: 12px;margin-right: 2px;}  
.career-apply-modal .job-detail-list .job-detail-item .text{font-size: 12px;color: var(--color02);}  
.career-apply-modal .header-text .vacancy-text{font-size: 14px;font-weight: 400;text-transform: capitalize;}  
.career-apply-modal .header-text .vacancy-text span{font-size: 15px;font-weight: 500;margin-right: 8px;}  

.career-apply-modal .upload-input {width: 0.1px;height: 0.1px;opacity: 0;overflow: hidden;position: absolute;z-index: -1;}
.career-apply-modal .input-file{display: flex;align-items: center;justify-content: center;height: 40px;}
.career-apply-modal .file-input-label {cursor: pointer;justify-content: center;display: flex;align-items: center;border-radius: 4px;font-weight: 600;color: #fff;font-size: 14px;padding: 10px 12px;background-color: var(--color01);box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);margin-bottom: 0;width: 120px;}
.career-apply-modal .payment-heading{font-size: 14px;color: #000;font-weight: 500;letter-spacing: 0.3px;line-height: 1.2;margin: 15px 0 25px;}
.feedback-input{visibility: hidden;position: absolute;}
.mt-25{margin-top: 25px;}
.ml-0{margin-left: 0;}

.solution-section{}
.tickts-value{margin: 14px 0;}
.tickts-value .tickets-row{display: flex;}
.tickts-value .tickets-row .ticket{width: 100%;max-width: calc(100% - 140px);margin-right: 20px;}
.tickts-value .tickets-row .ticket .sub-text{font-size: 16px;font-weight: 500;text-transform: capitalize;padding-bottom: 5px;border-bottom: 1px solid var(--shades04);margin-bottom: 8px;}
.tickts-value .tickets-row .ticket .type{font-size: 15px;font-weight: 400;}
.tickts-value .tickets-row .ticket .type+ .type{margin-top: 8px;}

.tickts-value .tickets-row .amount{width: 120px;}
.tickts-value .tickets-row .amount .sub-text{font-size: 16px;font-weight: 500;text-transform: capitalize;padding-bottom: 5px;border-bottom: 1px solid var(--shades04);margin-bottom: 8px;}
.tickts-value .tickets-row .amount .data{font-size: 15px;font-weight: 400;}
.tickts-value .tickets-row .amount .data+ .data{margin-top: 8px;}
.solution-section .solution-list{}
.solution-list{}
.solution-list .solution-item{display: flex;align-items: center;padding: 100px 0;}
.solution-list .solution-item:nth-child(odd) .row{flex-direction: row-reverse;}
.solution-list .solution-item:nth-child(odd){background-color: var(--shades03);}
.solution-item .solution-content{width: 640px;}
.solution-item .solution-content .title{font-size: 48px;font-weight: 700;margin: 0;line-height: 1.2;color: var(--shades02);}
.solution-item .solution-content .description{font-size: 16px;font-weight: 400;letter-spacing: 0.3px;color: var(--color02);line-height: 1.4 ;margin: 20px 0 0;}
.solution-list .solution-item:nth-child(odd) .solution-img{margin-right: 40px;}
.solution-list .solution-item:nth-child(even) .solution-img{margin-left: 40px;}
.solution-item .solution-img{width: 100%;max-width: calc(100% - 680px);height: 300px;}
.solution-item .solution-img img{width: 100%;height: 100%;object-fit: contain;}


.privacy-section{padding: 60px 0;}
.privacy-section h6{font-size: 18px;}
.privacy-section ul{}
.privacy-section ul li{font-size: 16px;font-weight: 400;line-height: 1.5;}
.privacy-section ul li+ li{margin-top: 10px;}
.privacy-section ul li p{font-size: 16px;font-weight: 400;line-height: 1.5;}
.privacy-section p{font-size: 16px;line-height: 1.5;font-weight: 400;}
.privacy-section a{color: var(--color01);margin: 0 4px;text-decoration: underline;}
.privacy-section .title{font-size: 35px;color: var(--color02);text-align: center;margin-bottom: 25px;border-bottom: 1px solid var(--shades04);padding-bottom: 20px;font-weight: 500;}
.privacy-section .privacy-heading{line-height: 1.5;}
.privacy-section .privacy-text {font-size: 15px;line-height: 1.5;}
.privacy-section .privacy-sub-head{font-size: 17px;line-height: 1.5;}


.terms-section{padding: 60px 0;}
.terms-section span{font-size: 16px;font-weight: 400;margin-right: 8px;}
.terms-section p span{font-size: 16px;font-weight: 400;margin-right: 8px;}
.terms-section b{margin-right: 8px;}
.terms-section .title{font-size: 35px;color: var(--color02);text-align: center;margin-bottom: 25px;border-bottom: 1px solid var(--shades04);padding-bottom: 20px;font-weight: 500;}
.terms-section .terms-heading{line-height: 1.5;}
.terms-section .terms-text {font-size: 15px;line-height: 1.5;}
.terms-section .terms-sub-head{font-size: 24px;line-height: 1.5;text-align: center;margin-bottom: 15px;}
.terms-section h6{font-size: 18px;}
.terms-section ul{}
.terms-section ul li{font-size: 16px;font-weight: 400;line-height: 1.5;}
.terms-section ul li+ li{margin-top: 10px;}
.terms-section ul li p{font-size: 16px;font-weight: 400;line-height: 1.5;}
.terms-section p{font-size: 16px;line-height: 1.5;font-weight: 400;}
.terms-section a{color: var(--color01);margin: 0 4px;text-decoration: underline;}

.total-pay-action{display: flex;justify-content: space-between;align-items: center;}
.total-pay-action .action-btn{}
.total-pay-action .action-btn .btn-pay{background: none;color: var(--shades01);background-color: var(--color01);font-weight: 400;display: flex;align-items: center;font-size: 20px;height: 32px;border-radius: 4px;padding: 0 10px;}
.total-pay-action .action-btn .btn-pay span{font-size: 14px;}
.sec-plate-management .ticket-dropdown.status{}
.sec-plate-management .ticket-dropdown+ .ticket-dropdown{}
.sec-plate-management .dropdown.state-dropdown{width: 220px;box-shadow: rgb(0 0 0 / 5%) 0 2px 10px;border-radius: 5px;padding:  0 15px;background: var(--shades01);}
/* .sec-plate-management .dropdown.state-dropdown+ .dropdown.dropdown.state-dropdown{margin-left: 15px;} */
.sec-plate-management .dropdown .dropdown-toggle{padding: 0;height: 40px;width: 100%;border: 0;display: flex;justify-content: flex-start;align-items: center;background-color: transparent;border-bottom: 2px solid rgba(255,255,255,0.2);color: var(--shades02);font-size: 16px;font-weight: 500;}
/* .sec-plate-management .dropdown .dropdown-menu{border: 0;width: 100%;padding:5px 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 20px;transform: translate3d(0px, 40px, 0px) !important;} */
.sec-plate-management .dropdown .dropdown-search{padding: 0 10px; margin: 0 0 5px;}
.sec-plate-management .dropdown .dropdown-search .form-control{background-color: transparent;border: 0;border-radius: 0;border-bottom: 1px solid var(--color02);padding: 0;width: 100%;height: 40px;color: var(--color02);font-size: 13px;font-weight: 500;transition: all 0.3s ease;}
.sec-plate-management .dropdown .dropdown-search .form-control::-webkit-input-placeholder{ color:var(--color02) !important;}
.sec-plate-management .dropdown .dropdown-search .form-control:focus{ border-color:var(--color02);}
.sec-plate-management .dropdown .dropdown-scroll{max-height: 200px;overflow: auto;}
.sec-plate-management .dropdown .dropdown-scroll::-webkit-scrollbar{width: 6px;height: 6px;background-color: transparent; border-radius: 10px}
.sec-plate-management .dropdown .dropdown-scroll::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 8px}
.sec-plate-management .dropdown .dropdown-toggle::after{color: var(--shades02);}
.sec-plate-management .ticket-table .custom-checkbox .custom-checkbox-label:before{top: -12px;}
.sec-plate-management .ticket-tab{width: 100%;margin: 12px 0;}
.sec-plate-management .ticket-tab .tab-btn{margin: 12px 0;background: var(--shades01);font-size: 16px;border: 1px solid var(--shades04);font-weight: 500;color: var(--color02);padding: 8px 15px;box-shadow: none;}
.sec-plate-management .ticket-tab .tab-btn.active{background: var(--color01);color: var(--shades01);}
.sec-plate-management .ticket-tab .tab-btn+ .tab-btn{margin-left: 15px;}

.sec-dashboard .tab-btn{background: var(--shades01);font-size: 16px;border: 1px solid var(--shades04);font-weight: 500;color: var(--color02);padding: 8px 15px;box-shadow: none;}
.sec-dashboard .tab-btn.active{background: var(--color01);color: var(--shades01);}
.sec-dashboard .tab-btn+ .tab-btn{margin-left: 15px;}

.sec-dashboard .dashboard-tabs-list{display: flex;justify-content: space-between;align-items: flex-end;flex-wrap: wrap;margin-bottom: 15px;}

.dashboard-section{background: var(--shades03);}
.main-layout{background-color: var(--lightColor);width: 100%;min-height: 100vh;display: flex;}
.aside-layout{position: relative;z-index: 9;background-color: var(--color01);width: 300px;transition: all 0.3s ease;box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);}
.aside-layout:before{content: ''; position: absolute;width: 100%; height: 100%;top: 0; left: 0;z-index: -1;}

.brand-logo{display: flex;justify-content: center;align-items: center;padding: 20px 0; margin: 0 0 20px;border-bottom: 1px solid rgb(234 234 234 / 29%);position: sticky;z-index: 9;top: 0;background: var(--color01);}
.brand-logo .logo{display: flex;height: 40px;width: 100%;padding: 0;transition: all 0.3s ease;}
.brand-logo .logo img{width: 100%;height: 100%;object-fit: contain;}
.side-panel{position: relative;
/* max-height: calc(100vh - 121px); */
overflow: auto;}
.side-panel .ticket-menus{}
.side-panel .ticket-menus .menu-link.active{border-left: 5px solid var(--color02);background: var(--shades04);position: relative;}
.side-panel .ticket-menus .menu-link:before{content: "\f078";font-family: "Font Awesome 5 Pro";font-size: 14px;font-weight: 500;display: block;position: absolute;margin: 0;vertical-align: unset;top: 50%;right: 0;transform: translateY(-50%);border: 0;color: var(--color05);opacity: 1;margin-top: 2px;transition: all 0.3s ease;right: 10px;top: 20px;}
.side-panel .ticket-menus .other-menus {margin-left: 60px;display: none;}
.side-panel .ticket-menus .other-menus.active{margin-left: 20px;display: block;}
.side-panel .ticket-menus .other-menus .sub-menu{display: flex;margin: 15px 0;
color: var(--shades06);}
.side-panel .ticket-menus .other-menus .sub-menu.selected{color: var(--color01);}
.side-panel .ticket-menus .other-menus .sub-menu+ .sub-menu{margin-top: 15px;}
.side-panel .ticket-menus .other-menus .sub-menu .link-icon{    font-size: 16px;margin-right: 10px;width: 20px;height: 20px;display: flex;align-items: center;justify-content: center;}
.side-panel .ticket-menus .other-menus .sub-menu .link-text{    line-height: 1.5;font-size: 14px;font-weight: 400;}

.side-panel .side-menu{position: relative;}
.side-panel .side-menu .menu-link{display: flex;align-items: center;padding: 0 20px;line-height: 1;height: 48px;position: relative;}
.side-panel .side-menu .menu-link:after{content: ''; position: absolute;width: 100%; height: 100%; top: 0; left: 0;z-index: -1; opacity: 0; transform: scale(0,1); transform-origin: left; transition: all 0.3s ease;}
.side-panel .side-menu .menu-link .link-icon{font-size: 18px;font-weight: 400;color: var(--shades01); width: 36px; height: 36px; background-color: var(--thirdColor); display: flex; justify-content: center; align-items: center; border-radius: 6px;transition: all 0.3s ease;}
.side-panel .side-menu .menu-link .link-text{display: block; font-size: 15px; margin-left: 10px;font-weight: 400;color: var(--shades01); transition: all 0.3s ease;}
.side-panel .side-menu .menu-link.active{border-left: 5px solid var(--color02);background: var(--shades03);}
.side-panel .side-menu .menu-link:hover{border-left: 5px solid var(--color02);background: var(--shades03);}
.side-panel .side-menu .menu-link:hover .link-icon{color: var(--color01);}
.side-panel .side-menu .menu-link:hover .link-text{color: var(--color01);}
.side-panel .side-menu .menu-link.active .link-icon{color: var(--color01);}
.side-panel .side-menu .menu-link.active .link-text{color: var(--color01);}
/* .side-panel .side-menu .menu-link.active:after, .side-panel .side-menu .menu-link:hover:after{opacity: 1; transform: scale(1,1);}
.side-panel .side-menu .menu-link.active .link-icon, .side-panel .side-menu .menu-link:hover .link-icon{background-color: transparent;}
.side-panel .side-menu .menu-link.active .link-text, .side-panel .side-menu .menu-link:hover .link-text{color: var(--color01);} */
.side-panel .side-menu .menu-link+.menu-link{margin-top:10px;}

.side-panel .user-menu{padding:20px 0}
.side-panel .user-menu .user-menu-heading{display:flex;padding:14px 20px;color:var(--color01);border-top:rgba(255,255,255,0.05) 1px solid;font-weight:500;margin-bottom:15px;align-items:center}
.side-panel .user-menu .user-menu-heading i{font-size:18px;margin-right:15px;width:36px}
.side-panel .user-menu .user-menu-heading .text{color:var(--color01);}
.side-panel .user-menu .menu-link{padding:0 20px;display:flex;align-items:center;height:40px}
.side-panel .user-menu .menu-link.active{background:linear-gradient(to right,var(--primaryColor),var(--fourthColor))}
.side-panel .user-menu .menu-link+.menu-link{margin-top:10px}
.side-panel .user-menu .menu-link .icon{font-size:18px;font-weight:400;color:var(--color01);width:36px;height:36px;display:flex;justify-content:center;align-items:center;border-radius:6px;transition:all .3s ease}
.side-panel .user-menu .menu-link .text{display:block;font-size:15px;margin-left:10px;font-weight:400;color:var(--color01);transition:all .3s ease}


.content-layout{width: 100%; max-width: calc(100% - 300px);transition: all 0.3s ease;}
.header-layout{position: sticky;display: flex;align-items: center;justify-content: space-between;padding: 0 30px 0 20px;width: 100%;background-color: var(--color02);z-index: 9; height: 80px;top: 0;}
.header-layout .right-menu{display: flex;align-items: center;}
.header-layout .right-menu .wallet-balance{display: flex;align-items: center;margin-right: 20px;}
.header-layout .right-menu .wallet-balance .money{display: block;font-size: 14px;text-transform: capitalize;font-weight: 600;line-height: 1.4;color: #fff;}
.header-layout .right-menu .wallet-balance .money-text{color: white;}
.header-layout .right-menu .wallet-balance .money-text .text{    font-size: 12px;color: var(--textAltColor);text-transform: uppercase;line-height: 1;font-weight: 400;}
.header-layout .right-menu .wallet-balance .icon{    display: flex;align-items: center;font-size: 30px;color: var(--fourthColor);margin-right: 10px;}
.header-layout:after{content: ''; position: absolute;width: 100%; height: 100%; background: linear-gradient(to right, transparent, rgba(0,0,0,0.25)); top: 0; left: 0;z-index: -1;}
.header-layout .sidepanel-toggle{width: 48px;height: 48px;display: flex;justify-content: center;align-items: center;border: 0;background-color: transparent;padding: 0;line-height: 1;font-size: 24px;color: var(--shades01);}
.header-layout .user-menu{}
.header-layout .user-menu .dropdown-toggle{display: flex;align-items: center;background-color: transparent;border: 0; text-align: left;padding-right: 24px !important;}
.header-layout .user-menu .dropdown-toggle .image{width: 40px;height: 40px;border-radius: 50%;display: flex;justify-content: center;align-items: center;overflow: hidden;margin-right: 16px;}
.header-layout .user-menu .dropdown-toggle .image img{width: 100%;height: 100%;object-fit: cover;}
.header-layout .user-menu .dropdown-toggle .title{font-size: 12px;color: var(--shades05);text-transform: uppercase;line-height: 1;font-weight: 400;}
.header-layout .user-menu .dropdown-toggle .title span{display: block;font-size: 15px;text-transform: capitalize;font-weight: 500;line-height: 1.4;color: #fff;}
.header-layout .user-menu .dropdown-toggle:after{color:var(--shades01); font-weight: 300;}
.header-layout .user-menu .dropdown-menu{width: 180px;margin: 10px 0 0;border: 0;box-shadow: rgba(0,0,0,0.05) 0 2px 20px;padding: 6px 0;border-radius: 6px;}
.header-layout .user-menu .dropdown-menu:before{content: '';position: absolute; top:-20px; left: 50%; transform: translateX(-50%); border: 10px solid transparent; border-bottom-color: #fff;}
.header-layout .user-menu .dropdown-menu .user-menu-item{display: flex;align-items: center;padding: 10px;line-height: 1;font-size: 14px;font-weight: 400;color: var(--shades02);transition: all 0.3s ease; position: relative;}
.header-layout .user-menu .dropdown-menu .user-menu-item.logout{color: var(--red);}
.header-layout .user-menu .dropdown-menu .user-menu-item:after{content: ''; position: absolute;width: 100%; height: 100%; background: linear-gradient(to right, var(--primaryColor), var(--shades01)); top: 0; left: 0;z-index: -1; opacity: 0;transition: all 0.3s ease;}
.header-layout .right-menu .other-menus{display: flex;align-items: center;margin-right: 14px;}
.header-layout .right-menu .other-menus .menu-item{}
.header-layout .right-menu .other-menus .menu-item+ .menu-item{margin-left: 20px;}
.header-layout .right-menu .other-menus .menu-item .icon{color: var(--shades01);font-size: 22px;}

.header-layout .user-menu .dropdown-menu .user-menu-item:hover{color: var(--shades01);background-color: var(--shades02);}

/* .header-layout .user-menu .dropdown-menu .user-menu-item:hover:after{opacity: 1;} */

.main-layout.collapsed .aside-layout{width: 76px;}
.main-layout.collapsed .aside-layout .brand-logo .logo{padding: 5px;}
.main-layout.collapsed .aside-layout .side-panel .side-menu .menu-link .link-text{display: none;}
.main-layout.collapsed .content-layout{max-width: calc(100% - 76px);}
.main-layout.collapsed .aside-layout .side-panel .user-menu .menu-link .text{display: none;}
.main-layout.collapsed .aside-layout .side-panel .user-menu .user-menu-heading .text{display: none;}
.main-layout.collapsed .aside-layout .side-panel .user-menu .user-menu-heading i{margin-right: 0;}

.total-pay-action{margin-bottom: 20px;}
.total-pay-action .custom-checkbox .custom-checkbox-label{padding-left: 10px;}
.total-pay-action .custom-checkbox .custom-checkbox-label:before{width: 24px;height: 24px;margin-right: 10px;border-radius: 4px;}
.total-pay-action .custom-checkbox .custom-checkbox-label:after{width: 24px;margin-right: 10px;}

.sec-ticket .ticket-table .custom-checkbox .custom-checkbox-label:before{top: 50%;}
.sec-ticket .ticket-table .custom-checkbox .custom-checkbox-input{top: 50%;}
.sec-ticket .ticket-list .ticket-table{overflow: unset;}
.sec-ticket .ticket-list .btn-pay{background: none;color: var(--shades01);background-color: var(--color01);font-weight: 400;display: flex;align-items: center;font-size: 20px;height: 32px;border-radius: 4px;padding: 0 10px;}
.sec-ticket .ticket-list .btn-pay span{font-size: 14px;}
.sec-dashboard .dashboard-action .driving-btn{}
.sec-dashboard .dashboard-action .driving-btn.active{}

.body-layout{position: relative;}
.body-content{}
.body-content .user-search-part{display:block;align-items:center;justify-content:space-between;margin-bottom:15px;flex-wrap: wrap;}
.body-content .user-search-part.header-part-title{margin-top: 35px;}
.body-content .user-search-part .search-input{width:100%;max-width:calc(100% - 35px);border:none;padding: 0 10px;}
.user-search-part .title{font-size:25px;font-weight:500;color:var(--color02);line-height: 1.2;}
.user-search-part .title span{padding-left: 10px;}
.user-search-part .search{width:300px;display:flex;border:1px solid var(--shades04);border-radius:5px;overflow:hidden;}
.user-search-part .search-btn{width:40px;height:40px;min-width: 40px;background-color:#fff;color:var(--color01);border-right: 1px solid var(--shades04);}
.ticket-list .ticket-table{background:var(--shades01);border:1px solid var(--shades04);box-shadow:2px 2px 8px rgb(0 0 0 / 11%);border-radius:6px; overflow: auto;}
.ticket-table .table-head {}
.sec-ticket{padding: 60px 0;background-color: var(--shades03);}
/* .payment-card{position: relative;background: var(--shades01);box-shadow: rgb(0 0 0 / 10%) 0 2px 16px;padding: 10px;border-radius: 5px;} */
.StripeElement{display: flex;flex-direction: column;justify-content: center;height:50px;padding:10px 12px;width:100%;color:#32325d;background-color:var(--shades01);border:1px solid transparent;border-radius:5px;box-shadow: rgb(0 0 0 / 10%) 0 2px 16px;-webkit-transition:box-shadow 150ms ease;transition:box-shadow 150ms ease}
.StripeElement--invalid{border-color:#fa755a}
.StripeElement--webkit-autofill{background-color:#fefde5!important}
.payment-card-btn{position:relative;overflow:hidden;display:flex;align-items:center;justify-content:center;padding:0 16px;height:38px;background-color:var(--color01);color:var(--shades01);font-size:14px;font-weight:600;text-transform:uppercase;letter-spacing:.3px;border-radius:4px;width:150px;margin-top:20px}


.ticket-table .table-head .table-row{display:flex;flex-wrap:nowrap;border-bottom:1px solid var(--shades04);background:var(--shades03)}
.ticket-table .table-head .table-row .table-cell{display:flex;align-items:center;min-height:50px;padding:10px;width:100%}
.ticket-table .mobile-title{display:none}
.ticket-table .table-head .table-row .table-cell.cell-check{width:7%;max-width:7%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.cell-plate{width:10%;max-width:10%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.cell-ticketID{width:13%;max-width:13%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.cell-date{width:15%;max-width:15%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.cell-fine{width:10%;max-width:10%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.cell-vc{width:20%;max-width:20%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.cell-violation{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.cell-status{width:10%;max-width:10%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.cell-view-btn{width:10%;max-width:10%;flex-direction:column;align-items:flex-end;justify-content:center}
.ticket-table .table-head .table-row .table-title{font-size:14px;font-weight:500;color:var(--color02)}
.ticket-table .table-body .table-row{display:flex;flex-wrap:nowrap}
.ticket-table .table-body .custom-checkbox+ .custom-checkbox{border-top:1px solid var(--shades04)}

.ticket-table .table-body .table-row .table-cell{display:flex;align-items:center;min-height:50px;padding:10px;width:100%}
.ticket-table .table-body .table-row .table-cell .cell-data{font-size: 14px;font-weight: 400;}
.ticket-table .table-body .table-row .table-cell .cell-data .status-icon{font-size: 18px;color: var(--color01);}    
.ticket-table .table-body .table-row .table-cell.cell-check{width:7%;max-width:7%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.cell-plate{width:10%;max-width:10%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.cell-ticketID{width:13%;max-width:13%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.cell-date{width:15%;max-width:15%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.cell-fine{width:10%;max-width:10%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.cell-vc{width:20%;max-width:20%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.cell-violation{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.cell-status{width:10%;max-width:10%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.cell-view-btn{width:10%;max-width:10%;flex-direction:column;align-items:flex-end;justify-content:center;}
/* .ticket-table .table-body .table-row .view-btn-action{position: absolute;} */
/* .ticket-table .table-body .table-row .cell-view-btn .dropdown{z-index: 7;} */

.ticket-table .table-body .view-btn-action .eye-btn{font-size: 14px;color: var(--color02);}

.ticket-table .table-body .table-row .cell-view-btn .btn-action{width:24px;height:24px;display:flex;justify-content:center;align-items:center;box-shadow: 0px 2px 5px rgb(0 0 0 / 10%) !important;padding:0;border-radius:0;background-color:#fff;font-size:14px;color:var(--shades07);border: 1px solid rgba(0,0,0,0.05);position: relative;z-index: 2;}
.ticket-table .table-body .table-row .cell-view-btn .dropdown .dropdown-menu{box-shadow: rgb(0 0 0 / 10%) 0 2px 10px;border:0;border-radius:2px;padding:6px 0;}
.ticket-table .table-body .table-row .cell-view-btn .dropdown .dropdown-menu{}
.ticket-table .table-body .table-row .cell-view-btn .dropdown .dropdown-menu.show{}
.ticket-table .table-body .table-row .cell-view-btn .dropdown-menu .dropdown-item{padding:6px 10px;line-height:1;height:32px;display:flex;align-items:center;font-size:14px;font-weight:400;text-transform:capitalize}
.ticket-table .table-body .table-row .cell-view-btn .dropdown-menu .dropdown-item i{margin-right:10px;width:28%;max-width:calc(28% - 20px)}

.ticket-table .custom-checkbox{padding: 0;display: block;}

.ticket-table .custom-checkbox .custom-checkbox-label{width:100%}
.ticket-table .custom-checkbox .custom-checkbox-label:after{all:unset}
.ticket-table .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:before{color:var(--color01);opacity:1;font-weight: 400;}
.ticket-table .custom-checkbox .custom-checkbox-label{position:relative}
.ticket-table .custom-checkbox .custom-checkbox-label:before{content:"\f058";position:absolute;font-family:"Font Awesome 5 Pro";width:unset;height:unset;top: 50%; transform: translateY(-50%);left:20px;font-weight:300;font-size:25px;opacity:.5;background:none!important;border:none!important;align-items:center;justify-content:center;color:var(--color02);text-align:center;z-index: 0;}
.ticket-table .custom-checkbox .custom-checkbox-input{top: 50%;transform: translateY(-50%);left: 20px;width: 25px;height: 25px;}
.cart-summary{padding: 20px;margin: 20px 0 0;background-color: var(--shades01);border-radius: 5px;box-shadow: rgba(0, 0, 0, 0.1) 0 2px 16px;overflow: hidden;}
.cart-summary .title{font-size: 16px;font-weight: 600;color: var(--shades02);line-height: 1;}     
.cart-summary .ticket-summary{position: relative;margin: 10px 0;}
.cart-summary .ticket-summary .ticket-info{display: flex;justify-content: space-between;align-items: center;line-height: 1;padding: 4px 0;}      
.cart-summary .ticket-info .text{font-size: 13px;font-weight: 400;color: var(--shades06);}
.cart-summary .ticket-info .data{font-size: 14px;font-weight: 600;color: var(--shades02);}      
.cart-summary .total-cart{display: flex;justify-content: space-between;align-items: center;line-height: 1;padding: 20px;margin: 0 -20px -20px;background-color: var(--shades06);}   
.cart-summary .total-cart .text{font-size: 15px;font-weight: 500;color: var(--shades01);text-transform: uppercase;}
.cart-summary .total-cart .data{font-size: 17px;font-weight: 600;color: var(--shades01);}

.checkout-action{display: flex;align-items: center;justify-content: flex-end; margin: 20px 0 0;}
.checkout-action .btn-action{font-size: 14px;height: 40px;padding: 0 10px;display: flex;justify-content: center;align-items: center;text-transform: uppercase;font-weight: 600;letter-spacing: 1px;width: 250px;border-radius: 5px;background-color: var(--color01);color: var(--shades01);}

/* .sec-ticket.checkout-page  .ticket-table .table-body .table-row .drive-action .dropdown .dropdown-menu{transform: translate(-116px, 26px) !important;} */

.ticket-management-list.sec-plate-management .ticket-table .custom-checkbox .custom-checkbox-input{top: -12px;}
.checkout-page .ticket-list .ticket-table{overflow: unset;}
.status-text{font-size: 12px;font-weight: 500;color: var(--color02);position: relative;padding-left: 15px;}
.status-text:before{content: '';position: absolute;width: 10px;height: 10px;border-radius: 50%;left: 0;bottom: 0;top: 50%;transform: translateY(-50%);}
.status-text.color:before{top: 4px;}
.status-text.inprogress:before{background: var(--statusInprogress);}
.status-text.assigned:before{background: var(--statusAssigned);}
.status-text.waiting:before{background: var(--statusWaiting);}
.status-text.open:before{background: var(--statusOpen);}
.status-text.reduce:before{background: var(--statusReduced);}
.status-text.dismiss:before{background: var(--statusDismiss);}
.status-text.guilty:before{background: var(--statusGuilty);}

.submit-new-ticket{display: flex;}
.submit-new-ticket .ticket-submit-box{display: flex;justify-content: space-between;margin-right: 15px;}
.submit-new-ticket .ticket-submit-box .submit-box-item{text-align: center;}
/* .ticket-submit-box .submit-box-item .image-part{width: 100%;height: 250px;}
.ticket-submit-box .submit-box-item .image-part img{width: 100%;height: 100%;object-fit: contain;} */
.ticket-submit-box .submit-box-item .submit-btn{background: var(--statusAssigned);border-radius: 5px;width: 190px;height: 38px; color: var(--shades01);display: flex;justify-content: center;align-items: center; font-weight: 500;text-transform: capitalize;margin-left: 15px;}
.ticket-submit-box .submit-box-item .submit-btn.traffic{background: var(--statusOpen);}


.add-new-ticket{background: var(--shades01);border-radius: 8px;padding: 15px 20px;box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);}
.add-new-ticket .control-label{font-size: 15px;font-weight: 500;margin-bottom: 6px;}
.select-section{display: flex;align-items: center;}
.select-ticket{display: flex;align-items: center;position: relative;}
.select-ticket+ .select-ticket{margin-left: 15px;}
.select-ticket .check-input{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;z-index:1;cursor:pointer; border: 1px solid transparent;}
.select-ticket .select{display: flex;align-items: center;position: relative;}
.select-ticket .select+ .select{margin-left: 10px;}
.select-ticket .check-label{position: relative;border: 1px solid var(--shades04);background-color: var(--shades01);border-radius: 4px;display: flex;align-items: center;justify-content: center;height:35px;margin-bottom: 0;margin: 2px;padding: 2px;color: var(--secondaryColor);font-size: 14px;font-weight: 400;text-transform: uppercase;width: 215px;}
.select-ticket .check-label:before{content:"";position:absolute;width:100%;height:100%;border-radius:4px;bottom:0;left:0;right:0;margin:0 auto}
.select-ticket .check-label:after{content:"\f058";font-family: 'Font Awesome 5 Pro';position:absolute;width:100%;height:100%;border-radius:4px;bottom:-7px;left:7px;right:0;display:none;margin:0 auto}
.select-ticket .check-input:checked ~ .check-label:after{display:flex}
.select-ticket .check-input:checked ~ .check-label{border: 1px solid var(--color01);padding: 2px;background: var(--color01);color: var(--shades01);}

.add-new-ticket .upload-input {width: 0.1px;height: 0.1px;opacity: 0;overflow: hidden;position: absolute;z-index: -1;}
.add-new-ticket .input-file{margin-left: 15px;}
.add-new-ticket .input-file.upload{margin-left: 0;}
.add-new-ticket .file-input-label {cursor: pointer;justify-content: center;display: flex;align-items: center;border-radius: 4px;font-weight: 600;color: #fff;font-size: 14px;padding: 10px 12px;background-color: var(--color01);box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);margin-bottom: 0;height: 35px;width: 100%;}
.add-new-ticket .ticket-info{display: flex;align-items: center;}
.add-new-ticket .ticket-info+ .ticket-info{margin-top: 15px;}
.add-new-ticket .ticket-info .ticket-input{width: 350px;border-radius: 5px;overflow: hidden;box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px}
.add-new-ticket .ticket-info .ticket-input+ .ticket-input{margin-left: 10px;}
.ticket-info .ticket-input .input{width: 350px;border: none;height: 35px;padding-left: 10px;}
.add-new-ticket .notes-area{width: 100%;max-width: 710px;resize: none;height: 120px;border-radius: 6px;box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;border: none;padding: 10px;}

.add-new-ticket .btn-submit-action{position: relative;overflow: hidden;display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 40px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: .3px;border-radius: 4px;}
.add-new-ticket .form-content{background:transparent;padding:10px}
.add-new-ticket .btn-submit-action:before{content: '';position: absolute;width: 200px;height: 200px;background-color: var(--shades01);top: 50%;left: 50%;transform: translate(-50%,-50%) scale(0);opacity: 0;border-radius: 50%;transition: all .3s ease;}
.add-new-ticket .btn-submit-action:hover:before{transform: translate(-50%,-50%) scale(1);opacity: .3;}

.mobile-view.sidenavigation{ display: none;}
.desktop-view{display: block;}
.user-search-part .ticket-submit-box{display: flex;}
.user-search-part .search-upper-part{display: flex;align-items: center;margin-bottom: 15px;flex-wrap: wrap;justify-content: space-between;}
.user-search-part .search-upper-part{    display: flex;align-items: center;margin-bottom: 15px;flex-wrap: wrap;justify-content: space-between;}
.sec-dashboard.tlc-management .body-content .user-search-part .search-upper-part{    display: flex;align-items: center;margin-bottom: 15px;flex-wrap: wrap;justify-content: space-between;}
.sec-dashboard.tlc-management .body-content .user-search-part .ticket-submit-box{display: flex;}
.sec-dashboard.tlc-management .body-content .user-search-part{display: block;}
.sec-dashboard.tlc-management .tlc-tickets-management {display: flex;}
.sec-dashboard.tlc-management .tlc-tickets-management {}
.sec-dashboard.tlc-management .tlc-tickets-management .ticket-submit-box{display: flex;}

.ticket-result-section{position: relative;padding: 100px 0;background-color: var(--shades03);z-index: 0;}
.ticket-id{font-size: 30px;text-transform: capitalize;font-weight: 600;margin-bottom: 15px;}
.ticket-result-section .ticket-details-inner{}
.ticket-result-section .ticket-details-inner .ticket-list{}
.ticket-details-inner .ticket-list{}
.ticket-details-inner .ticket-list .ticket-item{border: 1px solid var(--shades01);background: var(--shades01);padding: 20px;border-radius: 8px;}
.ticket-details-inner .ticket-list .ticket-item+ .ticket-item{margin-top: 15px;}
.ticket-details-inner .ticket-list .ticket-item .custom-checkbox{}
.ticket-list .ticket-item .custom-checkbox .custom-checkbox-input{}
.ticket-list .ticket-item .custom-checkbox .custom-checkbox-label{display: flex;width: 100%;}
.ticket-item .custom-checkbox .custom-checkbox-label .ticket-details{width: 60%;margin: 0 12px;}
.ticket-item .custom-checkbox .custom-checkbox-label .ticket-details .price{font-size: 24px;
margin-bottom: 5px;color: var(--color01);}
.ticket-item .custom-checkbox .custom-checkbox-label .ticket-details .violation-message{    font-size: 18px;
text-transform: capitalize;
margin-bottom: 22px;}
.ticket-item .custom-checkbox .custom-checkbox-label .ticket-details .violation-time{    font-size: 14px;
text-transform: capitalize;
color: var(--color03);}

.ticket-item .custom-checkbox .custom-checkbox-label .plate-details{width: 20%;margin: 0 12px;}
.ticket-item .custom-checkbox .custom-checkbox-label .plate-details .text{  font-size: 12px;margin-bottom: 10px;
text-transform: uppercase;
color: var(--color03);}
.ticket-item .custom-checkbox .custom-checkbox-label .plate-details .data{font-size: 16px;font-weight: 500;}

.ticket-item .custom-checkbox .custom-checkbox-label .due-date{width: 20%;}
.ticket-item .custom-checkbox .custom-checkbox-label .due-date .text{  font-size: 12px;margin-bottom: 10px;
text-transform: uppercase;
color: var(--color03);}
.ticket-item   .custom-checkbox .custom-checkbox-label:after{all: unset;}
.ticket-item .custom-checkbox .custom-checkbox-label .due-date .data{font-size: 16px;font-weight: 500;}
.ticket-item .custom-checkbox .custom-checkbox-label{position: relative;}
.ticket-item .custom-checkbox .custom-checkbox-label:before{     content: "\f058";position: absolute;font-family: "Font Awesome 5 Pro";width: unset;height: unset;top: unset;left: -30px;transform: unset;font-weight: 300;font-size: 25px;opacity: 0.5;background: none !important;border: none !important;align-items: center;justify-content: center;color: var(--color02);text-align: center;}
.ticket-item .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:before{color: var(--color01);opacity: 1;}
.cart-section .title {    font-size: 30px;text-transform: capitalize;font-weight: 600;margin-bottom: 15px;}

header .responsive-action {display: none;}
.nav-menu-item{padding: 10px;list-style: none;display: flex;margin-bottom: 0; }
header .responsive-action .btn-menu {width: 60px;height: 40px;display: flex;align-items: center;font-size: 24px;line-height: 1;color: var(--primaryColor);padding-top: 2px;justify-content: flex-end;}
header .menu-overlay.show {opacity: 1;visibility: visible;}
header .menu-overlay {position: fixed;z-index: 10;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0,0,0,0.5);opacity: 0;visibility: hidden;cursor: pointer;transition: all 0.3s ease;}

.driver-management{}
/* .sec-plate-management.ticket-management-list .ticket-result-content .table-cell.cell-view-btn .dropdown .dropdown-menu{transform: translate3d(-110px, 24px, 0px) !important;} */
/* .sec-plate-management .driver-list-inner .table-cell.drive-action .dropdown .dropdown-menu{transform: translate3d(-110px, -110px, 0px) !important;} */

.search-lower-part{display: flex;justify-content: space-between;}
.user-search-part .sec-plate-action .dropdown.state-dropdown .dropdown-menu{border: 1px solid var(--shades04) !important;}
.sec-plate-management.sec-plate-list .driver-list-inner .table-cell.drive-action .dropdown .dropdown-menu{}
/* .sec-plate-management .ticket-result-content .dropdown .dropdown-menu{transform: translate3d(-110px, 24px, 0px) !important;} */
.sec-plate-management .body-content .user-search-part .title{margin-bottom: 15px;}

.list-filter-action{display: flex;flex-wrap: wrap;gap: 10px;width: 100%;}
.list-filter-action .search{margin-right: auto;}
.list-filter-action .search-filters{display: flex;}
.list-filter-action .search-filters .dropdown{width: 100%;}
.list-filter-action .search-filters .dropdown .dropdown-toggle{border: 1px solid var(--shades04);background-color: var(--shades01);border-radius: 5px;font-size: 14px;padding: 0 10px;padding-right: 30px !important; height: 42px;}
.list-filter-action .search-filters .dropdown .dropdown-toggle::after{margin: 0;font-size: 12px;right: 10px;}
.list-filter-action .search-filters .dropdown .dropdown-menu{border-radius: 5px; border: 0; box-shadow: rgba(0,0,0,0.1) 0 2px 10px;}

.icon-referral .btn{position: absolute;top: 18px;right: 15px;color: var(--color01);font-size: 16px;}

.profile-edit-form .signin-content.active{visibility: visible;opacity: 1;display: block;}
.profile-edit-form .signin-content{visibility: hidden;opacity: 0;display: none;}
.profile-edit-form .form-action{margin: 0 auto;justify-content: center;}

.ticket-management-list.sec-plate-management {}
/* .icon-edit-email{position: relative;} */
.icon-edit-email{position:absolute;top:-3px;right:-0}
.icon-edit-email .edit-btn{color:var(--color01);background-color:transparent;padding:0;font-size:16px}
.ticket-management-list.sec-plate-management{}
.ticket-management-list.sec-plate-management .body-content .user-search-part{display: block;}
/* ********|| MODAL ENDS ||******** */
.sec-billings.sec-plate-management .ticket-dropdown.status{margin-left: 0;}

/* .sec-plate-management .dropdown .dropdown-menu{border: 0;width: 100%;padding:5px 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 20px;transform: translate3d(0px, 40px, 0px) !important;} */
@media screen and (min-width: 1600px) and (max-width:1920px){
.checkout-page .dropdown .dropdown-menu{border: 0;width: 100%;padding:5px 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 20px;transform: translate3d(-116px, 40px, 0px) !important;}
.sec-ticket .dropdown .dropdown-menu{border: 0;width: 100%;padding:5px 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 20px;transform: translate3d(-116px, 40px, 0px) !important;}
}


.ticket-table.plate-table .table-body .custom-checkbox.disabled .custom-checkbox-label:before{ display: none;}
.ticket-table.plate-table .table-row .table-cell.cell-check{width:50px; max-width: none;}
.ticket-table.plate-table .table-row .table-cell.cell-plate{width:80px; max-width: none;}
.ticket-table.plate-table .table-row .table-cell.cell-ticketID{width:100px; max-width: none;}
.ticket-table.plate-table .table-row .table-cell.cell-date{width:120px; max-width: none;}
.ticket-table.plate-table .table-row .table-cell.cell-fine{width:60px; max-width: none;}
.ticket-table.plate-table .table-row .table-cell.cell-violation{width:100%; max-width:calc(100% - 560px);}
.ticket-table.plate-table .table-row .table-cell.cell-status{width:100px; max-width: none;}
.ticket-table.plate-table .table-row .table-cell.cell-action{width:50px; max-width: none;}
.ticket-table.plate-table .table-row .table-cell .cell-data{font-size: 13px;}
.ticket-table.plate-table .table-row .table-cell.cell-violation .cell-data{font-size: 11px;}



/* ********|| RESPONSIVE STARTS ||******** */
@media screen and (max-width: 1600px) {
.ticket-dropdown.status .vehicle-input{width: 180px !important;}
.submit-new-ticket .ticket-submit-box{margin-top: 0;margin-right: 0;}
.ticket-management-list.sec-plate-management .submit-new-ticket .ticket-submit-box{margin-top: 15px;}

.ticket-table .table-body .table-row .table-cell.cell-violation{width: 15%;max-width: 15%;}
.ticket-table .table-head .table-row .table-cell.cell-violation {width: 15%;max-width: 15%;}
}
@media screen and (max-width: 1540px) {
.sec-ticket .ticket-list .ticket-table{overflow: auto;}
.sec-ticket.login-part-pages .ticket-table .table-head .table-row {
width: 1200px;
}
.ticket-management-list .ticket-table .custom-checkbox, .ticket-management-list .ticket-table .table-body .table-row, .ticket-management-list .ticket-table .table-head .table-row {
width: 1200px;
}
.sec-ticket.login-part-pages .ticket-table .table-body .table-row {
width: 1200px;
}
}
@media screen and (max-width: 1440px) {
.submit-new-ticket.ticket-search .ticket-dropdown.status{margin-left: 0;}
.sec-billings .ticket-dropdown.status{margin-bottom: 10px;margin-left: 0;}
/* .sec-plate-management .dropdown.state-dropdown+ .dropdown.dropdown.state-dropdown{margin-bottom: 10px;} */
.ticket-management-list.sec-plate-management .user-search-part .search{margin-top: 15px;}

/* .submit-new-ticket .ticket-submit-box{margin: 20px 0 0;} */
.ticket-submit-box .submit-box-item .submit-btn{margin-left: 0;}
.ticket-submit-box .submit-box-item .submit-btn.traffic{margin-left: 15px;}
.sec-billings-details .details-inner .details-ticket-sub-title{font-size: 14px !important;}
.sec-billings-details .details-inner .details-footer .plate-inner .plate-sub-title{font-size: 14px !important;}


.sec-ticket.checkout-page .ticket-table .table-head .table-row{width: unset !important;}
.sec-ticket.checkout-page .ticket-table .table-body .table-row{width: unset !important;}
}
@media screen and (max-width: 1400px) {
.sec-ticket.login-part-pages .driver-management.ticket-table .table-body .table-row{width: unset;}
.sec-ticket.login-part-pages .driver-management.ticket-table .table-head .table-row {width: unset;}
.sec-ticket.login-part-pages .ticket-table .table-head .table-row {width: 1200px;}
.sec-ticket.login-part-pages .ticket-table .table-body .table-row{ width: 1200px;}

.sec-ticket.checkout-page .ticket-table .table-head .table-row{width: unset;}
.sec-ticket.checkout-page .ticket-table .table-body .table-row{width: unset;}

.ticket-management-list .ticket-table .table-head .table-row{width: 1200px;}
.ticket-management-list .ticket-table .table-body .table-row{width: 1200px;}
.ticket-management-list .ticket-table .custom-checkbox{width: 46px;}
/* .ticket-management-list .ticket-table .table-head{overflow: auto;}
.ticket-management-list .ticket-table .table-body{overflow: auto;} */
.user-search-part .search.management{width: 360px;margin-right: 0;}
}
@media screen and (max-width: 1366px) {
.sec-ticket .ticket-list .ticket-table{overflow: auto;}
.job-detail-list .job-detail-item .text{font-size: 14px;}
.career-item .job-detail-list .job-detail-item{align-items: flex-start;}
.career-item .job-detail-list{align-items: flex-start;margin: 20px 0 0;}
.user-search-part .title{margin: 0 0 20px;}
.sec-referral .user-search-part .title{margin: 0;}
/* .submit-new-ticket{flex-wrap: wrap;} */
.ticket-dropdown.status .vehicle-input{width: 197px !important;}

}
@media screen and (max-width: 1280px) {
.sec-ticket .ticket-list .ticket-table{overflow: auto;}
.add-new-driver .driver-info .driver-input .input{width: 300px !important;}
.add-new-driver .driver-info .driver-input{width: 300px !important;}
.ticket-dropdown .vehicle-input{width: 300px !important;}
.add-new-driver .driver-info .driver-info-content.search-action{margin-left: 0 !important;}
.add-new-driver .driver-info.add-plate{flex-wrap: wrap;}
/* .add-new-driver .driver-info .driver-info-content.state{margin-right: 30px;} */
.driver-option{width: 300px !important;}
.sec-dashboard .dashboard-item{margin: 0 0 20px;}


/* .ticket-table .table-head .table-rows{width: 1200px;}
.ticket-table .table-body .table-rows{width: 1200px;}
.ticket-table .custom-checkbox{width: 1200px;}
.ticket-table .table-head{overflow: auto;}
.ticket-table .table-body{overflow: auto;} */

.sec-billings-details .details-inner .transaction-bill-summary{margin: 20px 0 0;}
.referral{margin: 20px 0 0 !important;}
.pagination-inner ul li{padding: 6px 12px !important;}
.add-new-driver .driver-info .driver-info-content .img-list .img{    max-width: calc(26% - 20px) !important;
width: 26% !important;}
.pdf-modal .modal-dialog {
max-width: 900px !important;
}
}
@media screen and (max-width: 1024px) {
.sec-dashboard.tlc-management .tlc-tickets-management{flex-wrap: wrap;width: 100%;}
.sec-dashboard.tlc-management .tlc-tickets-management .ticket-submit-box{margin-top: 15px;width: 100%;flex-wrap: wrap;display: flex;justify-content: space-between;}
/* .sec-dashboard.tlc-management .tlc-tickets-management .ticket-submit-box .submit-box-item{margin-right: 15px;}
.sec-dashboard.tlc-management .tlc-tickets-management .ticket-submit-box .submit-box-item+ .submit-box-item{margin-top: 15px;} */
/* .sec-plate-management.ticket-management-list .table-cell.cell-view-btn .dropdown .dropdown-menu {
transform: translate3d(-115px, 24px, 0px) !important;
} */
.submit-new-ticket.ticket-search{flex-wrap: wrap;}

.desktop-view{display: none;}
/* .sidenavigation{display: none;} */
header .authentication .dropdown{display: none;}
header .authentication .user-profile .profile-text{display: none;}
header .authentication .dropdown .dropdown-toggle::after{display: none;}
header .authentication .dropdown .dropdown-toggle{padding-right: 0 !important;}
/* .sec-plate-management .ticket-dropdown.status+ .ticket-dropdown.status{margin-left: 0;} */

header .responsive-action {display: block;}
header .navigation{position: fixed;top: 0;left: 0;z-index: 11;background-color: #fff;height: 100%;width: 280px;box-shadow: 2px 0 10px rgb(0 0 0 / 10%);padding: 40px 0;transform: translateX(-110%);transition: all 0.3s ease;display: block;}

header .navigation-menu .menu-item+ .menu-item {margin: 15px 0 0;}
header .navigation-menu .menu-link {padding: 10px 20px;}
header .contact .contact-item span{display: none;}
header .navigation-wrap{display: block;}
header .navigation-menu{display: block;}
header .navigation.show{transform: translateX(0%);}

.search.management{width: 100%;}
.modal-search .total-pay-action{width: 100%;margin-top: 10px;}
.step-section, .section-tickets{padding: 40px 0;}

header .brand{height: 40px;margin-top: unset !important;}
.what-we-do-section .content{font-size:16px;}
.contact-section .contact-inner{padding: 80px 0px;}
.contact-section .contact-inner .title{font-size: 24px;}

.contact-list{flex-direction: unset !important;margin-bottom: 15px;}
.contact-section .contact-info+ .contact-info{margin-top: 0 !important;margin-left: 10px;}
header .authentication{justify-content: flex-start;padding: 0 0 0 15px;}

.sec-billings-details .details-inner .details-footer .plate-inner .plate-content .plate-item{width: 100% !important;}
.sec-billings-details .details-inner .details-heading .id-sub-title{font-size: 18px !important;}

header .authentication.mobile{display: block;border-left: 0;}
header .authentication.desktop{display: none;}
header .authentication.mobile .btn-login{    color: var(--color02);background: none;padding: 5px;font-size: 24px;}
header .responsive-action .btn-menu{width: 40px;}
header .authentication{border-left: 0;}

.list-filter-action .search{width:100%;}


}
@media screen and (max-width: 990px) {
.sec-dashboard.tlc-management .tlc-tickets-management .ticket-submit-box{justify-content: flex-start;}
header .header-content{margin-top: 10px;}
/* .sidenavigation{display: none;} */
.sec-ticket .ticket-table .custom-checkbox .custom-checkbox-label:before{top: 0;}

.sidenavigation{position:fixed !important;top:0 !important;left:0;z-index:11;background-color:var(--shades01);height:100%;width:300px;padding:15px 0;transform:translateX(-110%);transition:all .4s ease}
.sidenavigation.show {transform: translateX(0%);display: block;}

.service-content{max-width: 100%;text-align: center;}
.whyus .whyus-item{max-width: calc(33.33% - 20px);}
.submit-new-ticket.ticket-search{width: 97%;}
.service-infographics{width: 100%;}
.service-infographics .service-item .service-title{margin: 0;width: 84px;font-size: 10px;}
.service-infographics .service-item .service-icon{width: 60px;height: 60px;font-size: 30px;}
.service-infographics .service-item{transform-origin: 170px;font-size: 30px;}
.service-infographics .service-circle{width: 360px;height: 360px;}
.service-infographics .service-center{width: 180px;height: 180px;}

.step-section .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{bottom: 0;}
.steps{margin-top: 15px;}
.tickets{max-width: 100%;order: 2;}
.section-tickets .tickets-image{height: 300px;}
.ticket-content{max-width: 100%;margin-left: unset;margin-bottom: 15px;order: 1;}
.solution-list .solution-item{padding: 40px 0;}
.pages-banner .page-title, .newsletter-form-section .news-letter-title{font-size:28px ;}
.section-newsletter .news-letter-content .newsletter-form-section{max-width: 100%;margin-top: 15px;}
.newsletter-form-section .form-action{width: unset !important;}
.newsletter-form-section .form-group{max-width: 100%;}
.solution-list .solution-item:nth-child(odd) .solution-img{max-width: 100%;}
.solution-list .solution-item .row{margin-left: unset;margin-right: unset;}
.solution-list .solution-item:nth-child(even) .solution-img{height: 100%;max-width: 100%;margin-right: 0 !important;}
.solution-item .solution-content .title{font-size: 28px;}

.feature-section .feature-box{max-width: 100%;}
.feature-section .feature-part{max-width: 100%;}
.feature-section .feature-descriptions-img{max-width: 100%;}
.feature-section{padding: 40px 0;}
.section-newsletter{padding: 40px 0 170px;}
.what-we-do-section .title{font-size: 28px;margin-top: 15px;}
.what-we-do-section{padding: 40px 0;}
.pages-banner .page-banner-content{font-size: 16px;}

.solution-item .solution-content{width: 100%;}
.what-we-do-section .content{font-size:14px;}
.hero .hero-title{font-size: 28px;}
.section-search .search .search-title, .service-content .service-title, .ticket-content .ticket-title{font-size: 28px;text-align: center;}
.whyus-info .whyus-title, .contact-section .contact-inner .title{font-size: 28px;}
.whyus .whyus-item .item-icon{font-size: 40px;}
.whyus .whyus-item .item-title{font-size: 14px;}
footer{padding: 40px 0;}
.contact-section .contact-inner{padding: 80px 20px;}
.contact-section .contact-inner .title{font-size: 24px;}
/* .section-search .overlay-section{font-size: 30px;} */

.contact-section .contact-inner{padding: 0;margin-bottom: 15px;}
.contact-section{padding: 40px 0;}
.contact-section .form-action{width: 100%;justify-content: center;}
.career-item .job-detail{flex-wrap: wrap;}
.career-item .job-detail-list{max-width: calc(100% - 0px);}
.career-item .job-detail-list .job-detail-item{width: 100%;max-width: calc(100% - 10px);}
.sec-dashboard .dashboard-action{margin: 0 !important;}
.side-panel{margin: 0 0 20px;position: relative;top: 0;}
.dashboard-list-inner .ticket-table .table-body .table-row .table-cell.cell-status{width: 40% !important;max-width: 40% !important;}
.dashboard-list-inner .ticket-table .table-head .table-row .table-cell.cell-status{width: 40% !important;max-width: 40% !important;}
/* .ticket-table .table-head .table-row{width: 800px;}
.ticket-table .table-body .table-row{width: 800px;} */
.sec-plate-details .details-log{margin: 20px 0 10px !important;}
.ticket-table .custom-checkbox{width: 800px;}



.side-overlay{position:fixed;z-index:10;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,0.5);opacity:0;visibility:hidden;cursor:pointer;transition:all .3s ease}
.side-overlay.show {opacity: 1;visibility: visible;}
.side-panel{position: relative !important;top: 0 !important;box-shadow: none !important;border-radius: 0 !important;}
.side-action{display: flex !important;}
.coupon-code .title{margin: 10px 0;}


.driver-list-inner .ticket-table .table-head .table-row.header-row{display:none}
/* .driver-list-inner .ticket-table .table-head .table-row.header-row .cell-image{display:flex} */
.driver-list-inner .ticket-table .table-body .table-row{flex-wrap:wrap!important}
/* .driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-image{width:100%!important;max-width:100%!important} */
.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-image{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-name{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-licence{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-email{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}

.driver-list-inner .ticket-table .driver-management .table-body .table-row .table-cell.cell-image{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.driver-list-inner .ticket-table .driver-management .table-body .table-row .table-cell.cell-name{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.driver-list-inner .ticket-table .driver-management .table-body .table-row .table-cell.cell-licence{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.driver-list-inner .ticket-table .driver-management .table-body .table-row .table-cell.cell-email{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}

.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-action{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.drive-action{padding:0;width:auto !important;position:absolute;top:0;right:14px;display:block;}
.ticket-table .table-body .table-row .table-cell{min-height:100%!important}

.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-image::before{content:'Ticket #';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-name::before{content:'Plate No';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-licence::before{content:'Fine';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-licence.due::before{content:'Due';width:125px;font-size:14px;font-weight:500;color:var(--color02)}


.driver-list-inner .ticket-table.driver-management .table-body .table-row .table-cell.cell-image::before{content:'Name';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.driver-list-inner .ticket-table.driver-management .table-body .table-row .table-cell.cell-name::before{content:'DMV License #';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.driver-list-inner .ticket-table.driver-management .table-body .table-row .table-cell.cell-licence::before{content:'Commercial Licence #';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.driver-list-inner .ticket-table.driver-management .table-body .table-row .table-cell.cell-email::before{content:'Email';width:125px;font-size:14px;font-weight:500;color:var(--color02)}



.driver-list-inner .driver-content{padding-right: 27px;width: 100%;max-width: calc(58% - 10px);}
.driver-list-inner .ticket-table .table-body .table-row .cell-email .driver-list-title{width: 100%;max-width: calc(58% - 10px);}
.ticket-table .table-body .table-row+ .table-row{margin-top: 20px;border: none;}
.ticket-list .ticket-table{background: transparent !important;border: none !important;box-shadow: none !important;}
.driver-list-inner .ticket-table .table-body .table-row{background: var(--shades01);border: 1px solid var(--shades04);box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);border-radius: 6px;padding: 10px;}
.ticket-table .table-body .table-row .table-cell+.table-cell{padding-top: 10px !important;}
.ticket-table .table-body .table-row .table-cell{padding: 0 !important;}

.sec-plate-management .driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-image::before{content:'Plate Number';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.sec-plate-management .driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-name::before{content:'Vin';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.sec-plate-management .driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-licence::before{content:'State';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.sec-plate-management .driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-email::before{content:'Type';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.sec-plate-management .driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-action::before{content:'Auto pay';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.sec-plate-management .driver-list-inner .ticket-table .table-body .table-row .table-cell.open-tickets::before{content:'Open Tickets';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.sec-plate-management .driver-list-inner .ticket-table .table-body .table-row .table-cell.driver-name::before{content:'Driver Name';width:125px;font-size:14px;font-weight:500;color:var(--color02)}


.dashboard-list .ticket-table .table-head .table-row.header-row{display:none}
.dashboard-list .ticket-table .table-body .table-row{flex-wrap:wrap!important}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-image{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-name{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-licence{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-charge{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-email{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-status{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-action{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.dashboard-list .ticket-table .table-body .table-row .table-cell.drive-action{padding:0;width:auto !important;position:absolute;top:0;right:14px;display:block;}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-image::before{content:'Tickets';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-name::before{content:'Issue Date';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-licence::before{content:'Fees';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-charge::before{content:'Reduce Charge';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-email::before{content:'Type';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-status::before{content:'Status';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.dashboard-list .ticket-table .table-body .table-row.header-row .table-cell.cell-view{position: absolute;left: 0;}
.dashboard-list .driver-content{padding-right: 27px;width: 100%;max-width: calc(58% - 10px);}
.dashboard-list .ticket-table .table-body .table-row .cell-email .driver-list-title{width: 100%;max-width: calc(58% - 10px);}
.dashboard-list .ticket-table .table-body .table-row{background: var(--shades01);border: 1px solid var(--shades04);box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);border-radius: 6px;padding: 10px;}

.dashboard-list-inner .ticket-table .table-body .table-row .table-cell.cell-view{position: absolute;left: 11px;top: -86px;}
.dashboard-list-inner .driver-list-image{width: 65px !important;height: 65px !important;border-radius: 5px !important;}

.dashboard-list .ticket-table .table-body .table-row{padding: 10px 0 10px 88px;position: relative;}

.ticket-table .table-head .table-row.header-row{display: none;}
.ticket-table .table-body .table-row {flex-wrap: wrap!important;background: var(--shades01);border: 1px solid var(--shades04);box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);border-radius: 6px;padding: 10px;}

.ticket-table .table-body .custom-checkbox+ .custom-checkbox{margin-top: 20px !important;border-top: none !important;}
.ticket-table .custom-checkbox-input{width: 25px;height: 25px;position: absolute;top: 10px;right: 50px;}
.ticket-table .table-body .table-row .table-cell.cell-check{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.ticket-table .table-body .table-row .table-cell.cell-plate{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.ticket-table .table-body .table-row .table-cell.cell-ticketID{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.ticket-table .table-body .table-row .table-cell.cell-date{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.ticket-table .table-body .table-row .table-cell.cell-fine{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.ticket-table .table-body .table-row .table-cell.cell-vc{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.ticket-table .table-body .table-row .table-cell.cell-violation{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.ticket-table .table-body .table-row .table-cell.cell-status{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important}
.ticket-table .table-body .table-row .table-cell.cell-view-btn{width:100%!important;max-width:100%!important;flex-direction:initial!important;justify-content:flex-start!important;}

/* .ticket-table .table-body .table-row .table-cell.cell-check::before{content:'Tickets';width:125px;font-size:14px;font-weight:500;color:var(--color02)} */
.ticket-table .table-body .table-row .table-cell.cell-plate::before{content:'Plate';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.ticket-table .table-body .table-row .table-cell.cell-ticketID::before{content:'Ticket-ID';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.ticket-table .table-body .table-row .table-cell.cell-fine::before{content:'Fine';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.ticket-table .table-body .table-row .table-cell.cell-fine.due::before{content:'Due';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.ticket-table .table-body .table-row .table-cell.cell-date::before{content:'Date';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.ticket-table .table-body .table-row .table-cell.cell-vc::before{content:'VIN';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.ticket-table .table-body .table-row .table-cell.cell-violation::before{content:'Violation';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.ticket-table .table-body .table-row .table-cell.cell-status::before{content:'Status';width:125px;font-size:14px;font-weight:500;color:var(--color02)}
.ticket-table .table-body .table-row .table-cell.cell-view-btn::before{content:'View';width:125px;font-size:14px;font-weight:500;color:var(--color02)}

.ticket-table.plate-table .custom-checkbox{width:100%;}
.ticket-table.plate-table .custom-checkbox .custom-checkbox-input{top: 10px; transform: none; width: 30px; height: 30px; right: 10px; left: auto;}
.ticket-table.plate-table .custom-checkbox .custom-checkbox-label:before{top: 10px; transform: none; width: 30px; height: 30px; right: 10px; left: auto;display: flex;justify-content: center;align-items: center;}
.ticket-table.plate-table .table-row .table-cell.cell-action{position: absolute;top: 10px;right: 44px;width: 30px;height: 30px;padding: 0 !important;display: flex;justify-content: center;align-items: center;min-height: auto !important;font-size: 20px;}
.ticket-table.plate-table .table-body .custom-checkbox+ .custom-checkbox{margin-top: 10px !important;}



.user-search-part .search.management{width: 100% !important;}
.ticket-dropdown.status{width: 40% !important;}
.ticket-dropdown.status .vehicle-input{width: 100% !important;}
.submit-new-ticket .ticket-submit-box{width: 100%;}
.submit-new-ticket .ticket-submit-box .submit-box-item{width: 100%;}
.ticket-submit-box .submit-box-item .submit-btn{width: 100%;padding: 0 8px;}
.ticket-table .custom-checkbox .custom-checkbox-label:before{    right: 0;z-index: 1;left: 0;top: 0;}
.ticket-dropdown.status .dropdown .dropdown-menu{width: 100% !important;}
.view-btn-action{    position: absolute;top: 10px;right: 10px;}
.ticket-management-list .ticket-table .table-head .table-row{width: auto;}
.ticket-management-list .ticket-table .table-body .table-row{width: auto;}
.ticket-management-list .ticket-table .custom-checkbox{width: 25px;height: 25px;position: absolute;top: 10px;right: 50px;}
.ticket-table .custom-checkbox .custom-checkbox-input{    top: 0;left: 0;width: 100%;height: 100%;z-index: 2;}
.ticket-management-list .ticket-table .table-head{overflow: unset;}
.ticket-management-list .ticket-table .table-body{overflow: unset;}

.sec-ticket .ticket-table .table-body .table-row .table-cell .cell-data{width: 65%;}
.sec-ticket .ticket-table .table-body .table-row .table-cell .cell-check .cell-data{}
/* .sec-ticket .ticket-table .table-body .table-row .table-cell .view-btn-action{width: 65%;} */
.career-apply-modal .details-card{height: 400px;overflow: auto;}
.career-apply-modal .payment-mode{margin: 20px 0 0;}
.success-section .success-message .success-image{width: 80px !important;height: 80px !important;}
.success-section .success-message .success-text .text{font-size: 18px !important;}
/* header .authentication{flex-wrap: wrap;} */
.details-content .vehicle-metalist .meta-item.full .ticket .driver-image{height: 300px !important;}
.sec-plate-management .user-search-part .dropdown .dropdown-menu{transform: translate3d(0px, 40px, 0px) !important;}
/* .sec-plate-management .user-search-part .ticket-dropdown.status .dropdown-toggle{margin-left: 10px;} */

.sec-plate-management .dropdown .dropdown-menu{transform: translate3d(-110px, 24px, 0px) !important;}

.dashboard-list-inner .ticket-table .table-body .table-row .table-cell.cell-view .mobile-title{display: block;font-size: 14px;font-weight: 500;color: var(--color02);}

.dashboard-list-inner .ticket-table .table-body .table-row .cell-view.mobile-view-part-image{position: unset !important;width: 100% !important;max-width: 100% !important;}
.dashboard-list-inner .mobile-view-part-image .driver-list-image{overflow: unset !important;width: unset !important;height: unset !important;box-shadow: none !important;}
.dashboard-list-inner .mobile-view-part-image .driver-list-image{display: flex;align-items: center;}
.dashboard-list-inner .mobile-view-part-image .driver-list-image .manager-name{width: 100%;max-width: calc(100% - 60px);margin-left: 20px;text-transform: capitalize;font-weight: 600;font-size: 14px;}
.dashboard-list-inner .mobile-view-part-image .driver-list-image img{width: 40px !important;height: 40px !important;border-radius: 50%;overflow: hidden;}
.list-filter-action .search-filters{width: 100%;max-width: calc(25% - 8px);}

}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 768px) {
.dashboard-list .ticket-table .table-body .table-row{padding: 15px;}
.sec-ticket.login-part-pages .ticket-table .table-body .table-row{width: unset;}
.sec-billings-details .details-inner .details-footer .plate-inner .plate-content .plate-item{width: 100% !important;}
.sec-billings-details .details-inner .details-content .details-ticket-date .date-title{font-size: 11px !important;}
.sec-billings-details .details-inner .details-ticket-title{font-size: 11px !important;}
.sec-billings-details .details-inner .details-heading .id-title{font-size: 11px !important;}
.sec-billings-details .details-inner .details-heading .id-sub-title{font-size: 14px !important;}

.login-part-pages .user-panel-menus .right-sidebar{max-width: 100% !important;margin-left: 0 !important;width: 100% !important;}
.sec-plate-management .body-content .user-search-part .sec-plate-action{width: 100%;}
.sec-plate-management .ticket-dropdown.status{margin-bottom: 10px;margin-left: 0 !important;width: 100%;}
.sec-billings-details .details-inner .details-heading .id-title{font-size: 11px;}
.sec-billings-details .details-inner .details-heading .id-sub-title{font-size: 14px;}


.sec-billings .billings-inner .billings-item .billings-title{font-size: 12px !important;}
.sec-billings .billings-inner .billings-item .billings-content .payment-mode{font-size: 12px !important;}
.ticket-total .title{font-size: 16px !important;}
.details-content .vehicle-metalist .meta-item{max-width: 100% !important; padding: 10px 0px 4px 0 !important ;}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.view-details{width: 35% !important;max-width: 35% !important;}

.driver-list-inner .ticket-table .table-body .table-row .table-cell.open-tickets{width: 100% !important;max-width: 100% !important;flex-direction: unset !important;justify-content: unset !important;}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.driver-name{width: 100% !important;max-width: 100% !important;flex-direction: unset !important;justify-content: unset !important;}

.sec-plate-management .dropdown .dropdown-menu{transform: translate3d(-110px, 24px, 0px) !important;}
.sec-plate-management.ticket-management-list .table-cell.cell-view-btn .dropdown .dropdown-menu {
transform: translate3d(-115px, 25px, 0px) !important;
}
.authenticationModal .modal-content{margin: 15px;}
}
@media screen and (max-width: 767px) {

/* .sec-plate-management .ticket-dropdown.status{} */

.section-whyus{padding: 40px 0;}
.tickets .ticket-item .ticket-start{max-width: 100%;}
.tickets .ticket-item .ticket-plate .plate-data{width: 80px;height: 30px;font-size: 12px;}
.hero .hero-title{font-size: 24px;}
.hero .hero-text{font-size: 14px;}
.hero .banner-content-list{margin-top: 14px;}
.hero .banner-content-list .list .item+ .item{padding-top: 10px;}
.hero .banner-content-list .list .item{font-size: 16px;}
.hero .banner-content-list .list .item::before{font-size: 20px;}

.step-section .step-part{display: unset;}
.step-section .step-part .steps{max-width: 100%;margin-left: unset !important;}
.steps .steps-item+ .steps-item{margin-top: 5px;}
.steps .steps-item{align-items: center;}
.steps .steps-item .step-icon{font-size: 40px;width: 80px;height: 80px;}

.section-search .overlay-section{font-size: 48px;}
.hero .hero-title{font-size: 28px;}
.service-content{max-width: 100%;}
.section-search .search .search-title, .service-content .service-title, .ticket-content .ticket-title, .whyus-info .whyus-title{font-size: 28px;text-align: center;}
.tickets{max-width: 100%;order: 2;margin-bottom: 15px;}

.client-content{margin: 0 !important;}
.client-content .client-info{max-width: 100%;}
.clients{max-width: 100%;margin: 0 !important;}

.whyus-info{max-width: 100%;margin-bottom: 10px;}
.ticket-content{max-width: 100%;margin-left: unset;margin-bottom: 15px;order: 1;}
.whyus{max-width: 100%;}
.whyus .whyus-item{max-width: calc(50% - 20px);}

.client-info{margin-bottom: 15px;}
.clients .client-list{justify-content: center;}
.clients .client-list .client-item{max-width: 33.33%;margin: 15px 0;}

.section-services .services-background{width: 100%;}
.service-infographics .service-circle:before{width: calc(100% - 80px);height: calc(100% - 80px);}

.service-infographics{width: 100%;}
.service-infographics .service-item .service-title{margin: 0;width: 84px;font-size: 10px;}
.service-infographics .service-item .service-icon{width: 60px;height: 60px;font-size: 30px;}
.service-infographics .service-item{transform-origin: 170px;font-size: 30px;}
.service-infographics .service-circle{width: 360px;height: 360px;}
.service-infographics .service-center{width: 180px;height: 180px;}
header .contact .contact-item+.contact-item{margin-left: 10px;}
header .social{margin: 0 0 0 10px; padding: 0 0 0 10px;}
header .navigation-menu .menu-item+ .menu-item{margin: 15px 0 0;}
header .navigation-menu .menu-link{padding:10px 20px;}
.whyus .whyus-item .item-icon{font-size: 40px;margin: 0  auto 10px;}
.whyus .whyus-item .item-title{font-size: 14px;}

.footer-bottom{flex-wrap: wrap;}
.footer-bottom .other-links{margin-left: 0 !important;}
footer{padding: 40px 0;}
footer .footer-title{margin: 0 0 5px;}
footer .footer-links .link{padding: 5px 0;}
footer .footer-contact .contact-item{padding: 5px 0;}
footer .footer-download{display: flex;align-items: center;}
footer .footer-download .download-item+.download-item{margin-top: 0;margin-left: 5px;}

.solution-list .solution-item{padding: 40px 0;}
.pages-banner .page-title, .newsletter-form-section .news-letter-title{font-size:28px ;}
.section-newsletter .news-letter-content .newsletter-form-section{max-width: 100%;margin-top: 15px;}
.newsletter-form-section .form-action{width: unset !important;}
.newsletter-form-section .form-group{max-width: 100%;}
.solution-list .solution-item:nth-child(odd) .solution-img{max-width: 100%;margin-right: 0 !important;}
.solution-list .solution-item .row{margin-left: unset;margin-right: unset;}
.solution-list .solution-item:nth-child(even) .solution-img{height: 100%;max-width: 100%;}
.solution-item .solution-content .title{font-size: 28px;}

.feature-section .feature-box{max-width: 100%;}
.feature-section .feature-part{max-width: 100%;}
.feature-section .feature-descriptions-img{max-width: 100%;}
.feature-section,.section-services{padding: 40px 0 170px;}
.section-newsletter{padding: 40px 0 80px;}
.what-we-do-section .title{font-size: 28px;margin-top: 15px;}
.what-we-do-section{padding: 40px 0;}
.pages-banner .page-banner-content{font-size: 16px;}

.solution-item .solution-content{width: 100%;}
.what-we-do-section .content{font-size:14px;}

.sec-plate-management.ticket-management-list .table-cell.cell-view-btn .dropdown .dropdown-menu{transform: translate3d(-110px, 24px, 0px) !important;}
.sec-plate-management .driver-list-inner .table-cell.drive-action .dropdown .dropdown-menu{transform: translate3d(-110px, 24px, 0px) !important;}

.contact-list{flex-direction: column !important;}
.contact-section .contact-info+ .contact-info{margin-left: 0;margin-top: 10px !important;}
.authenticationModal .authentication{flex-direction: column;min-height: auto;}
.authenticationModal .auth-image{display: none;}
.authenticationModal .auth-content{max-width: 100%;padding: 20px;}
.modal-close{right: -4px;}
.authenticationModal .auth-title{font-size: 30px;}
.section-newsletter .news-letter-content{text-align: center;justify-content: center;}
.section-newsletter .news-letter-content .newsletter-image{max-width: calc(100% - 30px);}
.add-new-driver .driver-info{flex-wrap: wrap;}
.add-new-driver .driver-info .driver-input{width: 100% !important;}
.add-new-driver .driver-info .driver-input .input{width: 100% !important;}
.add-new-driver .driver-info .driver-info-content+ .driver-info-content{margin-left: 0 !important;margin-top: 10px;}
.add-new-driver .driver-info .driver-info-content{width: 100%;}
.sec-billings .billings-inner .billings-item .billings-content .billings-id{width: auto !important;}
.sec-billings .billings-inner .billings-item .billings-sub-title{font-size: 14px !important;}
.sec-billings .billings-inner .billings-item .billings-content{flex-wrap: wrap;}
.sec-billings .billings-inner .billings-item .billings-content .billings-description{width: 100%;}
.sec-billings .billings-inner .billings-item .billings-content .billings-price{margin: 10px 0 0;}
.sec-billings .billings-inner .billings-item .billings-content .billings-price{font-size: 18px !important;}
.user-search-part .title{margin: 0 0 15px;}
.ticket-dropdown .vehicle-input{width: 100% !important;}
.notifications-inner{flex-wrap: wrap;justify-content: center !important;}
.notifications-inner .driver-notifications .pricing-toggle .switch{width: 48px !important;height: 23px !important;}
.notifications-inner .driver-notifications .pricing-toggle .slider:before{height: 15px !important;width: 15px !important;}
.notifications-inner .driver-notifications .pricing-toggle input:checked + .slider:before{left: 27px !important;}
.notifications-inner .driver-notifications .pricing-toggle .toggle-text{margin: 0 10px !important;}
.notifications-inner .driver-notifications .notifications-title{font-size: 14px !important;}
.notifications-inner .driver-notifications+ .driver-notifications{margin-left: 5px !important;}
.add-new-driver .driver-info .driver-info-content.state{margin-right: 0;}
.driver-option{width: 100% !important;}
.driver-info-content .input-file{width: 135px !important;height: 115px !important;}


.submit-new-ticket .ticket-submit-box{margin-top: 20px;}
.ticket-submit-box .submit-box-item .submit-btn{margin-right: 15px;margin-left: 0 !important;}


.driver-list-inner .ticket-table .table-body .table-row .cell-email .driver-list-title{-webkit-line-clamp:3;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;width: 100%;max-width: calc(58% - 10px);}
.dashboard-list-inner .driver-list-image{width: 60px !important;height: 60px !important;}
.dashboard-list-inner .ticket-table .table-body .table-row .table-cell.cell-view{top: -86px;}
.dashboard-list .driver-content{padding-right: 0;}
.dashboard-list .ticket-table .table-body .table-row{padding: 10px 0 10px 83px;}
.dashboard-list .ticket-table.tlc-tickets-list .table-body .table-row{padding: 10px 0 10px 10px;}

.sec-dashboard.tlc-management .body-content .user-search-part .ticket-submit-box{width: 100%;justify-content: space-between;}

.user-search-part .search.management{width: 100% !important;}
.ticket-dropdown.status{width: 100% !important;}
.ticket-dropdown.status .vehicle-input{margin: 15px 0 0;margin-left: 0 !important;}
.registration-inner .registration-content{margin: 4px;margin-left: 4px !important;}
/* .registration-inner .registration-content+ .registration-content{margin-left: 0 !important;} */
.sec-billings-details .details-inner .details-footer .plate-inner .plate-content{width: auto !important;}
.sec-billings-details .details-inner .details-footer .plate-inner .plate-content .plate-item+.plate-item{margin-left: 10px;}
header .cart-inner .dropdown .dropdown-menu{min-width: 285px;}

.sec-plate-management .dropdown .dropdown-menu{transform: translate3d(-110px, 24px, 0px) !important;}
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {
.list-filter-action .search-filters{width: 100%;max-width: calc(50% - 8px);}

}
@media screen and (max-width: 480px) {
.sec-ticket .ticket-table .custom-checkbox .custom-checkbox-label:before{top: 10px;}
.sec-dashboard .dashboard-tabs-list{justify-content: center;}

.service-infographics .service-center{width: 120px;height: 120px;}
.service-infographics .service-item{transform-origin: 130px;}
.service-infographics .service-item .service-icon{    width: 40px;height: 40px;font-size: 20px;}
.service-infographics .service-circle{width: 280px;height: 280px;}
.service-infographics .service-circle:before{width: calc(100% - 60px);height: calc(100% - 60px);}

.hero .hero-title{font-size: 22px;}
.section-search .search .search-title, .service-content .service-title, .ticket-content .ticket-title{font-size: 22px;text-align: center;}
.whyus-info .whyus-title{font-size: 22px;}
.section-services{padding: 40px 0 170px;}
.career-section .career-content .career-info{max-width: calc(100% - 30px);position: relative;top: 0;margin-bottom: 20px;}
.career-section .career-list{max-width: calc(100% - 30px);}
.sec-dashboard .dashboard-action{flex-wrap: wrap;margin-bottom: 15px !important;}
.sec-dashboard .dashboard-action .driving-btn+.driving-btn{margin-left: 0 !important;}
.sec-dashboard .dashboard-action .driving-btn{height: 48px !important;}
.notifications-inner .driver-notifications{margin-bottom: 10px;}
.notifications-header .image-upload{width: 135px !important;height: 115px !important;}
.notifications-header .image-upload .registration-text{font-size: 14px !important;}
.career-apply-modal .vehicle-metalist .meta-item{width: 40% !important;max-width: calc(40% - 10px) !important;}
.details-content .vehicle-metalist{margin: 0 0 20px;}
.ticket-submit-box .submit-box-item .submit-btn{width: 100%;}
.submit-new-ticket .ticket-submit-box{flex-wrap: wrap;margin-right: 0;}
.submit-new-ticket .ticket-submit-box .submit-box-item{margin-bottom: 10px;width: 100%;}
.checkout-action .btn-action{width: 100%;}
.select-section{flex-wrap: wrap;}
.select-ticket+ .select-ticket{margin-left: 0;margin-top: 8px;}
.add-new-ticket .ticket-info{flex-wrap: wrap;}
.add-new-ticket .input-file{margin-left: 0;margin-top: 10px;width: 100%;}
.add-new-ticket .ticket-info .ticket-input{width: 100%;}
.ticket-info .ticket-input .input{width: 100%;}
.add-new-ticket .btn-submit-action,.select-ticket .check-label{width: 100%;}
.select-ticket{width:100%}
.select-ticket .check-label:after{left: 18px;}
.sec-ticket{padding: 45px 0;}
.add-new-payment .payment-info .payment-input .input,.add-new-payment .payment-info .payment-input{width: 100% !important;}
.submit-action .btn-submit{width: 100% !important;}

.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-image::before{width:100px;}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-name::before{width:100px;}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-licence::before{width:100px;}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-charge::before{width:100px;}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-email::before{width:100px;}
.dashboard-list .ticket-table .table-body .table-row .table-cell.cell-status::before{width:100px;}

.ticket-table .table-body .table-row .table-cell.cell-plate::before{width:100px;}
.ticket-table .table-body .table-row .table-cell.cell-ticketID::before{width:100px;}
.ticket-table .table-body .table-row .table-cell.cell-fine::before{width:100px;}
.ticket-table .table-body .table-row .table-cell.cell-fine.due::before{width:100px;}
.ticket-table .table-body .table-row .table-cell.cell-date::before{width:100px;}
.ticket-table .table-body .table-row .table-cell.cell-vc::before{width:100px;}
.ticket-table .table-body .table-row .table-cell.cell-violation::before{width:100px;}
.ticket-table .table-body .table-row .table-cell.cell-status::before{width:100px;}
.ticket-table .table-body .table-row .table-cell.cell-view-btn::before{width:100px;display: none;}

.section-search .nav-tabs .nav-item+.nav-item{margin-left: 15px;}
.sec-referral .referral-inner .referral-item .referral-img{width: 60px !important;height: 60px !important;}
.payment-card-btn.pay{width: 100% !important;}
.career-apply-modal .pay-or{margin-top: 20px !important;}
.success-section .success-message{width: 86% !important;height: 338px !important;}
.pagination-inner{overflow: scroll;width: 100%;display: block !important;}
.add-new-driver .driver-info .driver-info-content .img-list .img{    max-width: calc(50% - 20px) !important;
width: 50% !important;}


}
@media screen and (max-width: 360px) {
.service-infographics .service-center{width: 120px;height: 120px;}
.service-infographics .service-item{transform-origin: 130px;}
.service-infographics .service-item .service-icon{width: 40px;height: 40px;font-size: 20px;}
.service-infographics .service-circle{width: 280px;height: 280px;}
.service-infographics .service-circle:before{width: calc(100% - 60px);height: calc(100% - 60px);}

.hero .hero-title{font-size: 22px;}
.section-search .search .search-title, .service-content .service-title, .ticket-content .ticket-title{font-size: 22px;text-align: center;}
.whyus-info .whyus-title{font-size: 22px;}
.section-services{padding: 40px 0 170px;}
}
@media screen and (max-width: 320px) {

}
/* ********|| RESPONSIVE ENDS ||******** */

/* raja */

.ticket-table .table-head .table-row .table-cell.vehicle-plate{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.vehicle-ticketID{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.vehicle-date{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.vehicle-fine{width:15%;max-width:15%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.vehicle-vc{width:10%;max-width:10%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.vehicle-violation{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-cell.vehicle-status{width:10%;max-width:10%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-head .table-row .table-title{font-size:14px;font-weight:500;color:var(--color02)}
.ticket-table .table-body .table-row{display:flex;flex-wrap:nowrap;position: relative;}
.ticket-table .table-body .table-row+ .table-row{border-top:1px solid var(--shades04)}
.ticket-table .table-body .table-row .table-cell{display:flex;align-items:center;min-height:50px;padding:10px;width:100%}


.ticket-table .table-body .table-row .table-cell .vehicle-data{font-size: 14px;font-weight: 400;}
.ticket-table .table-body .table-row .table-cell .vehicle-data .status-icon{font-size: 18px;color: var(--color01);}    
.ticket-table .table-body .table-row .table-cell.vehicle-plate{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.vehicle-ticketID{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.vehicle-date{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.vehicle-fine{width:15%;max-width:15%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.vehicle-vc{width:10%;max-width:10%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.vehicle-violation{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.ticket-table .table-body .table-row .table-cell.vehicle-status{width:10%;max-width:10%;flex-direction:column;align-items:center;justify-content:center}

.ticket-table .table-body .table-row .table-cell.vehicle-status .vehicle-edit .edit-btn{color: var(--color01);font-size: 18px;}

.add-vehicle-action{display: flex;justify-content: center;margin-left: 15px;}
.add-vehicle-action .add-vehicle-btn{position:relative;overflow:hidden;display:flex;align-items:center;justify-content:center;padding:0 16px;height:38px;background-color:var(--color01);color:var(--shades01);font-size:14px;font-weight:600;text-transform:uppercase;letter-spacing:.3px;border-radius:4px;width: 150px;}
.add-vehicle-action .add-vehicle-btn i{padding-right: 8px;}
.add-vehicle-inner .save-action .save-btn{position:relative;overflow:hidden;display:flex;align-items:center;justify-content:center;width:150px;height:38px;background-color:var(--color01);color:var(--shades01);font-size:14px;font-weight:600;letter-spacing:.3px;border-radius:4px}
.add-vehicle-inner .save-action{margin: 20px 0 0;}
.add-vehicle-inner .ticket-info{display:flex;align-items:center}
.add-vehicle-inner .ticket-info+ .ticket-info{margin-top:15px}
.add-vehicle-inner .ticket-info .ticket-input{width:350px;border-radius:5px;overflow:hidden;box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;}
.add-vehicle-inner .ticket-info .ticket-input+ .ticket-input{margin-left:10px}
.add-vehicle-inner .ticket-info .ticket-input .input{width:350px;border:none;height:35px;padding-left:10px}
.add-vehicle-inner{padding: 10px;}

.add-vehicle-inner .ticket-dropdown .vehicle-input{width:350px;border-radius:5px;overflow:hidden;box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;height:35px;background-color:#fff}
.add-vehicle-inner .ticket-dropdown .dropdown .dropdown-toggle{text-align: left;padding-left: 10px;}
.add-vehicle-inner .ticket-dropdown .dropdown .dropdown-toggle::after{color: var(--color02);right: 11px;}
.add-vehicle-inner .ticket-dropdown .dropdown .dropdown-menu{width: 97%;border: 1px solid var(--shades04);}
.add-vehicle-inner .ticket-dropdown .dropdown-search{padding:0 10px;margin:0 0 5px}
.add-vehicle-inner .ticket-dropdown .dropdown .dropdown-search .form-control{background-color:transparent;border:0;border-radius:0;border-bottom:1px solid var(--color02);padding:0;width:100%;height:40px;color:var(--color02);font-size:13px;font-weight:500;transition:all .3s ease}
.add-vehicle-inner .ticket-dropdown .dropdown .dropdown-scroll{max-height:100px;overflow:auto}
.add-vehicle-inner .ticket-dropdown .dropdown ::-webkit-scrollbar-track{	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);box-shadow: inset 0 0 6px rgba(0,0,0,0.1);background-color: var(--textMedColor);}
.add-vehicle-inner .ticket-dropdown .dropdown ::-webkit-scrollbar{width: 5px;height: 5px;background-color: #fff;}
.add-vehicle-inner .ticket-dropdown .dropdown ::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 10px;}
/* ********|| DRIVER MANAGEMENT STARTS ||******** */
.dashboard-list-inner.dashboard-table{margin: 20px 0 0;}
.dashboard-list-inner .dashbord-date{font-size:14px;font-weight:400;color:var(--color02);margin:0 0 2px}
.dashboard-list-inner .ticket-table .table-head .table-row .driver-list-title{font-size:14px;font-weight:500;color:var(--color02);margin:0 0 2px}
.dashboard-list-inner .ticket-table .table-body .table-row .driver-list-title{font-size:14px;font-weight:400;color:var(--color02);margin:0 0 2px}
.dashboard-list-inner .driver-list-image{width:40px;height:40px;border-radius:50%;overflow:hidden;box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;}
.dashboard-list-inner .driver-list-image img{width:100%;height:100%;object-fit:cover}
.dashboard-list-inner .ticket-table .table-body .table-row .table-cell.cell-image{width:30%;max-width:30%;flex-direction:column;align-items:flex-start;justify-content:center}
.dashboard-list-inner .ticket-table .table-head .table-row .table-cell.cell-image{width:30%;max-width:30%;flex-direction:column;align-items:flex-start;justify-content:center}

.dashboard-list-inner .ticket-table .table-body .table-row .table-cell.cell-charge{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.dashboard-list-inner .ticket-table .table-head .table-row .table-cell.cell-charge{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}

.dashboard-list-inner .ticket-table .table-body .table-row .table-cell.cell-view{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.dashboard-list-inner .ticket-table .table-head .table-row .table-cell.cell-view{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}

.dashboard-list-inner .ticket-table .table-body .table-row .table-cell.cell-name{width:35%;max-width:35%;flex-direction:column;align-items:flex-start;justify-content:center}
.dashboard-list-inner .ticket-table .table-head .table-row .table-cell.cell-name{width:35%;max-width:35%;flex-direction:column;align-items:flex-start;justify-content:center}
.dashboard-list-inner .ticket-table .table-body .table-row .table-cell.cell-licence{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.dashboard-list-inner .ticket-table .table-head .table-row .table-cell.cell-licence{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.dashboard-list-inner .ticket-table .table-body .table-row .table-cell.cell-email{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}
.dashboard-list-inner .ticket-table .table-head .table-row .table-cell.cell-email{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}
.dashboard-list-inner .ticket-table .table-body .table-row .table-cell.cell-action{width:30%;max-width:30%;flex-direction:column;align-items:flex-start;justify-content:center;}
.dashboard-list-inner .ticket-table .table-head .table-row .table-cell.cell-action{width:30%;max-width:30%;flex-direction:column;align-items:flex-start;justify-content:center;}

.dashboard-list-inner .ticket-table .table-head .table-row .table-cell.cell-status{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}
.dashboard-list-inner .ticket-table .table-body .table-row .table-cell.cell-status{width:25%;max-width:25%;flex-direction:column;align-items:flex-start;justify-content:center}

.driver-list-inner.dashboard-table{margin: 20px 0 0;}
.driver-list-inner .ticket-table .table-head .table-row .driver-list-title{font-size:14px;font-weight:500;color:var(--color02);margin:0 0 2px}
.driver-list-inner .ticket-table .table-body .table-row .driver-list-title{font-size:14px;font-weight:400;color:var(--color02);margin:0 0 2px;word-break: break-all;}
.sec-ticket .driver-list-inner .ticket-table .table-body .table-row .cell-image .driver-list-title{font-weight: 500;}
.driver-list-inner .driver-list-image{width:40px;height:40px;border-radius:50%;overflow:hidden;box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;margin-right: 20px;}
.driver-list-inner .driver-list-image img{width:100%;height:100%;object-fit:cover}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-image{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}
.driver-list-inner .ticket-table .table-head .table-row .table-cell.cell-image{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}

.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-charge{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}
.driver-list-inner .ticket-table .table-head .table-row .table-cell.cell-charge{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}

.driver-list-inner .ticket-table .table-body .table-row .table-cell.view-details{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}
.driver-list-inner .ticket-table .table-head .table-row .table-cell.view-details{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}

.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-name{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}
.driver-list-inner .ticket-table .table-head .table-row .table-cell.cell-name{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-licence{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}
.driver-list-inner .ticket-table .table-head .table-row .table-cell.cell-licence{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-email{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}
.driver-list-inner .ticket-table .table-head .table-row .table-cell.cell-email{width:40%;max-width:40%;flex-direction:column;align-items:flex-start;justify-content:center}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.cell-action{width:30%;max-width:30%;flex-direction:column;align-items:flex-start;justify-content:center;}
.driver-list-inner .ticket-table .table-head .table-row .table-cell.cell-action{width:30%;max-width:30%;flex-direction:column;align-items:flex-start;justify-content:center;}

.driver-list-inner .ticket-table .table-body .table-row .table-cell.drive-action{width:20%;max-width:20%;flex-direction:column;align-items:flex-end;justify-content:center;}
.driver-list-inner .ticket-table .table-body .table-row .table-cell.drive-action .cart-delete-btn{    background: var(--shades01);color: var(--danger);font-size: 14px;text-align: center;display: flex;align-items: center;justify-content: center;}
.driver-list-inner .ticket-table .table-head .table-row .table-cell.drive-action{width:20%;max-width:20%;flex-direction:column;align-items:flex-end;justify-content:center;}
.driver-list-inner .view-btn{position:relative;overflow:hidden;display:flex;align-items:center;justify-content:center;padding:0 16px;height:35px;background-color:var(--color01);color:var(--shades01);font-size:14px;font-weight:500;letter-spacing:.3px;border-radius:4px;width:120px}
.driver-list-inner .ticket-table .table-body .table-row .drive-action{color: var(--color02);font-size: 18px;}
.driver-list-inner .ticket-table .table-body .table-row .drive-action .btn-action{width:24px;height:24px;display:flex;justify-content:center;align-items:center;box-shadow: 0px 2px 5px rgb(0 0 0 / 10%) !important;padding:0;border-radius:0;background-color:#fff;font-size:14px;color:var(--shades07);border: 1px solid rgba(0,0,0,0.05);}
.driver-list-inner .ticket-table .table-body .table-row .drive-action .dropdown .dropdown-menu{box-shadow: rgb(0 0 0 / 10%) 0 2px 10px;border:0;border-radius:2px;padding:6px 0;}
/* .driver-list-inner .ticket-table .table-body .table-row .drive-action .dropdown .dropdown-menu{transform:translate(-82%,0)!important}
.driver-list-inner .ticket-table .table-body .table-row .drive-action .dropdown .dropdown-menu.show{transform:translate(-82%,30px)!important} */
.driver-list-inner .ticket-table .table-body .table-row .drive-action .dropdown-menu .dropdown-item{padding:6px 10px;line-height:1;height:32px;display:flex;align-items:center;font-size:14px;font-weight:400;text-transform:capitalize}
.driver-list-inner .ticket-table .table-body .table-row .drive-action .dropdown-menu .dropdown-item i{margin-right:10px;width:28%;max-width:calc(28% - 20px)}

.saved-cards .card-item .btn-delete-action{position: absolute;right: 10px;top: 10px;z-index: 24;background: transparent;color: var(--danger);font-size: 14px;text-align: center;display: flex;align-items: center;justify-content: center;}

.driver-list-inner .ticket-table .table-body .table-row .cell-action{color: var(--color02);font-size: 18px;}
.driver-list-inner .ticket-table .table-body .table-row .cell-action .btn-action{width:24px;height:24px;display:flex;justify-content:center;align-items:center;box-shadow: 0px 2px 5px rgb(0 0 0 / 10%) !important;padding:0;border-radius:0;background-color:#fff;font-size:14px;color:var(--shades07);border: 1px solid rgba(0,0,0,0.05);}
.driver-list-inner .ticket-table .table-body .table-row .cell-action .dropdown .dropdown-menu{box-shadow: rgb(0 0 0 / 10%) 0 2px 10px;border:0;border-radius:2px;padding:6px 0}
.driver-list-inner .ticket-table .table-body .table-row .cell-action .dropdown .dropdown-menu{transform:translate(-82%,0)!important}
.driver-list-inner .ticket-table .table-body .table-row .cell-action .dropdown .dropdown-menu.show{transform:translate(-82%,30px)!important}
.driver-list-inner .ticket-table .table-body .table-row .cell-action .dropdown-menu .dropdown-item{padding:6px 10px;line-height:1;height:32px;display:flex;align-items:center;font-size:14px;font-weight:400;text-transform:capitalize}
.driver-list-inner .ticket-table .table-body .table-row .cell-action .dropdown-menu .dropdown-item i{margin-right:10px;width:28%;max-width:calc(28% - 20px)}
.driver-list-inner .driver-content{display: flex;align-items: center;}
.driver-info .notifications-option .registration-inner{margin: unset;}
.ticket-total{    display: flex;padding: 20px 0;justify-content: space-between;}
.ticket-total .title{    font-size: 25px;font-weight: 600;text-transform: capitalize;}
.ticket-total .amoumt-text{margin-left: 20px;}
.ticket-total .amoumt-text .text{    font-size: 16px;font-weight: 600;}
.ticket-total .amoumt-text .amount{font-size: 20px;color: var(--color01);font-weight: 600;margin-top: 5px;}
/* ********|| DRIVER MANAGEMENT ENDS ||******** */
/* ********|| ADD DRIVER MANAGEMENT STARTS ||******** */
.add-new-driver .driver-info{display: flex;align-items: flex-start;}
.add-new-driver .file-input-label{width: 100%;height: 100%;cursor: pointer;display: flex;flex-direction: column;align-items: center;justify-content: center;margin-bottom: 0;}
.add-new-driver{border-radius:8px;}
.add-new-driver .radio-group{display: flex;align-items: center;}
.add-new-driver .radio-group .custom-radio+.custom-radio{margin-left: 15px;}
.add-new-driver .driver-info .profile-image-edit{height: 280px; position: relative;width: 280px;border-radius: 50%;overflow: hidden;}
.add-new-driver .driver-info .profile-image-edit .upload-file {display: none;}
.add-new-driver .driver-info .profile-image-edit .file-input-label{align-items: center; border-radius: 10px; color: var(--shades01);
cursor: pointer; display: flex;font-size: 14px;font-weight: 400;justify-content: center;margin-bottom: 0;padding: 10px 12px;border-radius: 50%;overflow: hidden;}
.add-new-driver .driver-info .profile-image-edit .no-image{border: 2px solid var(--color01); border-radius: 50%; height: 250px;overflow: hidden;width: 250px;}
.add-new-driver .driver-info .profile-image-edit .no-image img {height: 100%; width: 100%;}
.add-new-driver .driver-info .profile-image-edit .icon{    align-items: center; background: var(--color01);border-radius: 50px;bottom: 40px;display: flex; font-size: 18px;height: 40px;justify-content: center; position: absolute;right: 30px;width: 40px;}
.add-new-driver .driver-info .driver-input{width:350px;border-radius:5px;overflow:hidden;box-shadow: rgb(0 0 0 / 5%) 0 2px 10px;}
.add-new-driver .driver-info .driver-input .input{width:350px;border:none;min-height:45px;padding-left:10px;background-color: var(--shades01);}
.add-new-driver .driver-info .driver-info-content+ .driver-info-content{margin-left:30px}
.add-new-driver .driver-info.pdf-content .driver-info-content+ .driver-info-content{margin-left:0px}
.add-new-driver .driver-info .driver-info-content .driver-info-title{font-size:15px;font-weight:400;margin-bottom:6px;color: var(--color02);}
.add-new-driver .driver-info .driver-info-content .img-list{display:flex;flex-wrap:wrap;padding:15px 0;margin:0 -20px}
.add-new-driver .driver-info .driver-info-content .img-list .img{align-items:center;position:relative;margin:10px;max-width:calc(20% - 20px);width:20%;display:flex}
.add-new-driver .driver-info.pdf-content{display: block;}
.arrow-btn{font-size:15px;font-weight:400;color:var(--color02)}
.year-arrow{font-size:14px;font-weight:400;padding:2px;color:var(--color02);border:1px solid var(--shades05)}
.year-arrow.left{margin-right:10px}
.driver-option{width:350px}
.react-datepicker{border:1px solid var(--shades05)}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{background-color: var(--color01);color: var(--shades01) !important;}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{background-color: var(--color01);color: var(--shades01) !important;}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{font-size: 12px;font-weight: 400;color: var(--color02);}
.driver-info-content .input-file{width: 160px;height: 140px;}
.driver-info-content .input-file img{width: 100%;height: 100%;object-fit: contain;}
.input-file.cursor{cursor: pointer !important;}
.add-new-driver .driver-info+ .driver-info{margin-top:20px}
.add-new-driver .submit-action{margin:30px 0 0;display: flex;align-items: center;}
.add-new-driver .btn-submit-action{position:relative;overflow:hidden;display:flex;align-items:center;justify-content:center;width:150px;height:38px;background-color:var(--color01);color:var(--shades01);font-size:14px;font-weight:600;text-transform:uppercase;letter-spacing:.3px;border-radius:4px}
.add-new-driver .btn-cancel-action{position:relative;overflow:hidden;display:flex;align-items:center;justify-content:center;width:150px;height:38px;background-color: transparent;margin-left: 10px; border: 1px solid var(--color01);color:var(--color01);font-size:14px;font-weight:600;text-transform:uppercase;letter-spacing:.3px;border-radius:4px}
.add-new-driver .commercial-inner{margin:20px 0}
.pagination-inner{display:flex;justify-content:flex-end}
.pagination-inner .pagination .page-item{margin-right:10px}
.pagination-inner .pagination .page-item.active .page-link{background:var(--color01);color:#fff;border:none}
.pagination-inner .pagination .page-item .page-link{border-radius:5px;width:30px;height:30px;display:flex;align-items:center;border:none;justify-content:center;color:var(--color02);font-size:14px;font-weight:400;background: rgb(0 0 0 / 10%);}

.logoutModal{z-index: 10;}
.modal-backdrop.show{z-index: 1}


/* .edit */
/* ********|| ADD DRIVER MANAGEMENT ENDS ||******** */


/* ********|| CALENDER STARTS ||******** */
.fc .fc-button-primary{border-color: transparent !important;}
.fc .fc-button-primary:not(:disabled).fc-button-active{background-color: var(--color01) !important;}
.fc .fc-button{background-color: var(--color02) !important;}
.fc .fc-button-group>.fc-button:focus, .fc .fc-button-group>.fc-button:hover{background-color: var(--color01) !important}
.fc .fc-button:focus{box-shadow: none !important;}
.fc .fc-button-primary:hover{background-color: var(--color01) !important}
.fc .fc-button:disabled{opacity: 10 !important;}

/* ********|| CALENDER ENDS ||******** */
/* ********|| ADD PLATE STARTS ||******** */
.notifications-inner{display: flex;align-items: center;justify-content: space-between;margin: 20px 0 0;}
.notifications-inner .driver-notifications+ .driver-notifications{margin-left: 30px;}
.notifications-inner .driver-notifications .notifications-title{font-size: 16px;font-weight: 500;margin: 0 0 10px;color: var(--color02);text-align: center;}
.notifications-inner .driver-notifications .notifications-sub-title{font-size: 14px;font-weight: 400;margin: 0 0 10px;color: #000;text-align: center;}
.notifications-inner .driver-notifications .pricing-toggle{display:flex;align-items:center;justify-content:center}
.notifications-inner .driver-notifications .pricing-toggle .slider.round{border-radius:34px}
.notifications-inner .driver-notifications .pricing-toggle .slider.round:before{border-radius:50%}
.notifications-inner .driver-notifications .pricing-toggle .switch{position:relative;display:inline-block;width:60px;height:29px;margin:0}
.notifications-inner .driver-notifications .pricing-toggle .switch input{opacity:0;width:0;height:0}
.notifications-inner .driver-notifications .pricing-toggle .slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;-webkit-transition:.4s;transition:.4s}
.notifications-inner .driver-notifications .pricing-toggle .slider:before{position:absolute;content:"";height:20px;width:20px;left:4px;bottom:4px;background-color:#fff;-webkit-transition:.4s;transition:.4s}
.notifications-inner .driver-notifications .pricing-toggle input:checked + .slider{background-color: var(--color01);}
.notifications-inner .driver-notifications .pricing-toggle input:focus + .slider{box-shadow:0 0 1px var(--color01)}
.notifications-inner .driver-notifications .pricing-toggle .toggle-text{margin:0 25px}
.notifications-inner .driver-notifications .pricing-toggle input:checked + .slider:before{left:34px}
.registration-content .notifications-header{position: relative;display: inline-block;}
.notifications-header .image-upload{display:flex;justify-content:center;align-items:center;flex-direction:column;z-index:1;width:160px;height:140px;background-color:var(--shades01);border-radius:5px;box-shadow: rgb(0 0 0 / 5%) 0 2px 10px;text-align:center;padding:10px}
.notifications-header .image-upload .image-upload-input{position:absolute;top:0;right:0;width:100%;height:100%;opacity:0;cursor:pointer;z-index:1}
.notifications-header .image-upload .registration-icon{font-size: 25px;color: var(--color01);margin: 0 0 10px;}
.notifications-header .image-upload .registration-text{font-size: 15px;font-weight: 400;color: var(--color02);}
.notifications-option .registration-inner{    display: flex;flex-wrap: wrap;padding: 15px 0;margin: 0 -20px;}
.registration-inner .registration-content+ .registration-content{margin-left: 30px;}

.notifications-option .registration-inner .driver-info-content{margin: 20px;}
.notifications-option .registration-inner .driver-info-content .img-list{margin-top: 15px;}
.notifications-option .registration-inner .driver-info-content .img-list .img{width: 140px;height: 140px;border: 1px solid var(--shades04);}
.notifications-option .registration-inner .driver-info-content .img-list .img img{width: 100%;height: 100%;object-fit: contain;}
.notifications-option .registration-inner .driver-info-content .img-list i{    color: #dd0000;position: absolute;font-size: 25px;background: var(--shades01);border-radius: 50px;font-size: 20px;box-shadow: 0 0 5px grey;padding: 0;right: 60px;top: 0;z-index: 9;}
.edit-plate .tickets-inner{text-align: center;}
.edit-plate .tickets-inner .tickets-no{font-size:20px;font-weight:600;margin:0 0 10px;color:var(--color02);text-align:center}
.edit-plate .tickets-inner .tickets-state{font-size:14px;font-weight:400;margin:0 0 10px;color:var(--color02);text-align:center}
.driver-info.pdf-content{}
.driver-info.pdf-content .driver-info-content{}
.driver-info.pdf-content .driver-info-content .img-list{display: flex;margin:10px -5px;position: relative;flex-wrap: wrap;}
.driver-info.pdf-content .driver-info-content .img-list .img{    width: 140px;height: 140px;overflow: inherit;border: 1px solid var(--shades04);margin: 5px;}
.driver-info.pdf-content .driver-info-content .img-list .img img{}
/* ********|| ADD PLATE ENDS ||******** */
/* ********|| PROFILE STARTS ||******** */
.profile-inner .profile-image{width: 100px;height: 100px;border-radius: 50%;box-shadow:rgb(0 0 0 / 10%) 0px 0px 5px;overflow: hidden;}
.profile-inner .profile-image img{width: 100%;height: 100%;object-fit: cover;}
.profile-inner .myaccount-profile-img{position: relative;width: 200px;}
.profile-inner .myaccount-profile-img label{display:block;width:100%;height:100%;position:relative;cursor:pointer}
.profile-inner .myaccount-profile-img .myaccount-profile-img-warp{height:150px;width:150px;border-radius:50%;display:block;overflow:hidden;position:relative;box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;}
.profile-inner .myaccount-profile-img .myaccount-profile-img-warp img{width:100%;height:100%;object-fit:cover;object-position:center;display:block}
.profile-inner .myaccount-profile-img label input[type='file']{position:absolute;z-index:2;width:100%;height:1px;margin:0;opacity:0;top:50%}
.profile-inner .myaccount-profile-img .upload-icon{width:40px;height:40px;background-color:var(--color01);color:#fff;border-radius:50%;text-align:center;display:flex;justify-content:center;align-items:center;position:absolute;right:50px;bottom:10px;font-size:20px}
.ticket-dropdown .vehicle-input{width:350px;border-radius:5px;overflow:hidden;box-shadow: rgb(0 0 0 / 5%) 0 2px 10px;min-height:45px;background-color:var(--shades01)}
.ticket-dropdown.status .vehicle-input{width: 180px;min-height: 38px;background: var(--shades01);}
.ticket-dropdown.status .dropdown .dropdown-menu{width: 95%;}
.ticket-dropdown .dropdown .dropdown-toggle{text-align: left;padding-left: 10px;}
.ticket-dropdown .dropdown .dropdown-toggle::after{color: var(--color02);right: 11px;}
.ticket-dropdown .dropdown .dropdown-menu{width: 100%;border: 1px solid var(--shades04);}
.ticket-dropdown .dropdown-search{padding:0 10px;margin:0 0 5px}
.ticket-dropdown .dropdown .dropdown-search .form-control{background-color:transparent;border:0;border-radius:0;border-bottom:1px solid var(--color02);padding:0;width:100%;height:40px;color:var(--color02);font-size:13px;font-weight:500;transition:all .3s ease}
.ticket-dropdown .dropdown .dropdown-scroll{max-height:100px;overflow:auto}
.ticket-dropdown .dropdown ::-webkit-scrollbar-track{	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);box-shadow: inset 0 0 6px rgba(0,0,0,0.1);background-color: var(--textMedColor);}
.ticket-dropdown .dropdown ::-webkit-scrollbar{width: 5px;height: 5px;background-color: #fff;}
.ticket-dropdown .dropdown ::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 10px;}
/* .profile-inner .profile-content{border-bottom: 1px solid var(--shades05);} */
.profile-inner .profile-title{font-size:18px;font-weight:500;margin: 0 0 20px;position: relative;}
.profile-inner .profile-title.change{margin: 30px 0;}
/* .profile-inner .profile-title::after{content: '';height: 1px;background-color: var(--shades05);width: 100%;position: absolute;bottom: 0;left: 0;}s */
/* ********|| PROFILE ENDS ||******** */

/* .add-plate .ticket-dropdown .vehicle-input{width:350px;border-radius:5px;overflow:hidden;box-shadow: rgb(0 0 0 / 5%) 0 2px 10px;min-height:45px;background-color:var(--shades01)} */
/* ********|| Raja ||******** */
/* ********|| SIDE STARTS ||******** */
.sec-panel{padding: 60px 0;background-color: var(--shades03);}
.login-part-pages .user-panel-menus{margin-right: 0;margin-left: 0;}
.login-part-pages .user-panel-menus .left-sidebar{width: 220px;}
.login-part-pages .user-panel-menus .right-sidebar{max-width: calc(100% - 250px);margin-left: 30px;width: 100%;}

.side-panel{position: sticky;top: 110px;box-shadow: rgb(0 0 0 / 14%) 0px 15px 29px 0px;border-radius: 10px;background: var(--shades01);}
.side-panel .side-menu{position: relative;padding: 15px 0;}
.side-panel .side-menu .menu-link{display: flex;align-items: center;padding: 0 6px;line-height: 1;height: 48px;position: relative;}
.side-panel .side-menu .menu-link:after{content: ''; position: absolute;width: 100%; height: 100%; top: 0; left: 0;z-index: -1; opacity: 0; transform: scale(0,1); transform-origin: left; transition: all 0.3s ease;}
.side-panel .side-menu .menu-link .link-icon{font-size: 18px;font-weight: 400;color: var(--shades02); width: 36px; height: 36px; background-color: var(--thirdColor); display: flex; justify-content: center; align-items: center; border-radius: 6px;transition: all 0.3s ease;}
.side-panel .side-menu .menu-link .link-text{display: block; font-size: 15px; margin-left: 4px;font-weight: 400;color: var(--shades02); transition: all 0.3s ease;}
.side-panel .side-menu .menu-link.active{border-left: 5px solid var(--color02);background: var(--shades04);}
.side-panel .side-menu .menu-link:hover{border-left: 5px solid var(--color02);background: var(--shades04);}
.side-panel .side-menu .menu-link:hover .link-icon{color: var(--color01);}
.side-panel .side-menu .menu-link:hover .link-text{color: var(--color01);}
.side-panel .side-menu .menu-link.active .link-icon{color: var(--color01);}
.side-panel .side-menu .menu-link.active .link-text{color: var(--color01);}
.side-panel .user-menu{padding:20px 0}
.side-panel .user-menu .user-menu-heading{display:flex;padding:14px 20px;color:var(--color01);border-top:rgba(255,255,255,0.05) 1px solid;font-weight:500;margin-bottom:15px;align-items:center}
.side-panel .user-menu .user-menu-heading i{font-size:18px;margin-right:15px;width:36px}
.side-panel .user-menu .user-menu-heading .text{color:var(--color01);}
.side-panel .user-menu .menu-link{padding:0 20px;display:flex;align-items:center;height:40px}
.side-panel .user-menu .menu-link.active{background:linear-gradient(to right,var(--primaryColor),var(--fourthColor))}
.side-panel .user-menu .menu-link+.menu-link{margin-top:10px}
.side-panel .user-menu .menu-link .icon{font-size:18px;font-weight:400;color:var(--color01);width:36px;height:36px;display:flex;justify-content:center;align-items:center;border-radius:6px;transition:all .3s ease}
.side-panel .user-menu .menu-link .text{display:block;font-size:15px;margin-left:10px;font-weight:400;color:var(--color01);transition:all .3s ease}
/* .side-action{display: none;} */
.side-action .side-action{color: var(--color02);font-size: 24px;margin-left: 10px;}
/* .side-action .side-action i{padding-right: 8px;} */
.side-action{display: none;}

/* ********|| SIDE ENDS ||******** */
/* ********|| BILLING  STARTS ||******** */
.sec-billings .billings-inner .billings-item{box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);padding: 15px;background: var(--shades01);border-radius: 5px;}
.sec-billings .billings-inner .billings-item+ .billings-item{margin: 20px 0 0;}
.sec-billings .billings-inner .billings-item .billings-hading .billings-id{margin: 0 0 10px;}
.sec-billings .billings-inner .billings-item .billings-hading{display: flex;justify-content: space-between;margin: 0 0 10px;}
.sec-billings .billings-inner .billings-item .billings-title{font-size: 14px;font-weight: 500;color: var(--shades06);text-transform: uppercase;}
.sec-billings .billings-inner .billings-item .billings-title.ticket{text-transform: uppercase;font-weight: 400;}
.sec-billings .billings-inner .billings-item .billings-sub-title.ticket-no{font-weight: 400;font-size: 16px;}
.sec-billings .billings-inner .billings-item .billings-sub-title{font-size: 20px;font-weight: 700;color: var(--color02);}
.sec-billings .billings-inner .billings-item .billings-hading .billings-status .status-title{font-size: 13px;font-weight: 400;color: var(--color02);position: relative;text-transform: uppercase;}
.sec-billings .billings-inner .billings-item .billings-hading .billings-status .status-title::after{content:'';position:absolute;top:5px;left:-11px;background-color:var(--color01);width:7px;height:7px;border-radius:50%}
.sec-billings .billings-inner .billings-item .billings-hading .billings-status .status-title.cancel{color: var(--statusOpen);}
.sec-billings .billings-inner .billings-item .billings-hading .billings-status .status-title.cancel::after{background-color: var(--statusOpen);}
.sec-billings .billings-inner .billings-item .billings-hading .billings-status .status-title.paid{color: var(--color01);}
.sec-billings .billings-inner .billings-item .billings-hading .billings-status .status-title.paid::after{background-color: var(--color01);}
.sec-billings .billings-inner .billings-item .billings-hading .billings-status .status-title.un-paid{color: var(--statusWaiting);}
.sec-billings .billings-inner .billings-item .billings-hading .billings-status .status-title.un-paid::after{background-color: var(--statusWaiting);}
.sec-billings .billings-inner .billings-item .billings-content .payment-mode{font-size: 14px;font-weight: 400;color: var(--shades06);text-transform: uppercase;}
.sec-billings .billings-inner .billings-item .billings-content .payment-sub-mode{font-size: 14px;font-weight: 600;color: var(--color02);}
.sec-billings .billings-inner .billings-item .billings-content{display: flex;justify-content: space-between;align-items: flex-end;}
.sec-billings .billings-inner .billings-item .billings-content .billings-payment{margin: 0 20px;}
.sec-billings .billings-inner .billings-item .billings-content .billings-description{display: flex;}
.sec-billings .billings-inner .billings-item .billings-content .billings-price{font-size: 20px;color: var(--color01);font-weight: 600;}
.sec-billings .billings-inner .billings-item .billings-content .billings-id{width: 240px;}
/* ********|| BILLING  ENDS ||******** */
/* ********|| BILLING DETAILS  STARTS ||******** */
.sec-billings-details .invoice-inner .invoice-btn i{padding-right: 10px;}
.sec-billings-details .invoice-inner .invoice-btn{position:relative;overflow:hidden;display:flex;align-items:center;justify-content:center;width:150px;height:38px;background-color:var(--color01);color:var(--shades01);font-size:14px;font-weight:600;letter-spacing:.3px;border-radius:4px}
.sec-billings-details .details-inner .details-item{box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);padding: 15px;background: var(--shades01);border-radius: 5px;margin-bottom: 25px;}
.sec-billings-details .details-inner .details-item .details-body-content{margin: 0 0 10px;padding: 0 0 10px;border-bottom: 1px solid var(--shades04);}
.sec-billings-details .details-inner .details-item .details-body-content:last-child{border-bottom: none;}
.sec-billings-details .details-inner .details-heading{display: flex;justify-content: space-between;}
.sec-billings-details .details-inner .details-heading .id-title{font-size:14px;font-weight:500;color:var(--shades06);text-transform:uppercase}
.sec-billings-details .details-inner .details-heading .id-sub-title{font-size:20px;font-weight:700;color:var(--color02)}
.sec-billings-details .details-inner .details-heading{padding:0 0 10px;margin:0 0 10px;border-bottom:1px solid var(--shades04)}
.sec-billings-details .details-inner .details-heading .billings-status{position: absolute;right: 30px;}
.sec-billings-details .details-inner .details-heading .billings-status .status-title{font-size: 13px;font-weight: 400;color: var(--color02);position: relative;text-transform: uppercase;}
.sec-billings-details .details-inner .details-heading .billings-status .status-title::after{content:'';position:absolute;top:5px;left:-11px;background-color:var(--color01);width:7px;height:7px;border-radius:50%}
.sec-billings-details .details-inner .details-heading .billings-status .status-title.cancel{color: var(--statusOpen);}
.sec-billings-details .details-inner .details-heading .billings-status .status-title.cancel::after{background-color: var(--statusOpen);}
.sec-billings-details .details-inner .details-heading .billings-status .status-title.paid{color: var(--color01);}
.sec-billings-details .details-inner .details-heading .billings-status .status-title.paid::after{background-color: var(--color01);}
.sec-billings-details .details-inner .details-heading .billings-status .status-title.un-paid{color: var(--statusWaiting);}
.sec-billings-details .details-inner .details-heading .billings-status .status-title.un-paid::after{background-color: var(--statusWaiting);}
.sec-billings-details .details-inner .details-ticket-title{font-size:14px;font-weight:500;color:var(--shades06);text-transform:uppercase}
.sec-billings-details .details-inner .details-ticket-sub-title{font-size:16px;font-weight:700;color:var(--color02);margin: 2px 0;}
.sec-billings-details .details-inner .details-footer .plate-inner .plate-content .plate-item{width: 120px;}
.sec-billings-details .details-inner .details-ticket-text{font-size:14px;font-weight:400;color:var(--shades06);text-transform:uppercase}
.sec-billings-details .details-inner .details-content{display: flex;justify-content: space-between;margin: 0 0 10px;}
.sec-billings-details .details-inner .details-content .details-ticket-date .date-title{font-size:14px;font-weight:400;color:var(--shades06);text-transform:uppercase;text-align: right;}
.sec-billings-details .details-inner .details-content .details-ticket-date .date-sub-title{font-size:16px;font-weight:500;color:var(--color02)}
.sec-billings-details .details-inner .details-footer{display:flex;justify-content:space-between}
.sec-billings-details .details-inner .details-footer .plate-inner .plate-content{display:flex;justify-content:space-between;width:400px}
.sec-billings-details .details-inner .details-footer .plate-inner .plate-title{font-size:14px;font-weight:400;color:var(--shades06);text-transform:uppercase}
.sec-billings-details .details-inner .details-footer .plate-inner .plate-sub-title{font-size:16px;font-weight:700;color:var(--color02)}
.sec-billings-details .details-inner .details-footer .details-price{font-size:20px;color:var(--shades06);font-weight:600}
.sec-billings-details .details-inner .details-footer .details-price.cancel{color: var(--statusOpen);}
.sec-billings-details .details-inner .details-footer .details-price.paid{color: var(--color01);}
.sec-billings-details .details-inner .details-footer .details-price.un-paid{color: var(--statusWaiting);}
/* .sec-billings-details .details-inner .transaction-bill-summary{width: 150px;} */
.sec-billings-details .details-inner .transaction-bill-summary .coupon-inner{display: flex;align-items: center;justify-content: space-between;box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);padding: 15px;background: var(--shades01);border-radius: 5px;}
.sec-billings-details .details-inner .transaction-bill-summary .coupon-inner .coupon-title{font-size:14px;font-weight:400;color:var(--shades06);text-transform:uppercase}
.sec-billings-details .details-inner .transaction-bill-summary .coupon-inner .coupon-text{font-size:14px;font-weight:400;color:var(--color01);text-transform:uppercase}
.sec-billings-details .details-inner .payment-inner .payment-title{font-size:16px;font-weight:700;color:var(--color02);margin: 0 0 10px 0;}
.sec-billings-details .details-inner .payment-inner .payment-item{box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);background:var(--shades01);border-radius:5px;overflow: hidden;}
.sec-billings-details .details-inner .payment-inner .payment-item .payment-content{display:flex;align-items:center;justify-content:space-between}
.sec-billings-details .details-inner .payment-inner .payment-item .payment-content .payment-text{font-size:14px;font-weight:400;color:var(--shades06)}
.sec-billings-details .details-inner .payment-inner .payment-item .payment-content .payment-price{font-size:14px;font-weight:700;color:var(--color02)}
.sec-billings-details .details-inner .payment-inner .payment-item .payment-content+ .payment-content{margin:15px 0 0}
.sec-billings-details .details-inner .payment-inner .payment-item .payment-content .payment-price.discount{color:var(--statusOpen)}
.sec-billings-details .details-inner .payment-inner .payment-item .payment-footer{background-color: var(--shades04);display: flex;align-items: center;justify-content: space-between;padding:15px;}
.sec-billings-details .details-inner .payment-inner .payment-item .payment-footer .total-text{font-size:16px;font-weight:600;color:var(--color02);text-transform:uppercase}
.sec-billings-details .details-inner .payment-inner .payment-item .payment-footer .total-Price{font-size:16px;font-weight:600;color:var(--color02);text-transform:uppercase}
.sec-billings-details .details-inner .payment-inner .payment-item .payment-option{padding:15px}
/* ********|| BILLING DETAILS  ENDS ||******** */
/* ********|| REFERRAL STARTS ||******** */
.sec-referral .referral-inner .referral-item .referral-img{width: 80px;height: 80px;overflow: hidden;border-radius: 50%;}
.sec-referral .referral-inner .referral-item .referral-img img{width: 100%;height: 100%;object-fit: cover;}
.sec-referral .referral-inner .referral-item .referral-content .referral-name{font-size:16px;font-weight:700;color:var(--color02);margin: 0 0 8px;}
/* .sec-referral .referral-inner .referral-item .referral-content .referral-heading{display: flex;justify-content: space-between;} */
.sec-referral .referral-inner .referral-item .referral-content .referral-heading .referral-group .referral-label{font-size:14px;font-weight:400;color:var(--shades06);text-transform:uppercase;margin:0 0 2px}
/* .sec-referral .referral-inner .referral-item .referral-content .referral-heading .referral-group .referral-copy{color:var(--color02);font-size:14px;font-weight:500;background-color:transparent;display:flex;border:1px dashed var(--color01);width:150px;justify-content:space-between;height:30px;align-items:center;border-radius:6px}
.sec-referral .referral-inner .referral-item .referral-content .referral-heading .referral-group .referral-copy .icon{border-left: 1px dashed var(--color01);}
.sec-referral .referral-inner .referral-item .referral-content .referral-heading .referral-group .referral-copy i{padding-left: 5px;} */
/* .sec-referral .referral-inner .referral-item .referral-content .referral-sub-name{font-size:14px;font-weight:400;color:var(--shades06)} */
.sec-referral .referral-inner .referral-item .referral-content{margin-left: 10px;width: 90%;max-width: calc(90% - 50px);}
.sec-referral .referral-inner .referral-item .referral-content .referral-details{display:flex;align-items:center;justify-content:space-between;margin:10px 0 0}
.sec-referral .referral-inner .referral-item .referral-content .referral-details .referral-date{font-size:12px;font-weight:400;color:var(--shades06);text-transform:uppercase}
.sec-referral .referral-inner .referral-item .referral-content .referral-details .referral-date-no{font-size:14px;font-weight:500;color:var(--color02);}
.sec-referral .referral-inner .referral-item{box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);padding:15px;background:var(--shades01);border-radius:5px;display: flex;justify-content: space-between;align-items: center;}
.sec-referral .referral-inner .referral-item+ .referral-item{margin: 20px 0 0;}
.referral{position: relative; padding: 20px; margin: 0 0 20px; background-color: var(--shades01); border-radius: 5px;box-shadow: rgb(0 0 0 / 10%) 0 2px 16px;}
.referral .group{text-align: center;}
.referral .group .group-label{font-size: 13px; text-transform: uppercase; font-weight: 400; line-height: 1; color: var(--shades07);margin: 0 0 20px;}
.referral .group .group-data{font-size: 14px; font-weight: 600; color: var(--shades02); line-height: 1; margin: 0 0 20px;}
.referral .group .group-data.alt{font-size: 24px; font-weight: 700; color: var(--color01);}
.referral .group .group-action{display: flex; align-items: center; justify-content: center; margin:  20px 0 0;}
.referral .group-action .btn-action{font-size: 14px; display: flex; justify-content: center; align-items: center; padding: 0 10px; width: 100%; height: 40px; text-transform: uppercase; font-weight: 500; letter-spacing: 0.3px; border-radius: 30px; color: var(--shades01); background-color: var(--color01);}
.vehicle-action{margin: 23px 0 0;}
.vehicle-action .vehicle-btn{position:relative;overflow:hidden;display:flex;align-items:center;justify-content:center;padding:0 16px;height:44px;background-color:var(--color01);color:var(--shades01);font-size:14px;font-weight:500;letter-spacing:.3px;border-radius:4px;width:160px;margin-left: 12px;}
.show-details-inner{border: 1px solid var(--shades04);box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);background-color: var(--shades01);padding: 10px;}
.show-details-inner .change-inner{position: relative;display: flex;}
.show-details-inner .change-inner+.Change-inner{border-top: 1px solid var(--shades04);}

.show-details-inner .change-inner .change-image{width: 40px; height: 40px; border-radius:50%; margin:0; overflow: hidden;z-index: 1;}
.show-details-inner .change-inner .change-image img{width: 100%; height: 100%; object-fit: cover;}
.show-details-inner .change-inner .change-content{width: 100%;max-width: calc(100% - 50px);margin-left: 10px;border-radius: 10px;display: flex; flex-direction: column; justify-content: center;}           
.show-details-inner .change-inner .change-content .change-name{font-size: 16px; font-weight: 500; line-height: 1; color: var(--shades02);margin: 0 0 10px;}
.show-details-inner .change-inner .change-content .change-meta{display: flex;align-items: center;justify-content: space-between;}               
.show-details-inner .change-inner .change-content .change-meta .meta-label{font-size: 14px; text-transform: uppercase; font-weight: 400; line-height: 1; color: var(--shades07);}
.show-details-inner .change-inner .change-content .change-meta .meta-data{font-size: 14px; text-transform: uppercase; font-weight: 400; line-height: 1; color: var(--shades07);}           
/* ********|| REFERRAL ENDS ||******** */
/* ********|| DASHBOARD STARTS ||******** */
/* .sec-dashboard .dashboard-inner{display: flex;justify-content: space-between;} */
.sec-dashboard .dashboard-stats{display: flex; flex-wrap: wrap; margin: 0 -5px;}
.sec-dashboard .dashboard-item{width: 100%; max-width: calc(25% - 10px); margin: 5px;}
.sec-dashboard .dashboard-item.blue{background: linear-gradient(to right, #0575e6, #021b79);}
.sec-dashboard .dashboard-item.red{background: linear-gradient(to right, #ee0979, #ff6a00);}
.sec-dashboard .dashboard-item.yellow{background: linear-gradient(to right, #ffb75e, #ed8f03);}
.sec-dashboard .dashboard-item.green{background: linear-gradient(to right, #56ab2f, #a8e063);}
.sec-dashboard .dashboard-item{display: flex;align-items: center;padding:15px;border-radius: 5px;background-color: var(--shades01);height: 100px;border: 1px solid var(--shades05);position: relative;}
/* .sec-dashboard .dashboard-item::after{position: absolute;content: '';background-color: var(--color01);width: 5px;height: 100%;left: 0;top: 0;border-radius: 5px;} */
/* .sec-dashboard .dashboard-item.yello{background-color: var(--statusWaiting);} */
.sec-dashboard .dashboard-item .dashboard-icon{color:var(--shades01);font-size:35px;width:55px;display:flex;align-items:center;justify-content:center}
.sec-dashboard .dashboard-item .dashboard-content .dashboard-title{font-size:14px;font-weight:500;color:var(--shades01);margin:0 0 8px}
.sec-dashboard .dashboard-item .dashboard-content{margin-left:10px;padding-left:10px;border-left:1px solid var(--shades01)}
.sec-dashboard .dashboard-item .dashboard-content .dashboard-text{font-size:30px;font-weight:500;color:var(--shades01);line-height: 1;}

.sec-dashboard .dashboard-list{margin:10px 0 60px  0;}
.sec-dashboard .dashboard-action{display: flex;align-items: center;justify-content: flex-start; margin: 30px 0 0;}
.sec-dashboard .dashboard-action .driving-btn.active{background: var(--color01);color: var(--shades01);}
.sec-dashboard .dashboard-action .driving-btn{position:relative;overflow:hidden;display:flex;align-items:center;justify-content:center;padding:0 16px;height:44px;background-color:var(--shades01);color:var(--color02);font-size:14px;font-weight:600;text-transform:uppercase;letter-spacing:.3px;border-radius:4px;width:155px;border: 1px solid var(--shades04);}
.sec-dashboard .dashboard-action .driving-btn+.driving-btn{margin-left: 20px;}
.sec-dashboard .view-btn{position:relative;overflow:hidden;display:flex;align-items:center;justify-content:center;padding:0 10px;height:35px;background-color:var(--color01);color:var(--shades01);font-size:12px;font-weight:500;letter-spacing:.3px;border-radius:4px;width:100%}
/* ********|| DASHBOARD ENDS ||******** */
/* ********|| CHECKOUT START ||******** */
.payment-summary{padding: 20px; margin: 0 0 20px; background-color: var(--shades01); border-radius: 5px;box-shadow: rgb(0 0 0 / 10%) 0 2px 16px; overflow: hidden;}     
.payment-summary .payment-title{font-size: 16px; font-weight: 600; color: var(--shades02); line-height: 1;}
.payment-summary .summary-list{position: relative; margin: 10px 0;}
.payment-summary .summary-list .item{display: flex; justify-content: space-between; align-items: center;line-height: 1;padding: 0 0 16px;}         
.payment-summary .item .item-label{font-size: 14px; font-weight: 400; color:var(--shades06);}
.payment-summary .item .item-data{font-size: 14px;font-weight: 600; color:var(--shades02);}       
.payment-summary .summary-total{display: flex; justify-content: space-between; align-items: center;line-height: 1;padding: 20px; margin: 0 -20px -20px; background-color:var(--shades06);}        
.payment-summary .summary-total .label{font-size: 15px; font-weight: 500; color:var(--shades01); text-transform: uppercase;}
.payment-summary .summary-total .data{font-size: 17px; font-weight: 600; color:var(--shades01);}     
.coupon-code{margin: 0 0 20px;}
.coupon-code .coupons-input .input-wrap{border:1px solid var(--shades01);display:flex;background-color: var(--shades01);border-radius: 5px;box-shadow: rgb(0 0 0 / 10%) 0 2px 16px;margin:0 auto;height: 44px;display: flex;align-items: center;}
.coupon-code .coupons-input .input-style{width:100%;max-width:calc(100% - 70px);border:none;border-radius:5px 0 0 5px;padding:0 15px;background:transparent;font-size: 14px;}
.coupon-code .coupons-input .action{border:none;background:transparent;color:var(--color01);padding:10px;height:40px;width:70px;border-radius:0 5px 5px 0;font-size:14px;font-weight: 500;}  
.coupons-input.remove .input-wrap{border:1px solid var(--primaryColor);display:flex; background-color: var(--shades01);border-radius: 10px;box-shadow: rgb(0 0 0 / 10%) 0 2px 16px;margin:0 auto}
.coupons-input.remove .input-style{width:100%;max-width:calc(100% - 70px);border:none;border-radius:5px 0 0 5px;padding:0 15px;background:transparent}
.coupons-input.remove .action.remove{border:none;background:transparent;color:var(--colorDanger);padding:10px;height:40px;width:70px;border-radius:0 5px 5px 0;font-size:12px}
.payment-select{margin: 0 0 40px;}
.payment-select .title{font-size: 16px;font-weight: 600;color: var(--shades02);line-height: 1;text-transform: capitalize;}
.payment-select .radio-group{position: relative;background: var(--shades01);box-shadow: rgb(0 0 0 / 10%) 0 2px 16px;padding: 10px;border-radius: 5px;}
.payment-select .radio{position: relative;padding: 0 10px;height: 46px;display: flex;align-items: center;width: 100%;}
.payment-select .radio+.radio{border-top: 1px solid var(--shades04);}
.payment-select .radio-input{position: absolute; top:0;right: 0; width: 100%; height: 100%;opacity: 0; cursor: pointer;z-index: 1;}
.payment-select .radio-label{font-size: 16px;line-height: 1;padding:8px 0;padding-left: 24px;position: relative;font-weight: 500;color: var(--shades07);display: flex;align-items: center;width: 100%;justify-content: space-between;}
.payment-select .radio-label .icon{font-size: 26px;color: var(--shades05);}
.payment-select .radio-label .text{margin-left: 10px;font-size: 14px;font-weight: 500;color: var(--shades07);}
.payment-select .radio-label:before{content:'';position: absolute;top: 50%;left: 0;transform: translateY(-50%);width: 16px;height: 16px;background-color: var(--shades01);border-radius: 50%;border: 1px solid var(--shades07);}
.payment-select .radio-label:after{content:'';position: absolute;top: 50%;left: 3px;transform: translateY(-50%);width: 10px;height: 10px;background-color: var(--color01);border-radius: 50%;opacity: 0;}
.payment-select .radio-input:checked ~ .radio-label:after{opacity: 1;}
.payment-select .radio-input:checked ~ .radio-label:before{border: 1px solid var(--color01);}
.payment-select .radio-input:checked ~ .radio-label{color: var(--shades07);}
.payment-select .radio-input:checked ~ .radio-label .icon{color: var(--color01);}
.saved-cards{margin: 0 0 40px;}
.saved-cards .title{font-size: 16px;font-weight: 600;color: var(--shades02);line-height: 1;text-transform: capitalize;margin-bottom: 10px;}
.saved-cards .card-list{background: var(--shades01);box-shadow: rgb(0 0 0 / 10%) 0 2px 16px;border-radius: 5px;padding: 10px;}     
.saved-cards .card-list .add-card-btn{border-top:1px solid var(--shades06);width:100%}
.saved-cards .card-list .add-card-btn .add-action{background:none;color:var(--shades06);display:flex;margin:15px 0 10px;align-items:center}
.saved-cards .card-list .add-card-btn .add-action .icon{font-size:16px;margin-right:6px}
.saved-cards .card-list .add-card-btn .add-action .text{font-size:14px;font-weight:500;text-transform:capitalize}       
.saved-cards .card-item{position: relative;}
.saved-cards .card-item+ .card-item{border-top: 1px solid var(--shades04);}       
.saved-cards .card-item .label-icon .icon{width: 70px;color: var(--shades06);display: flex;align-items: center;font-size: 60px;justify-content: center;margin-right: 15px;}
.saved-cards .card-item .card-content{width: 100%;}         
.saved-cards .card-item .card-content .card-number{margin-bottom: 2px;}
.saved-cards .card-item .card-content .card-number .card-text{font-size: 16px;line-height: 1.5;text-transform: capitalize;}
.saved-cards .card-item .card-content .expiry-date{display: flex;}
.saved-cards .card-item .card-content .expiry-date .card-text{font-size: 12px;line-height: 1.5;text-transform: capitalize;}
.saved-cards .card-item .card-content .expiry-date .data{font-size: 12px;line-height: 1.5;text-transform: capitalize;margin-left: 5px;}
.saved-cards .card-item .card-content .card-number .data{font-size:16px;line-height:1.4;font-weight:600}
.saved-cards .card-item .card-content .cvv-number .card-text{font-size: 12px;line-height: 1.5;text-transform: capitalize;margin-bottom: 5px;}
.saved-cards .card-item .card-content .cvv-number .input-style{position: relative;border: 1px solid var(--shades06);border-radius: 4px;padding: 2px 8px;width: 60px;font-size: 14px;text-align: center;}
.saved-cards .card-item .card-content .card-details{display: flex;width: 100%;justify-content: space-between;align-items: flex-end;}
.saved-cards .card-item .radio{position: relative;width: 100%;} 
.saved-cards .card-item .radio+ .radio{border-top: 1px solid var(--shades06);}        
.saved-cards .card-item .radio .radio-input{position: absolute; top:0;right: 0; width: 100%; height: 100%;opacity: 0; cursor: pointer;z-index: 1;}
.saved-cards .card-item .radio .radio-label{font-size: 16px;line-height: 1;padding:10px;position: relative;font-weight: 500;color: var(--shades07);border-radius: 6px;display: flex;width: 100%;align-items: flex-start;}
.saved-cards .card-item .radio .radio-label .text{margin-left: 10px;font-size: 13px;font-weight: 500;color: var(--shades07);}
/* // .radio-label:before{content:'';position: absolute;top: 10px;left: 0;width: 16px;height: 16px;background-color: var(--shades01);border-radius: 50%;border: 1px solid var(--shades07);}
// .radio-label:after{content:'';position: absolute;top: 14px;left: 4px;width: 10px;height: 10px;background-color: var(--color01);border-radius: 50%;opacity: 0;}
// .radio-input:checked ~ .radio-label:after{opacity: 1;}
// .radio-input:checked ~ .radio-label{border: 2px solid var(--color01);} */
.saved-cards .card-item .radio .radio-input:checked ~ .radio-label{color: var(--shades07);}
.saved-cards .card-item .radio .radio-input:checked ~ .radio-label .icon{color: var(--color01);}
.sec-referral .referral-inner .referral-inner-link+ .referral-inner-link{margin-top: 20px;}
.sec-referral .referral-inner .referral-inner-link{display: block;}
.add-new-payment .payment-info .payment-input.cvv-content{width: 150px;}
.add-new-payment .payment-info .payment-input{width:400px;border-radius:5px;overflow:hidden;box-shadow: rgb(0 0 0 / 5%) 0 2px 10px;}
.add-new-payment .payment-info .payment-input .input{width:400px;border:none;min-height:45px;padding-left:10px;background-color: var(--shades01);}
.add-new-payment .payment-info .payment-input .input.cvv-item{width: 150px;}
.add-new-payment .payment-info.cvv-inner .payment-info-content+ .payment-info-content{margin-left:30px}
.add-new-payment .payment-info .payment-info-content{margin: 0 0 16px;}
.add-new-payment .payment-info .payment-info-content .payment-info-title{font-size:15px;font-weight:500;margin-bottom:6px;color: var(--color02);}
.add-new-payment .payment-info+ .payment-info{margin-top:20px}
.add-new-payment .payment-info.cvv-inner{display: flex;align-items: center;}
.add-new-payment{border-radius:8px}
.add-new-payment .title{font-size:16px;font-weight:600;color:var(--shades02);line-height:1;text-transform:capitalize;margin-bottom:15px}
.coupon-code .title{font-size:16px;font-weight:600;color:var(--shades02);line-height:1;text-transform:capitalize;margin-bottom:10px}
.payment-option .title{font-size:16px;font-weight:600;color:var(--shades02);line-height:1;text-transform:capitalize;margin-bottom:10px}
.submit-action .btn-submit{font-size:14px;height:40px;padding:0 10px;display:flex;justify-content:center;align-items:center;text-transform:uppercase;font-weight:600;letter-spacing:1px;width:250px;border-radius:5px;background-color:var(--color01);color:var(--shades01)}
/* ********|| CHECKOUT END ||******** */
/* ********|| PLATE DETAILS STARTS ||******** */
.sec-plate-details .details-log{font-size:15px;font-weight:500;line-height:1;color:var(--shades02);margin:0 0 10px}
.details-content .details-content{position: relative;}
.details-content .details-image{width: 100%; height: 360px; overflow: hidden;position: fixed;z-index: 0;}
.details-content .details-image img{ width: 100%; height: 100%; object-fit: cover;}
.details-content .details{position: relative; z-index: 1;}
.details-content .details-card{position: relative;background-color: var(--shades01); padding: 20px; margin: 0; border-radius: 10px;}
.details-content .details-title{font-size:18px;font-weight:600;color:var(--shades02);line-height:1}
.details-content .vehicle-name{font-size:20px;font-weight:600;color:var(--shades02);line-height:1}
.details-content .vehicle-info{display:flex;align-items:center;justify-content:space-between}
.details-content .vehicle-info .vehicle-number{font-size:20px;font-weight:700;color:var(--color01);line-height:1;}
.details-content .vehicle-info .report-details .report-btn{background: none;color: var(--shades01);text-transform: capitalize;font-size: 14px;height: 20px;border-radius: 4px;font-weight: 400;background: var(--color01);padding: 12px;display: flex;align-items: center;justify-content: center;height: 40px;}
.details-content .vehicle-metalist{position:relative;margin:0 0 40px;display:flex;flex-wrap:wrap;margin-top: 15px;}
.details-content .vehicle-metalist .meta-item{width:100%;max-width:33.33%;padding:10px 10px 10px 0;margin:0;}
.details-content .vehicle-metalist .meta-item.full{border-bottom: none;max-width: 100%;}
.details-content .vehicle-metalist .meta-item.full .ticket .driver-image{width: 100%;height: 500px;}
.details-content .vehicle-metalist .meta-item.full .ticket .driver-image iframe{width: 100%;height: 100%;}
.details-content .vehicle-metalist .meta-item.item-half{max-width:50%}
.details-content .vehicle-metalist .meta-item.item-two-third{max-width:66.66%}
.details-content .vehicle-metalist .meta-item.item-full{max-width:100%}
.details-content .vehicle-metalist .meta-item .meta-label{font-size:12px;font-weight:400;line-height:1;color:var(--shades06);text-transform:uppercase;margin:0 0 4px}
.details-content .vehicle-metalist .meta-item .meta-data{font-size:14px;font-weight:500;color:var(--shades02);line-height:1.4}
.details-content .assigned-inner{display:flex;justify-content:space-between}
.details-content .assigned-inner .log-btn{background-color:transparent;font-size:14px;font-weight:500;line-height:1;color:var(--shades02)}
.details-content .assigned-drivers{position:relative;margin:10px 0 40px}
.details-content .assigned-drivers .driver{position:relative}
.details-content .assigned-drivers .driver+.driver{border-top:1px solid var(--shades04)}
.details-content .assigned-drivers .driver .driver-image{width:80px;height:80px;border-radius:50%;margin:0;overflow:hidden;position:absolute;top:50%;transform:translateY(-50%);left:0;z-index:1}
.details-content .assigned-drivers .driver .driver-image img{width:100%;height:100%;object-fit:cover}
.details-content .assigned-drivers .driver .driver-content{width:100%;max-width:100%;padding:20px;padding-left:100px;overflow:hidden;border-radius:10px;min-height:100px;display:flex;flex-direction:column;justify-content:center}
.details-content .assigned-drivers .driver .driver-content .driver-name{font-size:16px;font-weight:700;line-height:1;color:var(--shades02)}
.details-content .assigned-drivers .driver .driver-content .driver-meta{margin:10px 0 0}
.details-content .assigned-drivers .driver .driver-content .driver-meta .meta-label{font-size:10px;text-transform:uppercase;font-weight:400;line-height:1;color:var(--shades07)}
.details-content .assigned-drivers .driver .driver-content .driver-meta .meta-data{font-size:13px;font-weight:600;color:var(--shades02);line-height:1} 
/* ********|| PLATE DETAILS ENDS ||******** */
/* ********|| CAREER MODAL STARTS ||******** */
.career-apply-modal .vehicle-name{font-size:18px;font-weight:600;margin-bottom:17px;color:var(--color01)}
.career-apply-modal .vehicle-number{font-size:15px;font-weight:600;margin-bottom:6px;color:var(--color02)}
.career-apply-modal .details-card{border-bottom: 1px solid var(--shades05);padding-bottom: 10px;}
.career-apply-modal .vehicle-metalist{display:flex;flex-wrap:wrap}
.career-apply-modal .vehicle-metalist .meta-item{width:25%;max-width:calc(25% - 10px);margin:6px 5px}
.career-apply-modal .vehicle-metalist .meta-item .meta-label{font-size:15px;color:var(--color02);font-weight:500}
.career-apply-modal .vehicle-metalist .meta-item .meta-data{font-size:14px;color:var(--shades02);font-weight:400}
.career-apply-modal.delete-modal .modal-title{font-size:18px;font-weight:500;color:var(--color02)}
.career-apply-modal.delete-modal .modal-text{font-size:14px;font-weight:400;color:var(--color02);padding: 10px 0;line-height: 1.3;}
.career-apply-modal .driver-info-content .driver-info-title{font-size: 15px;color: var(--color02);font-weight: 500;margin: 5px 0;}
.career-apply-modal.delete-modal .cancel-btn{border:1px solid var(--statusOpen);background-color:transparent;color:var(--statusOpen);font-size:16px;font-weight:400;border-radius:5px;height:38px;width:150px;display:flex;align-items:center;justify-content:center}
.career-apply-modal.delete-modal .confirm-btn{background-color: var(--color01);color: var(--shades01);font-size: 16px;font-weight: 400;border-radius: 5px;height: 38px;width: 150px;display: flex;align-items: center;justify-content: center;}
.career-apply-modal .table-body{overflow: auto;max-height: 400px;}
.career-apply-modal .table-body::-webkit-scrollbar-track{	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);box-shadow: inset 0 0 6px rgba(0,0,0,0.1);background-color: var(--textMedColor);}
.career-apply-modal .table-body::-webkit-scrollbar{width: 5px;height: 5px;background-color: #fff;}
.career-apply-modal .table-body::-webkit-scrollbar-thumb{background-color: var(--color01);border-radius: 10px;}

.career-apply-modal .vehicle-metalist .assigned-drivers{width:100%;}
.career-apply-modal .vehicle-metalist .driver-image{width:100%;height:300px;}
.career-apply-modal .vehicle-metalist .driver-image iframe{width:100%;height:100%;}
.career-apply-modal .pay-or{font-size: 16px;font-weight: 500;color: #000;border-radius: 50%;border: 1px solid var(--shades06);
display: flex;align-items: center;justify-content: center;height: 40px;width: 40px;margin: 0 auto;}

.pdf-modal .modal-dialog{max-width: 1200px;}
.pdf-modal .modal-body object{width: 100%;height: 500px;}
/* .career-apply-modal .modal-content.delete{width: auto;} */
/* ********|| CAREER MODAL ENDS ||******** */
/* ********|| CAREER MODAL STARTS ||******** */
.log-out .vehicle-name{font-size:18px;font-weight:600;margin-bottom:17px;color:var(--color01)}
.log-out .vehicle-number{font-size:15px;font-weight:600;margin-bottom:6px;color:var(--color02)}
.log-out .modal-content{overflow: unset; box-shadow: none;}
.log-out .details-card{border-bottom: 1px solid var(--shades05);padding-bottom: 10px;}
.log-out .vehicle-metalist{display:flex;flex-wrap:wrap}
.log-out .vehicle-metalist .meta-item{width:25%;max-width:calc(25% - 10px);margin:6px 5px}
.log-out .vehicle-metalist .meta-item .meta-label{font-size:15px;color:var(--color02);font-weight:500}
.log-out .vehicle-metalist .meta-item .meta-data{font-size:14px;color:var(--shades02);font-weight:400}
.log-out.delete-modal .modal-title{font-size:18px;font-weight:500;color:var(--color02)}
.log-out.delete-modal .modal-text{font-size:14px;font-weight:400;color:var(--color02);padding: 10px 0;line-height: 1.3;}
.log-out.delete-modal .cancel-btn{border:1px solid var(--statusOpen);background-color:transparent;color:var(--statusOpen);font-size:16px;font-weight:400;border-radius:5px;height:38px;width:150px;display:flex;align-items:center;justify-content:center}
.log-out.delete-modal .confirm-btn{background-color: var(--color01);color: var(--shades01);font-size: 16px;font-weight: 400;border-radius: 5px;height: 38px;width: 150px;display: flex;align-items: center;justify-content: center;}
/* .career-apply-modal .modal-content.delete{width: auto;} */
/* ********|| CAREER MODAL ENDS ||******** */
.nothing-inner{text-align:center;padding:160px 0 80px}
.nothing-inner .nothing-icon{color:var(--color01);font-size:40px}
.nothing-inner .nothing-content .nothing-text{font-size:18px;font-weight:500;color:var(--color02);letter-spacing:.3px;margin:10px 0 5px}
.nothing-inner .nothing-content .nothing-sub-text{font-size:16px;font-weight:400;color:var(--thirdColor);letter-spacing:.3px}

/* Added Chinmoy */
.success-section{padding: 60px 0;display: flex;align-items: center;justify-content: center;flex-direction: column;background: var(--shades04);}
.success-section .success-message{min-width: 400px;margin: 0 auto;display: flex;align-items: center;justify-content: center;flex-direction: column;background: var(--shades01);min-height: 400px;border-radius: 5px; padding: 30px;}
.success-section .success-message .success-image{margin-bottom: 15px;}
.success-section .success-message .success-image{width: 100px;height: 100px;}
.success-section .success-message .success-image img{width: 100%;height: 100%;object-fit: contain;}
.success-section .success-message .success-text .text{font-size: 25px;color: var(--colorSuccess);font-weight: 600;text-transform: capitalize;}
.success-section .details-inner{display: flex;flex-direction: column;margin: 20px 0 0;}
.success-section .details-inner .details-item{box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);padding: 15px;background: var(--shades01);border-radius: 5px;margin-bottom: 25px;}
.success-section .details-inner .details-body-content{background-color: var(--shades01);padding: 20px 0;border-bottom: 1px solid var(--shades04);}
.success-section .details-inner .details-body-content:last-child{border-bottom: none;}
.success-section .details-inner .details-heading{display: flex;justify-content: space-between;}
.success-section .details-inner .details-heading .id-title{font-size:14px;font-weight:500;color:var(--shades06);text-transform:uppercase}
.success-section .details-inner .details-heading .id-sub-title{font-size:20px;font-weight:700;color:var(--color02)}
.success-section .details-inner .details-heading{padding:0 0 10px;margin:0 0 10px;border-bottom:1px solid var(--shades04)}
.success-section .details-inner .details-heading .billings-status{position: absolute;right: 30px;}
.success-section .details-inner .details-heading .billings-status .status-title{font-size: 13px;font-weight: 400;color: var(--color02);position: relative;text-transform: uppercase;}
.success-section .details-inner .details-heading .billings-status .status-title::after{content:'';position:absolute;top:5px;left:-11px;background-color:var(--color01);width:7px;height:7px;border-radius:50%}
.success-section .details-inner .details-heading .billings-status .status-title.cancel{color: var(--statusOpen);}
.success-section .details-inner .details-heading .billings-status .status-title.cancel::after{background-color: var(--statusOpen);}
.success-section .details-inner .details-heading .billings-status .status-title.paid{color: var(--color01);}
.success-section .details-inner .details-heading .billings-status .status-title.paid::after{background-color: var(--color01);}
.success-section .details-inner .details-heading .billings-status .status-title.un-paid{color: var(--statusWaiting);}
.success-section .details-inner .details-heading .billings-status .status-title.un-paid::after{background-color: var(--statusWaiting);}
.success-section .details-inner .details-ticket-title{font-size:12px;font-weight:500;color:var(--shades06);text-transform:uppercase}
.success-section .details-inner .details-ticket-sub-title{font-size:16px;font-weight:700;color:var(--color02);margin: 2px 0;}
.success-section .details-inner .details-footer .plate-inner .plate-content .plate-item{width: 120px;margin: 0 0 10px;}
.success-section .details-inner .details-ticket-text{font-size:14px;font-weight:400;color:var(--shades06);text-transform:uppercase;margin: 10px 0 0;}
.success-section .details-inner .details-content{display: flex;justify-content: space-between;margin: 0 0 10px;}
.success-section .details-inner .details-content .details-ticket-date .date-title{font-size:12px;font-weight:500;color:var(--shades06);text-transform:uppercase;text-align: right;}
.success-section .details-inner .details-content .details-ticket-date .date-sub-title{font-size:16px;font-weight:500;color:var(--color02)}
.success-section .details-inner .details-footer{display:flex;justify-content:space-between}
.success-section .details-inner .details-footer .plate-inner .plate-content{display:flex;justify-content:space-between;width:400px}
.success-section .details-inner .details-footer .plate-inner .plate-title{font-size:11px;font-weight:400;color:var(--shades06);text-transform:uppercase}
.success-section .details-inner .details-footer .plate-inner .plate-sub-title{font-size:16px;font-weight:700;color:var(--color02)}
.success-section .details-inner .details-footer .details-price{font-size:20px;color:var(--shades06);font-weight:600}
.success-section .details-inner .details-footer .details-price.cancel{color: var(--statusOpen);}
.success-section .details-inner .details-footer .details-price.paid{color: var(--color01);}
.success-section .details-inner .details-footer .details-price.un-paid{color: var(--statusWaiting);}
/* .success-section .details-inner .transaction-bill-summary{width: 150px;} */
.success-section .details-inner .transaction-bill-summary .coupon-inner{display: flex;align-items: center;justify-content: space-between;box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);padding: 15px;background: var(--shades01);border-radius: 5px;}
.success-section .details-inner .transaction-bill-summary .coupon-inner .coupon-title{font-size:14px;font-weight:400;color:var(--shades06);text-transform:uppercase}
.success-section .details-inner .transaction-bill-summary .coupon-inner .coupon-text{font-size:14px;font-weight:400;color:var(--color01);text-transform:uppercase}
.success-section .details-inner .payment-inner .payment-title{font-size:16px;font-weight:700;color:var(--color02);margin: 0 0 10px 0;}
.success-section .details-inner .payment-inner .payment-item{box-shadow: 2px 2px 8px rgb(0 0 0 / 11%);background:var(--shades01);border-radius:5px;overflow: hidden;}
.success-section .details-inner .payment-inner .payment-item .payment-content{display:flex;align-items:center;justify-content:space-between}
.success-section .details-inner .payment-inner .payment-item .payment-content .payment-text{font-size:14px;font-weight:400;color:var(--shades06)}
.success-section .details-inner .payment-inner .payment-item .payment-content .payment-price{font-size:14px;font-weight:700;color:var(--color02)}
.success-section .details-inner .payment-inner .payment-item .payment-content+ .payment-content{margin:15px 0 0}
.success-section .details-inner .payment-inner .payment-item .payment-content .payment-price.discount{color:var(--statusOpen)}
.success-section .details-inner .payment-inner .payment-item .payment-footer{background-color: var(--shades04);display: flex;align-items: center;justify-content: space-between;padding:15px;}
.success-section .details-inner .payment-inner .payment-item .payment-footer .total-text{font-size:16px;font-weight:600;color:var(--color02);text-transform:uppercase}
.success-section .details-inner .payment-inner .payment-item .payment-footer .total-Price{font-size:16px;font-weight:600;color:var(--color02);text-transform:uppercase}
.success-section .details-inner .payment-inner .payment-item .payment-option{padding:15px}

.success-section .radio-group{display: flex; align-items: center; gap: 40px;}
.success-section .form-content{position: relative;margin: 30px 0 0;}
.success-section .form-group{position: relative; margin: 0 0 16px;}
.success-section .input-style{position: relative;overflow: hidden;display: flex;background-color: var(--shades01);border-radius: 6px;box-shadow: rgb(0 0 0 / 5%) 0 2px 10px;}
.success-section .input-style .input-icon{width: 40px;min-width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;font-size: 18px;color: var(--color01);background-color: transparent;border-right: 1px solid var(--shades03);}
.success-section .input-style .input{width: 100%;border: 0;border-radius: 0;padding: 0 10px;background-color: transparent;color: var(--color02);font-weight: 500;letter-spacing: 0.3px;}
.success-section .input-style .btn-show-password{width: 40px;min-width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;font-size: 14px;color: var(--shades06);background-color: transparent;}
.success-section .timer{padding: 10px 0 0;display: flex;justify-content: flex-end;font-weight: 500;font-size: 13px;color: var(--color01);}
.success-section .form-option{display: flex;justify-content: space-between; align-items: center;}
.success-section .custom-checkbox{margin-right: auto;}
.success-section .custom-checkbox .custom-checkbox-label{font-size: 13px;}
.success-section .btn-forgot-pasword{margin-left: auto; height: auto; padding: 0; background-color: transparent; font-size: 13px;font-weight: 500;color: var(--color01);}
.success-section .form-action{display: flex;justify-content: center;align-items: center;margin: 20px 0 0;width: 100%; gap: 10px;}
.success-section .form-action .btn-action{ position: relative; overflow: hidden; display: flex;align-items: center;justify-content: center;padding: 0 16px;height: 40px;background-color: var(--color01);color: var(--shades01);font-size: 14px;font-weight: 600;text-transform: uppercase;letter-spacing: 0.3px;border-radius: 4px;width: 100%;max-width: 200px;}
.success-section .form-action .btn-action i{font-size: 16px;}
.success-section .form-action .btn-action:before{content:''; position: absolute; width: 220px; height: 220px; background-color: var(--shades01);top: 50%; left: 50%; transform: translate(-50%,-50%) scale(0); opacity: 0; border-radius: 50%; transition: all 0.3s ease;}
.success-section .form-action .btn-action:hover:before{transform: translate(-50%,-50%) scale(1);opacity: 0.3;}
.success-section .form-action .btn-action:disabled{background-color: var(--shades06);}



.coupon-code .reward-info-title{font-size: 15px;color: var(--color02);font-weight: 500;margin: 5px 0;}

.success-section .success-message .back-home-action{margin-top: 15px;}
.success-section .success-message .back-home-action .back{ text-decoration: underline !important;font-weight: 600;font-size: 16px;}

.pagination-inner{margin: 40px 0 20px;}
.pagination-inner ul{padding: 0;margin: 0;display: flex;align-items: center;}
.pagination-inner ul li+li{margin-left:10px}
.pagination-inner ul li.selected{background:var(--color01);color:#fff;border:none}
.pagination-inner ul li{border-radius:5px;padding: 10px 15px;display:flex;align-items:center;border:none;justify-content:center;color:var(--color02);font-size:14px;font-weight:400;background: rgb(0 0 0 / 10%);}

.authorization-form{background: #fff;padding: 30px;}
.settlment-header{text-align: center;margin: 10px 3px 10px 0;font-weight: bold;text-transform: uppercase;}
.card .card-body .certification{text-decoration: underline !important}
.signature-canvas{border: #000 solid 1px;}
.signature-canvas canvas{width: 100% !important;height: 300px !important;}
.authorization-form .authorization-image{max-width: 21%;}
.authorization-form .authorization-image img{width: 100%;height: 100%;}
.authorization-form .authorization-heading{display: flex;align-items: center;justify-content: space-between;}
.authorization-form .authorization-heading .authorization-address{width: 40%;max-width: calc(40% - 10px);}
.authorization-form .authorization-heading .authorization-address .text{font-size: 15px;font-weight: 600;color: var(--shades02);text-align: right;line-height: 1.3;}
.authorization-form .authorization-heading .authorization-address .text span{font-weight: 400;}
.authorization-form .authorization-heading .authorization-address .text+.text{margin-top: 15px;}
.authorization-form .card-text{font-size:15px;margin-left:10px;font-weight:400;color:var(--shades02);line-height: 1.4;}
.authorization-form .card-text ol li{margin: 0 0 10px;}
.authorization-form .card-body{padding: 10px;}
.authorization-form .input-fild{border: none;border-bottom: 1px solid #000;border-radius: 0;}
.authorization-form .input-fild.fild{width: 100%;}
.authorization-form .summons{border: none;border-bottom: 1px solid #000;border-radius: 0;}
.authorization-form .driver-info-title{font-size:15px;font-weight:600;color:var(--shades02);line-height:1.3}
.authorization-form .date{display: flex;flex-direction: column;justify-content: flex-end;}
.authorization-form .add-btn{width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;background-color: var(--color01);color: var(--shades01);font-size: 14px;}
.authorization-form .close-btn{width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;background-color: #f94646;color: var(--shades01);font-size: 14px;}
.authorization-form .clear-btn{width: 80px;height: 40px;display: flex;align-items: center;justify-content: center;background-color: #f94646;color: var(--shades01);font-size: 14px;}
.authorization-form .trim-btn{width: 80px;height: 40px;display: flex;align-items: center;justify-content: center;background-color: var(--color01);color: var(--shades01);font-size: 14px;margin-left: 15px;}
.authorization-form .submit-action{display: flex;align-items: center;justify-content: flex-end;margin:20px;}
.authorization-form .settlment-inner{display: flex;align-items: center;justify-content: center;flex-direction: column;margin: 20px 0;border-top: 1px solid #000;padding-top: 10px;}
.authorization-form .settlment-inner .settlment-title{font-size:16px;font-weight:600;color:var(--shades02);line-height:1.2;margin:0 0 12px}

.cart-section .cartlist{max-width: unset !important;}


/* end chinmoy */

.img{border:1px solid var(--op-shades04);position:relative;border-radius:5px;margin-bottom:15px;background:var(--shades01)}
.img iframe{width:100%!important;height:100%!important}
.driver-info.pdf-content .img i.fal{position:absolute;font-size:25px;background:var(--shades01);border-radius:50px;font-size:20px;box-shadow:0 0 5px grey;padding:0;right:-5px;top:-5px}
.img img{width:150px;height:150px;object-fit:contain}

@media screen and (max-width: 1024px) {
.side-action{display: flex !important;}
.side-overlay.show {opacity: 1;visibility: visible;}
.sidenavigation.show {transform: translateX(0%);display: block;}
.side-overlay {position: fixed;z-index: 10;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0,0,0,0.5);opacity: 0;visibility: hidden;cursor: pointer;transition: all .3s ease;}
.login-part-pages .user-panel-menus .right-sidebar{max-width: 100%;margin-left: 0;}
.side-panel {position: relative !important;top: 0 !important;box-shadow: none !important;border-radius: 0 !important;}
.sidenavigation {position: fixed !important;top: 0 !important;left: 0;z-index: 11;background-color: var(--shades01);height: 100%;width: 300px;padding: 15px 0;transform: translateX(-110%);transition: all .4s ease;}
.sec-ticket .ticket-list .ticket-table{overflow: auto;}
.add-new-driver .driver-info .driver-info-content+ .driver-info-content{margin-left: 8px;}
/* .add-new-driver .driver-info .driver-info-content.state{margin-right: 8px;} */
}


@media screen and (max-width:990px){
.sec-dashboard .dashboard-item{flex-direction: column;height: auto;}
.sec-dashboard .dashboard-item .dashboard-content{margin: 10px 0 0; padding: 10px 0 0; border:0;border-top: 1px solid var(--shades01);width: 100%; text-align: center;}
}
@media screen and (max-width:990px){
  .sec-dashboard .dashboard-item{max-width: calc(50% - 10px);}
  }
